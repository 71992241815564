/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';

function Logo() {
    const router = useHistory();
    return (
        <nav>
            <img
                alt="Vacation logo"
                src="/images/logo.webp"
                className="w-40 inline-block cursor-pointer"
                onClick={() => router.push('/')}
            />
        </nav>
    );
}

export default Logo;
