/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { SlideDown } from 'react-slidedown';
import useMobileDetect from 'use-mobile-detect-hook';
import ArrowUp from './elements/ArrowUp';
import ArrowDown from './elements/ArrowDown';

function Footer() {
    const detectMobile = useMobileDetect();

    const [showMetro, setShowMetro] = useState();
    const [showDestination, setShowDestination] = useState();
    const [showInterest, setShowInterest] = useState();

    useEffect(() => {
        if (detectMobile.isDesktop()) {
            setShowMetro(true);
            setShowDestination(true);
            setShowInterest(true);
        }
    }, [detectMobile]);

    return (
        <div>

            <div className="flex items-end w-full z-20 relative ">

                <footer className="w-full text-white">
                    <div
                        // className="flex flex-col flex-wrap mx-auto  items-center"
                        className="relative flex flex-col items-center justify-center min-h-full bg-cover w-full pt-10 md:pt-72 pb-32"
                        style={{ background: 'url(\'/images/footer.webp\')', backgroundSize: 'cover', backgroundPosition: '12%' }}
                    >
                        <div className="flex flex-col items-center  w-full md:px-10 px-2  py-4 z-30">
                            <img
                                alt="Blademar logo"
                                src="/images/logo.webp"
                                className="md:w-1/4 w-full mb-10"
                            />
                        </div>
                        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-30" />
                        <div className="flex flex-col gap-4 xl:gap-2 md:flex-row  w-full py-2 px-4 md:px-10  z-30 justify-center items-start md:items-center ">

                            <div className="align-top w-full">
                                <div className="font-semibold uppercase flex flex-row  items-center text-xl md:text-lg justify-between">
                                    <div> Agents by Metro Area</div>
                                    <div onClick={() => setShowMetro(!showMetro)} className="block md:hidden">
                                        {showMetro ? <ArrowUp customClass="bg-transparent" large /> : <ArrowDown customClass="bg-transparent" large />}
                                    </div>
                                </div>
                                <SlideDown className="my-dropdown-slidedown">
                                    {showMetro
                                        && (
                                            <>
                                                <div className="flex flex-col md:flex-row gap-4  xl:gap-20 text-sm text-white">

                                                    <div>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Atlanta&state=GA">Atlanta travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Austin&state=TX">Austin travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Baltimore&state=MD">Baltimore travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Charleston&state=SC">Charleston travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Charlotte&state=NC">Charlotte travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Chicago&state=IL">Chicago travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Columbus&state=OH">Columbus travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Dallas-Ft.+Worth&state=TX">Dallas travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Denver&state=CO">Denver travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Las+Vegas&state=NV">Las Vegas travel agents</a></p>
                                                    </div>

                                                    <div>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Los+Angeles&state=CA">Los Angeles travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Miami-Ft.+Lauderdale&state=FL">Miami travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Mobile&state=AL">Mobile travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=New York&state=NY">New York travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Philadelphia&state=PA">Philadelphia travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Seattle-Tacoma&state=WA">Seattle travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=St.+Louis&state=MO">St. Louis travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Tampa-St+Petersburg&state=FL">Tampa travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Toronto&state=ON">Toronto travel agents</a></p>
                                                        <p className="text-base leading-4"><a href="/agents?metro=Washington,+DC&state=DC">Washington, DC travel agents</a></p>
                                                    </div>
                                                </div>
                                                <div className="text-left text-sm text-white mt-10">
                                                    <p className="text-base leading-4"><a href="/find_travel_agent">Find a Travel Agent near me &gt;</a></p>
                                                </div>

                                            </>
                                        )}
                                </SlideDown>
                            </div>

                            <div className="w-full flex flex-col md:flex-row gap-4">
                                <div className="align-top w-full">
                                    <div className="font-semibold uppercase flex flex-row  items-center text-xl md:text-lg justify-between">
                                        <div> Agents by Interest</div>
                                        <div onClick={() => setShowInterest(!showInterest)} className="block md:hidden">
                                            {showInterest ? <ArrowUp customClass="bg-transparent" large /> : <ArrowDown customClass="bg-transparent" large />}
                                        </div>
                                    </div>
                                    <SlideDown className="my-dropdown-slidedown">
                                        {showInterest
                                            && (
                                                <div className="text-sm text-white">
                                                    <p className="text-base leading-4"><a href="/agents?interest=Cruises">Cruise specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Honeymoons">Honeymoon specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Groups">Group travel specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Adventure">Adventure specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Luxury">Luxury travel specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Safari">Safari specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Golf">Golf travel specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Villas">Villa specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=All+Inclusive">All Inclusive specialists</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?interest=Wellness">Wellness specialists</a></p>
                                                    <p className="text-base leading-4 mt-16"><a href="/find_travel_agent#interest">View More &gt;</a></p>
                                                </div>
                                            )}
                                    </SlideDown>
                                </div>

                                <div className="align-top w-full">
                                    <div className="font-semibold uppercase flex flex-row  items-center text-xl md:text-lg justify-between">
                                        <div> Agents by Destination</div>
                                        <div onClick={() => setShowDestination(!showDestination)} className="block md:hidden">
                                            {showDestination ? <ArrowUp customClass="bg-transparent" large /> : <ArrowDown customClass="bg-transparent" large />}
                                        </div>
                                    </div>
                                    <SlideDown className="my-dropdown-slidedown">
                                        {showDestination
                                            && (
                                                <div className="text-sm text-white">
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Europe&destination=62227">Europe experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=France&destination=62388">France experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Germany&destination=62385">Germany experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Greece&destination=62410">Greece experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Italy&destination=62396">Italy experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Ireland&destination=62378">Ireland experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Portugal&destination=62391">Portugal experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Spain&destination=62390">Spain experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Caribbean&destination=62224">Caribbean experts</a></p>
                                                    <p className="text-base leading-4"><a href="/agents?destinationLabel=Bahamas&destination=62321">Bahamas experts</a></p>
                                                    <p className="text-base leading-4 mt-16"><a href="/find_travel_agent#destination">View More &gt;</a></p>
                                                </div>
                                            )}
                                    </SlideDown>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="bg-blue-dark text-white">
                        <div className="container px-5 py-1 mx-auto">
                            <p className="text-sm md:text-lg  capitalize text-center">
                                Powered by Internova Travel Group |
                                {' '}
                                <a href="https://internova.com/terms-conditions/" target="_blank" rel="noreferrer" aria-label="Terms of use">TERMS OF USE</a>
                                {' '}
                                |
                                {' '}
                                <a href="https://internova.com/privacy/" target="_blank" rel="noreferrer" aria-label="Privacy policy">PRIVACY POLICY</a>
                                {' '}
                                |
                                {' '}
                                <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        openAxeptioCookies();
                                    }}
                                >
                                    Manage Cookie Preferences
                                </a>
                                {' '}
                            </p>
                        </div>
                    </div>
                </footer>
            </div>

        </div>
    );
}

export default Footer;
