import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Loading from '../components/elements/Loading';

import useAppStore from '../contexts/useAppStore';
import WhyComponent from '../components/WhyComponent';
import GetMostFromVacation from '../components/GetMostFromVacation';
import TopReviews from '../components/TopReview';
import InspiredVacation from '../components/InspiredVacation';
import OneFieldSearchBox from '../components/OneFieldSearchBox';

function HomeAb() {
    const { isFetchingAgent } = useAppStore();

    const [boldSearchContainer, setBoldSearchContainer] = useState(false);

    const listenToScroll = () => {
        const heightToHideFrom = 225;

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) { setBoldSearchContainer(true); } else { setBoldSearchContainer(false); }
    };
    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('resize', listenToScroll);
        };
    }, []);
    return (
        <>
            <div role="main">
                <Helmet>
                    <title>Best Travel Agents in the US and Canada | Vacation.com</title>
                    <meta name="title" content="Best Travel Agents in the US and Canada | Vacation.com" />
                    <meta name="description" content="Find travel agents, advisors and agencies providing one of a kind vacation services for trips of all kinds." />
                </Helmet>
                <div className="sticky top-12 left-0  right-0" style={{ zIndex: '1000' }}>
                    <div className={[boldSearchContainer ? 'bg-blue-dark' : 'bg-transparent-blue', 'transition-all'].join(' ')}>
                        <OneFieldSearchBox />
                    </div>
                </div>
                <Hero title="Meet Our Experts. Travel Like a Pro" />

                <div className=" max-w-screen-2xl mx-auto ">

                    <div className="min-h-screen z-20 relative">
                        <WhyComponent />
                        <GetMostFromVacation />
                        <TopReviews title="Traveler Reviews" />
                        <InspiredVacation />
                        {isFetchingAgent() && <Loading />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeAb;
