/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  IoGlobeOutline, IoCalendarOutline, IoLocation, IoCheckmark, IoExpand, IoCloseSharp, IoClose, IoScanOutline,
} from 'react-icons/io5';
import SlideDown from 'react-slidedown';
import Modal from 'react-modal';
import ReactImageGallery from 'react-image-gallery';
import { Helmet } from 'react-helmet';
import API from '../services/API';
import AgentLabel from '../components/AgentLabel';
import BreadCrumb from '../components/BreadCrumb';
import {
  getStateName, modalCustomStyles,
} from '../Utils';
import Loading from '../components/elements/Loading';

function Itnerary() {
  const { id, itneraryId, bioId } = useParams();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const [agent, setAgent] = useState();
  const [bio, setBio] = useState();
  const [loading, setLoading] = useState(false);
  const [itinerary, setItinerary] = useState();
  const [expanded, setExpanded] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  // const detectMobile = useMobileDetect();
  const [links, setLinks] = useState([{
    label: 'Home',
    url: '/',
  },
  {
    label: 'Find a travel Agent',
    url: '/find_travel_agent',
  },
  {
    label: 'State',
  },
  {
    label: '',
  },
  {
    label: 'State',
  },
  ]);

  const [openedList, setOpenedList] = useState([]);

  const onItemClicked = (item) => {
    if (openedList.includes(item)) {
      setOpenedList([...openedList.filter((x) => x !== item)]);
    } else {
      openedList.push(item);
      setOpenedList([...openedList]);
    }
  };

  const getItinerary = () => {
    setLoading(true);
    API.agentItineraries({
      agentId: id,
      bioId: 0,
      itineraryId: itneraryId,
      preview: query.get('preview') || false,
      returnAll: 'true',
    }).then((result) => {
      if (result.responseStatus === 1) {
        setItinerary(result.data[0]);
      }
      setLoading(false);
    });
  };

  const getAgentBio = () => {
    setLoading(true);
    API.agentBio({
      agentId: id,

    }).then((result) => {
      if (result.responseStatus === 1) {
        if (result.data.agentId !== 0) {
          setAgent(result.data);
          getItinerary();
        }
      }
      setLoading(false);
    });
  };
  const getAdditionalBio = () => {
    setLoading(true);
    API.additionalBio({
      agentId: id,
      bioId,

    }).then((result) => {
      if (result.responseStatus === 1) {
        setBio(result.data);
      }
      setLoading(false);
    });
  };

  const metaDescription = () => `See a travel curated by ${`${agent.firstName} ${agent.lastName}`}, who specializes in ${itinerary.visitedCountries} vacations. Discover what ${agent.firstName} can do for you`;

  // useEffect(() => {
  //   if (!detectMobile.isDesktop()) { setExpanded(false); }
  // }, []);

  useEffect(() => {
    if (expanded && itinerary) {
      getAdditionalBio();
      setOpenedList(itinerary.itineraryDays.map((x) => x.day));
    } else { setOpenedList([]); }
  }, [expanded, itinerary]);

  const agentBio = () => (
    <div className="flex flex-col gap-10 md:px-20  ">

      <div className="flex flex-col gap-4 px-4 ">
        <div className=" md:-ml-6 mt-4 md:mt-0" style={{ zIndex: '1000' }}>
          <BreadCrumb links={links} />
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:gap-6 md:items-center  w-full flex-1">

          <div className="absolute right-0 z-40 top-28 md:top-32 hidden-tw md:block">
            <img
              alt="Vector"
              src="/images/Vector 5.webp"
              className="md:w-full -mr-40 md:mr-0"
            />

          </div>
          <div className="flex flex-row gap-2">
            <div className="w-56 h-72 hidden-tw md:block bg-blue-dark rounded-tr-3xl
             md:absolute -ml-6   z-10"
            />

            <img
              alt={agent.firstName + ' ' + agent.lastName + ', ' + agent.city + ', ' + agent.state + ' Travel Agent'}
              src={agent.agentPhotoFileName}
              className="hidden-tw md:block md:w-80 md:mt-8 mx-auto  rounded-tr-3xl z-20 relative"
            />
            <div className="md:hidden w-full">

              <AgentLabel agent={agent} reviewCallback={() => {}} />
            </div>

          </div>
          <div className="hidden-tw md:flex flex-col items-center md:items-start gap-2  w-full">
            {/* <BreadCrumb links={links} /> */}
            <AgentLabel agent={agent} reviewCallback={() => {}} />

          </div>

        </div>

      </div>

    </div>
  );

  const header = () => (
    <div className="flex flex-col gap-4 md:gap-10">
      <div className="py-2 md:p-10 flex flex-col bg-blue-dark gap-4">
        <div className="text-xl md:text-3xl text-white text-center px-2 md:px-0">{itinerary.title}</div>
        <div className="relative cursor-pointer" onClick={() => setIsModalOpen(true)}>
          <img
            className="w-full object-cover "
            src={itinerary.itineraryImage}
            alt={'Vacation Itinerary - ' + itinerary.title}
          />

        </div>
        <div className="flex justify-center text-white text-2xl md:text-4xl cursor-pointer" onClick={() => setIsModalOpen(true)}>
          <IoScanOutline />
        </div>

      </div>
      <div className="flex flex-col gap-2 md:gap-0 md:flex-row justify-between md:px-10 px-2">
        {itinerary.visitedCountries !== '' && (
        <div className="text-blue-dark font-bold text-base md:text-lg  px-2 mt-4
        flex flex-row items-center gap-3"
        >
          <span className="bg-blue-dark rounded-full p-3">
            <IoGlobeOutline className="text-white text-3xl" />
          </span>
          <div className="flex flex-col">
            <span>COUNTRIES VISITED:</span>
            <span className="text-black font-thin text-sm md:text-base">
              {itinerary.visitedCountries}
            </span>
          </div>
        </div>
        )}
        {itinerary.visitedCities !== '' && (
        <div className="text-blue-dark font-bold text-base md:text-lg  px-2 mt-4
        flex flex-row items-center gap-3"
        >
          <span className="bg-blue-dark rounded-full p-3">
            <IoLocation className="text-white text-3xl" />
          </span>
          <div className="flex flex-col">
            <span>PLACES VISTIED:</span>

            <span className="text-black font-thin text-sm md:text-base">
              {itinerary.visitedCities}
            </span>
          </div>
        </div>
        ) }
        <div className="text-blue-dark font-bold text-base md:text-lg  px-2 mt-4
        flex flex-row items-center gap-3"
        >
          <span className="bg-blue-dark rounded-full p-3">
            <IoCalendarOutline className="text-white text-3xl" />
          </span>
          <div className="flex flex-col">
            <span>SUGGESTED DURATION:</span>
            <span className="text-black font-thin text-sm md:text-base">
              {itinerary.itineraryDays.length}
              {' '}
              Days
            </span>
          </div>

        </div>
      </div>
      <div className="p-10 bg-gray-verylight text-black text-sm md:text-lg ml-10 rounded-l-3xl">
        {itinerary.overview}
      </div>
    </div>
  );

  const body = () => (
    <div className="flex flex-col  gap-10 md:px-10">
      <div className=" self-end text-blue-dark pr-2 md:pr-0 text-sm md:text-base">
        {!expanded
          ? (
            <div className="flex flex-row cursor-pointer items-center gap-2" onClick={() => setExpanded(true)}>
              <div>Expand All Days</div>
              <div className="font-bold">
                <IoExpand />
              </div>
            </div>
          ) : (
            <div className="flex flex-row cursor-pointer items-center gap-2" onClick={() => setExpanded(false)}>
              <div>Close All Days</div>
              <div className="font-bold">
                <IoCloseSharp />
              </div>
            </div>
          )}
      </div>

      {itinerary.itineraryDays.map((x) => (
        <div className="flex flex-col gap-4  md:gap-10 " key={`day-${x.day}`}>
          <div
            className="flex flex-col gap-2 md:p-8 p-4 md:shadow-xl rounded-tr-3xl md:rounded-xl md:border md:border-gray-50 border-r border-t border-gray-200"
          >
            <div className="flex flex-col md:flex-row  justify-between md:items-center">
              <div className="flex flex-row gap-2 items-center">
                <div className="bg-blue-dark rounded-full w-14 h-14 md:h-20 md:w-20
                flex flex-col items-center justify-center
                 text-white text-sm md:text-base font-semibold"
                >
                  <span>DAY</span>
                  <span>{x.day}</span>
                </div>

                {x.dayDestinations.map((y, index) => (
                  <div
                    className="flex flex-row gap-2 items-center font-bold text-xs
                      md:text-2xl uppercase"
                    key={y.locationId}
                  >
                    <div>{y.location.split('|')[0]}</div>
                    {(index + 1 < x.dayDestinations.length) && <div>-</div>}
                  </div>
                ))}
              </div>
              <div
                onClick={() => onItemClicked(x.day)}
                className="md:flex flex-row hidden-tw gap-2 justifiy-center items-center cursor-pointer text-blue-dark"
              >
                {!openedList.includes(x.day)
                && (
                <>
                  <div>Expand Day</div>
                  <div className="font-bold">
                    <IoExpand />
                  </div>
                </>
                )}
                {openedList.includes(x.day)
                && (
                <>
                  <div>Close Day</div>
                  <div className="font-bold">
                    <IoCloseSharp />
                  </div>
                </>
                )}
              </div>

            </div>

          </div>
          <SlideDown className="my-dropdown-slidedown">
            {openedList.includes(x.day)
                && (
                <div className="flex flex-col md:flex-row gap-8 md:gap-16 mx-4 md:mx-10">
                  <div className="flex-1  flex flex-col gap-4">
                    <div className="text-blue-dark text-sm md:text-xl font-semibold uppercase">
                      {x.dayTitle}
                    </div>
                    <div className="text-xs md:text-sm text-gray-800">
                      {x.dayDescription}
                    </div>
                    <div className="text-blue-dark text-sm md:text-xl  font-semibold">
                      HIGHLIGHTS:
                    </div>
                    {x.dayHighlights.map((y) => (
                      <div className="flex flex-row gap-2 items-center" key={y.highlightId}>
                        <div><IoCheckmark className="text-green-500 font-bold text-base md:text-xl" /></div>
                        <div className="md:font-bold font-semibold text-xs md:text-base">{y.highlight1}</div>
                      </div>
                    ))}

                  </div>
                  {x.dayImage.length > 0 && (
                  <div className="flex-1 mx-0 md:mx-10">
                    <img
                      className="w-full mx-auto rounded-lg h-80 object-cover "
                      src={x.dayImage}
                      alt="Day"
                    />
                  </div>
                  )}
                </div>
                )}
          </SlideDown>
        </div>
      ))}

    </div>
  );

  const finalToughts = () => (
    <div className="p-4 md:p-10 bg-gray-verylight rounded-tl-3xl md:rounded-none text-black text-xs md:text-lg flex flex-col md:gap-6 gap-2 ">
      <div className="text-blue-dark text-base md:text-3xl font-semibold">FINAL THOUGHTS</div>
      <div>
        {itinerary.finalThoughts}
      </div>
    </div>
  );

  const collectImages = () => {
    setImages([itinerary.itineraryImage].concat(itinerary.itineraryDays.map((x) => x.dayImage)));
  };

  useEffect(() => {
    if (agent && itinerary) {
      const breadCrumbLinks = [{
        label: 'Home',
        url: '/',
      },
      {
        label: 'Find a travel Agent',
        url: '/find_travel_agent',
      }, {
        label: getStateName(agent.state),
        url: `/agents?state=${agent.state}`,
      },
      {
        label: `${agent.firstName} ${agent.lastName}`,
        url: `/agent/${agent.agentId}`,
      },
      ];

      if (bio) {
        breadCrumbLinks.push({
          label: bio.displayTitle,
          url: `/agent/${agent.agentId}/bio/${bioId}`,
        });
      }

      breadCrumbLinks.push({
        label: itinerary.title,
      });

      setLinks(breadCrumbLinks);

      collectImages();
    }
  }, [agent, itinerary, bio]);

  useEffect(() => {
    getAgentBio();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" max-w-screen-2xl mx-auto bg-white" role="main">
      {agent && (
      <div className="h-full  flex flex-col gap-10 pt-10 md:py-16 text-gray-900 ">
        {agentBio()}
        {itinerary && (
        <>
          <Helmet>
            <title>
              {itinerary.title}
              {' '}
              | Vacation.com
            </title>
            <meta name="title" content={`${itinerary.title}  | Vacation.com`} />
            <meta
              name="description"
              content={metaDescription()}
            />
          </Helmet>
          {header()}
          {body()}
          {finalToughts()}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            style={modalCustomStyles}
            contentLabel="Modal"
            ariaHideApp={false}
          >
            <div className="max-w-6xl">
              <div className="flex justify-end cursor-pointer" onClick={() => setIsModalOpen(false)}>
                <IoClose className="text-blue-dark text-right" />
              </div>
              <div className=" px-4">

                <div>
                  <div className="text-blue-dark text-xl font-medium text-center mb-6">
                    ITINERARY GALLERY
                  </div>
                  <ReactImageGallery
                    items={images.map((y) => ({
                      original: y,
                      thumbnail: y,
                      originalTitle: '',
                    }))}
                    additionalClass="max-w-xs md:max-w-2xl"
                  />
                </div>

              </div>
            </div>
          </Modal>
        </>
        )}
      </div>
      )}
      {loading && <Loading />}
    </div>
  );
}

export default Itnerary;
