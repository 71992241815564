/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../services/API';
import ButtonBlue from './elements/ButtonBlue';

function EmergencyEmailForm({ agent }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();

  const {
    register, handleSubmit, watch, formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = (data) => {
    setIsProcessing(true);
    API.sendEmailToAgent(data).then((response) => {
      if (response.responseStatus) {
        if (response.responseStatus === 0) {
          toast.error(response.error.message);
        } else {
          history.push('/thankYou');
        }
      } else toast.error('Error occured');
      setIsProcessing(false);
    });
  };

  useEffect(() => {
    setValue('agentId', agent.agentId.toString());
    setValue('URL', window.location.href);
  }, []);
  return (
    <div className="p-4 flex flex-col gap-6 max-w-2xl">

      <div className="text-lg font-semibold">

        {`${agent.firstName} ${agent.lastName}  is not currently accepting customer inquiries and a response may be delayed. In the case of emergency, submit  your information below or call ${agent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}`}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="flex flex-col">
          <input className="hidden" {...register('agentId', { required: true })} />
          <div>
            <label>First Name</label>
          </div>
          <div>
            <input placeholder="First Name" className="border border-gray-400  py-2 px-4 w-full" {...register('firstName', { required: true })} />
          </div>
          <div>
            {errors.firstName && <span className="text-red-500 text-sm">First name is required</span>}
          </div>
        </div>

        <div className="flex flex-col">
          <div>
            <label>Last Name</label>
          </div>
          <div>
            <input placeholder="Last Name" className="border border-gray-400  py-2 px-4 w-full" {...register('lastName', { required: true })} />
          </div>
          <div>
            {errors.lastName && <span className="text-red-500 text-sm">Last name is required</span>}
          </div>
        </div>

        <div className="flex flex-col">
          <div>
            <label>Email</label>
          </div>
          <div>
            <input placeholder="Email" type="email" className="border border-gray-400  py-2 px-4 w-full" {...register('email', { required: true })} />
          </div>
          <div>
            {errors.email && <span className="text-red-500 text-sm">Email is required</span>}
          </div>
        </div>

        <div className="flex flex-col">
          <div>
            <label>Phone Number</label>
          </div>
          <div>
            <input placeholder="Phone number" className="border border-gray-400  py-2 px-4 w-full" {...register('phone')} />
          </div>
          {errors.phone && <span className="text-red-500 text-sm">Phone number is required</span>}
        </div>

        <div className="flex flex-col">
          <div>
            <label>Your questions / comments to this agent</label>
          </div>
          <div>
            <textarea placeholder="Comment" className="border border-gray-400  py-2 px-4 w-full" {...register('comment')} />
            {errors.comment && <span className="text-red-500 text-sm">Question/Comment is required</span>}

          </div>
        </div>

        <div>
          <ButtonBlue
            disabled={isProcessing}
            type="submit"
            title={
             isProcessing ? `Sending email to ${agent.firstName} ${agent.lastName} ....`
               : `Email ${agent.firstName} ${agent.lastName}`
           }
          />
        </div>
      </form>

    </div>

  );
}

EmergencyEmailForm.propTypes = {
  agent: PropTypes.any.isRequired,

};
export default EmergencyEmailForm;
