/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import urlSlug from 'url-slug';
import Slider from 'react-slick';
import API from '../services/API';
import { IMAGE_URL, slidesettings } from '../Utils';
import SliderArrows from './elements/SliderArrows';

function InspiredVacation() {
  const [data, setData] = useState([]);
  const noSlides = 3;
  const slider = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    API.getBlogs({ rows: 20, name: 'featured' }).then((result) => {
      if (result.responseStatus === 1) { setData(result.data); }
    });
  }, []);
  const renderItem = (item) => (
      <a href={`/travel_information/${item.storyId}/${urlSlug(item.title)}`} className="-ml-4 flex flex-col gap-0 rounded-tr-3xl  border border-gray-100 flex-1" tabIndex="-1">
      <div>
        <img
          alt="Item"
          src={`${IMAGE_URL()}/${item.thumbnailImage}`}
          className="w-full rounded-tr-3xl h-96 object-cover"
        />
      </div>
      <div className="p-6 bg-blue-dark text-white">
        <div className="text-lg font-semibold uppercase h-16">
          {item.title}
        </div>
        <div><p className="break-words text-sm">{item.shortDescription.slice(0, 150)}</p></div>
        <div>
          <span className="font-semibold text-sm">Read more</span>
        </div>
      </div>
    </a>
  );
  return (
    <div className="bg-white md:p-16 px-4 py-16 relative">
      <div className="flex flex-row md:justify-between justify-center">
        <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
          <div className="text-xl md:text-4xl font-bold px-1 md:px-0 text-blue-dark">
            Get Inspired By Vacation Ideas
          </div>
          <div className="h-1.5 w-24 md:w-14 bg-blue-dark md:ml-0" />
        </div>
        <div className="hidden md:block">
          <SliderArrows
            onPrev={() => slider.current.slickNext()}
            onNext={() => slider.current.slickPrev()}
            hasNext={selectedIndex + noSlides < data.length}
            hasPrev={selectedIndex !== 0}
            horizontal
          />
        </div>
      </div>
      <div className="hidden md:block">
        <Slider {...slidesettings(data.length < noSlides ? data.length : noSlides, false)} ref={slider} afterChange={(index) => setSelectedIndex(index)}>
          {data.map((review) => (
            <div className="pr-10" key={review.storyId}>
              {renderItem(review)}
            </div>
          ))}
        </Slider>
      </div>
      <div className="block md:hidden -mx-4">
        <Slider {...slidesettings(data.length < 1 ? data.length : 1, false)} afterChange={(index) => setSelectedIndex(index)}>
          {data.map((review) => (
            <div className="pr-10" key={review.storyId}>
              {renderItem(review)}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default InspiredVacation;
