/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

function SelectInput({
    options, onSelected, placeholder, value,
}) {

    return (
        <Dropdown
            value={value}
            options={options}
            onChange={(e) => onSelected(e.value)}
            placeholder={placeholder}
            controlClassName="border border-gray-400 rounded-full text-xs"
            className="w-full"
            menuClassName="text-left"
            arrowClassName="mr-2 mt-1"
            placeholderClassName="text-xs py-1.5 px-2"
            style={{ color: '#333333' }}
        />
    );
}
SelectInput.defaultProps = {

    placeholder: 'Select an options',
    value: '',
};
SelectInput.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    onSelected: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
};
export default SelectInput;
