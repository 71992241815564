/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

function ButtonBlue({
  onClick, title, disabled, icon, titleClass, type, extraClass,
}) {
  return (
    <button
          type={type}
          className={['border rounded-full py-2.5 px-4 text-xs w-full font-semibold  flex flex-row justify-center items-center gap-2',
              disabled ? 'text-white bg-gray-normal hover:bg-gray-normal cursor-not-allowed' : 'text-white bg-blue-dark hover:bg-green-light cursor-pointer', extraClass].join(' ')}
          onClick={onClick}
          disabled={disabled}
          aria-label={title}
          tabIndex="0"
    >
      {icon}
      <span className={titleClass}>
        {title}
      </span>
    </button>
  );
}

ButtonBlue.defaultProps = {
  disabled: false,
  icon: undefined,
  onClick: () => {},
  titleClass: '',
  extraClass: '',
  type: 'button',
};

ButtonBlue.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  titleClass: PropTypes.string,
  extraClass: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  type: PropTypes.string,
};

export default ButtonBlue;
