/* eslint-disable import/prefer-default-export */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import StarRatings from 'react-star-ratings';
import useMobileDetect from 'use-mobile-detect-hook';

import {
    GoogleMap, Marker, withGoogleMap,
    InfoWindow,
} from 'react-google-maps';

export const AgentMap = withGoogleMap((props) => {
    const { selectedLocation, callback } = props;
    const [showInfo, setShowWindow] = useState(false);
    const [selected, setSelected] = useState();
    const detectMobile = useMobileDetect();

    useEffect(() => {
        if (selectedLocation) { setSelected(selectedLocation); }
    }, [selectedLocation]);
    useEffect(() => {
        if (selected) {
            setShowWindow(true);
            callback(selected);
        }
    }, [selected]);

    return (
        <div>
            <GoogleMap
                defaultZoom={detectMobile.isDesktop() ? 2 : 1}
                center={selected ? {
                    lat: selected.latitude,
                    lng: selected.longitude,
                } : { lat: 37.0902, lng: -95.7129 }}
                >

                {props.locations?.map((x) => (
                    <Marker
                        key={x.mapId}
                        position={{ lat: x.latitude, lng: x.longitude }}
                        onClick={() => {
                            setSelected(x);
                            // setShowWindow(!showInfo);
                        }}
                        title={x.area + ', ' + x.region}
                    />
                ))}
                {showInfo && (
                    <InfoWindow
                        position={{
                            lat: selected.latitude,
                            lng: selected.longitude,
                        }}
                        onCloseClick={() => setShowWindow(!showInfo)}
                    >
                        <div className="flex flex-row gap-10 justify-between p-6">
                            <div className="flex flex-col">
                                <div className="text-sm text-red-700 md:text-base font-medium">{`${selected.locale} ${selected.locale ? ',' : ''} ${selected.area} ${selected.region}`}</div>
                                {selected.bookedIt && <div className="text-sm text-gray-500 font-light">{`Booked It ${selected.bookingCount} Times`}</div>}
                                {selected.beenThere && <div className="text-sm text-gray-500 font-light">{`Been There ${selected.visitCount} Times`}</div>}

                                <StarRatings
                                    rating={selected.reviewRating}
                                    starRatedColor="#4287f5"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="14px"
                                    starSpacing="2px"
                                    title="ratings"
                                />
                                <div className="text-sm text-gray-500 font-light">{selected.reviewTitle}</div>
                                {selected.locationTip && (
                                    <>
                                        <div className="text-sm text-gray-500 font-semibold mt-4">Must Do:</div>
                                        <div className="text-sm text-gray-500 font-light">{selected.locationTip}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>

        </div>
    );
});
