/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import React, {
  useEffect, useState,
} from 'react';
import parser from 'html-react-parser';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import sanitizeHtml from 'sanitize-html-react';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';
import { IMAGE_URL } from '../Utils';
import urlSlug from 'url-slug';

function BlogCategory() {
  const [loading] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const [category] = useState(query.get('category') || '');

  const [title, setTitle] = useState();

  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (category) {
      const text = category.toLowerCase() === 'travel tips' ? 'Travel Tips and Ideas' : `${category} Travel Ideas and Tips`;
      setTitle(text);
      setLinks([{
        label: 'Home',
        url: '/',
      },
      {
        label: 'Travel Information',
        url: '/travel_information',
      },
      {
        label: text,
      },
      ]);
    }
  }, [category]);

  return (
    <div className=" max-w-screen-2xl mx-auto bg-white" role="main">
      <ToastContainer />
      <Helmet>
        <title>
          {category}
          {' '}
          Travel Ideas and News | Vacation.com
        </title>
        <meta name="title" content={`${category} Travel Ideas and News | Vacation.com`} />
        <meta
          name="description"
          content={`See news, tips, and ideas about ${category} written by the experts who know it best.`}
        />
      </Helmet>

      <div className="min-h-screen pb-40 md:px-10 px-2">
        <div className="flex flex-col md:gap-10 gap-4">
          <div className="ml-2  pt-16 md:mt-10">
            <BreadCrumb links={links} />
          </div>
          <div className="text-blue-dark text-xl md:text-5xl  flex-col items-center  justify-center font-bold flex md:mb-10">
            <h1>
              {title}
            </h1>
            <div className="h-1.5 bg-blue-dark w-28 mb-2 mt-6 md:hidden" />

          </div>
          <div className="flex flex-col gap-4 items-start max-w-screen-5xl mx-auto -ml-2">
            {category && <CategoryBlogs name={category} /> }

          </div>
        </div>

      </div>

      {loading && <Loading />}
    </div>
  );
}

export default BlogCategory;

function CategoryBlogs({ name }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  const history = useHistory();

  const getBlogs = () => {
    setLoading(true);
    API.getBlogs({
      rows: currentPage,
      name,
    }).then((result) => {
      if (result.responseStatus === 1) {
        setData(result.data);
        if (result.data.length === currentPage) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } else { toast(result.error.message); }
      setLoading(false);
    });
  };
  useEffect(() => {
    getBlogs();
  }, [currentPage]);
  return (
    <>
      {data.length > 0
      && (
      <div className="">
        <ToastContainer />
        {!loading && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {data.map((x) => (
                <div className="max-w-sm" key={x.storyId}>
                  <div
                    className="flex flex-col gap-6  cursor-pointer bg-blue-dark text-white rounded-tr-3xl"
                    onClick={() => history.push(`/travel_information/${x.storyId}/${urlSlug(x.title)}?category=${name}`)}
                  >
                    <div className="rounded-tr-3xl  ">
                      <img src={`${IMAGE_URL()}/${x.thumbnailImage}`} className="w-full  rounded-tr-3xl object-cover" alt="Blog" />
                      {' '}
                    </div>

                    <div className="h-48">
                      <div className="font-bold text-lg uppercase px-4">{x.title}</div>
                      <div
                        className="text-xs  px-4 "
                      >

                        { x.shortDescription.length > 250 ? parser(`${sanitizeHtml(x.shortDescription.slice(0, 250))}...<span class="mx-2  cursor-pointer underline font-semibold">Read more</span>`)
                          : parser(`${sanitizeHtml(x.shortDescription)}...<span class="mx-2  cursor-pointer underline font-semibold">Read more</span>`)}
                      </div>

                    </div>
                  </div>
                </div>

              ))}
            </div>

            <div className="text-sm font-semibold text-blue-dark cursor-pointer  flex justify-end gap-1 uppercase w-full mt-10" onClick={() => history.push('/travel_information')}>
              <div>
                See all articles

              </div>
              <div>{'>'}</div>

            </div>
          </>
        ) }

      </div>
      )}
      {loading && <Loading />}
    </>
  );
}
