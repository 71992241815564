/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, createContext, useEffect } from 'react';
import API from '../services/API';

const AppContext = createContext([{}, () => {}]);

const AppContextProvider = (props) => {
  const [appState, setAppState] = useState({});

  const fetchLookups = async () => Promise.all(
    [
      API.fetchStates().then((response) => response),
      API.fetchInterests().then((response) => response),
      API.fetchDestinations().then((response) => response),
      API.getPopularDestinations().then((response) => response),
    ],

  );

  useEffect(() => {
    fetchLookups().then((result) => {
      // if (result[0].error) console.log(result[0].error.message);
      // if (result[1].error) console.log(result[1].error.message);
      // if (result[2].error) console.log(result[2].error.message);
      // if (result[3].error) console.log(result[3].error.message);

      setAppState(
        {
          ...appState,
          states: result[0].error ? [] : result[0],
          specialities: result[1].error ? [] : result[1],
          destinations: result[2].error ? [] : result[2],
          popularDestinations: result[3].error ? [] : result[3],
        },
      );
    });
  }, []);

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
