/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import API from '../../services/API';

import 'react-slidedown/lib/slidedown.css';
import { getStateName, label } from '../../Utils';
import { IoChevronDown, IoChevronForward, IoPlaySharp } from 'react-icons/io5';

function State({
  destination, city, interest, state, language, metroRegion, zip,
  supplier,
  callback,
  trigger,
}) {
  const stateType = 'FacetedStates';
  const metroType = 'FacetedMetroRegions';
  const cityType = 'FacetedCities';
  const [data, setData] = useState([]);
  const [metroData, setMetroData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [showFull, setShowFull] = useState(false);
  const [type, setType] = useState('state');
  const [itemToShow, setItemToShow] = useState(5);

  const fetch = (agent) => {
    API.fetchFaceted({
      destination: destination || '',
      city: city || '',
      interest: interest || '',
      state: state || '',
      language: language || '',
      metroRegion: metroRegion || '',
      zip: zip || '',
      supplier: supplier || '',
      type: stateType,
    }).then((response) => {
      if (!response || response.error) { setData([]); } else setData(response);
    });
  };

  const fetchMetros = (agent) => {
    API.fetchFaceted({
      destination: destination || '',
      city: city || '',
      interest: interest || '',
      state: state || '',
      language: language || '',
      metroRegion: metroRegion || '',
      zip: zip || '',
      supplier: supplier || '',
      type: metroType,
    }).then((response) => {
      if (!response || response.error) { setMetroData([]); } else setMetroData(response);
    });
  };

  const fetchCities = (agent) => {
    API.fetchFaceted({
      destination: destination || '',
      city: city || '',
      interest: interest || '',
      state: state || '',
      language: language || '',
      metroRegion: metroRegion || '',
      zip: zip || '',
      supplier: supplier || '',
      type: cityType,
    }).then((response) => {
      if (!response || response.error) { setCityData([]); } else setCityData(response);
    });
  };

  useEffect(() => {
    fetch();
    if (state && state !== '') {
      if (metroRegion) { setType('city'); } else { setType('metro'); }
    } else {
      setType('state');
    }
  }, [destination, interest, state, language, supplier, metroRegion]);

  // useEffect(() => {
  //   if (zip && zip !== '') {
  //     trigger();
  //   }
  // }, [zip]);

  useEffect(() => {
    if (state && state !== '') {
      trigger();
      fetchMetros();
      if (metroRegion) { setType('city'); } else { setType('metro'); }
    } else {
      trigger();
    }
  }, [state, metroRegion]);

  useEffect(() => {
    if (metroRegion) {
      trigger();
      fetchCities();
      setType('city');
    }
  }, [metroRegion]);

  useEffect(() => {
    if (city) {
      trigger();
      setType('city');
    }
  }, [city]);

  return (
    <>
      {data.length > 0 && (
      <div className="py-2">
        <div>{label('LOCATION')}</div>

        <div className="text-4xl flex flex-col">
          {type === 'state' && (
          <div className="flex flex-col items-start ">
            <div
              onClick={() => setItemToShow(itemToShow === 5 ? 0 : 5)}
              className="text-xs mb-1 cursor-pointer text-white flex flex-row items-center gap-1"
            >
              All States
              {' '}
              {itemToShow === 0 && <IoChevronForward className="text-right" /> }
              {itemToShow === 5 && <IoChevronDown className="text-right" /> }
            </div>

            <SlideDown className="my-dropdown-slidedown">
              <div className="flex flex-col items-start ">
                {data.slice(0, showFull ? data.length : itemToShow).map((x) => (
                  <div
                    key={x.state}
                    onClick={() => callback('state', x.state)}
                    className="text-xs mb-1 cursor-pointer text-white"
                  >
                    {getStateName(x.state)}
                  </div>
                ))}
              </div>
            </SlideDown>

            {data.length > itemToShow && itemToShow !== 0
     && (
     <div className="text-xs mb-1 cursor-pointer font-semibold text-white mt-2  self-end md:self-start" onClick={() => setShowFull(!showFull)}>
       {!showFull ? (
         <div className="flex flex-row gap-1">
           <IoPlaySharp />
           More Locations
           {`(${data.length - itemToShow})`}
         </div>
       ) : <div>Close</div>}
     </div>
     )}
          </div>
          )}

          {type === 'metro' && (
          <div className="flex flex-col items-start ">

            <div
              onClick={() => callback('state', 0)}
              className="text-xs mb-1 cursor-pointer text-white"
            >
              All States
            </div>

            <SlideDown className="my-dropdown-slidedown">
              <div
                className="text-xs mb-1  text-white"
              >
                {getStateName(state)}
              </div>

              <div className="flex flex-col items-start pl-4">
                <div
                  className="text-xs mb-1 text-white font-semibold"
                >
                  Metro Region
                </div>
                {metroData.map((x) => (
                  <div
                    key={x.metroRegion}
                    onClick={() => callback('metro', x.metroRegion)}
                    className={['text-xs mb-1 cursor-pointer font-normal', x.metroRegion === metroRegion ? 'text-white' : 'text-white'].join(' ')}
                  >
                    {x.metroRegion}
                  </div>
                ))}
              </div>
            </SlideDown>

          </div>
          )}

          {type === 'city' && (
          <div className="flex flex-col items-start ">

            <div
              onClick={() => callback('state', 0)}
              className="text-xs mb-1 cursor-pointer text-white"
            >
              All States
            </div>
            <div
              onClick={() => callback('state', state)}
              className="text-xs mb-1 cursor-pointer text-white"
            >
              {getStateName(state)}
            </div>
            <div
              className="text-xs mb-1 text-white font-semibold"
            >
              Metro Region
            </div>
            <div
              onClick={() => callback('metro', 0)}
              className="text-xs mb-1 cursor-pointer text-white"
            >
              {metroRegion}
            </div>

            <SlideDown className="my-dropdown-slidedown">

              <div className="flex flex-col items-start pl-4">
                <div
                  className="text-xs mb-1 text-white font-semibold"
                >
                  City
                </div>

                {cityData.map((x) => (
                  <div
                    onClick={() => callback('city', x.city)}
                    className={['text-xs mb-1 cursor-pointer font-normal', x.city === city ? 'text-white' : 'text-white'].join(' ')}
                  >
                    {x.city}
                  </div>
                ))}
              </div>
            </SlideDown>

          </div>
          )}
        </div>
      </div>
      )}
    </>
  );
}

export default State;
