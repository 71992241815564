import React from 'react';

function ThankYouHero() {
  return (
    <div className="text-4xl flex flex-col justify-center text-center" style={{ height: '500px' }}>
      <div
        className="relative flex flex-col items-center justify-center min-h-full bg-cover min-w-screen "
        style={{ backgroundImage: "url('https://www.travelleaders.com/images/thankyouhero.jpg')" }}
      >
        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-40" />

        <div className="flex max-w-6xl mx-auto">
          <div className="container relative flex flex-col md:px-10 px-4 pb-1 md:pr-12 text-2xl font-hairline text-white">
            <h1 className="relative z-20 text-xl md:text-6xl font-medium leading-tight text-white uppercase mt-20">
              THANK YOU FOR CONTACTING US!
            </h1>

          </div>

        </div>
      </div>
    </div>
  );
}

export default ThankYouHero;
