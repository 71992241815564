/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import parser from 'html-react-parser';
import Modal from 'react-modal';
import {
  IoClose, IoMail, IoCall,
} from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import { useHistory } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html-react';
import Slider from 'react-slick';

import useMobileDetect from 'use-mobile-detect-hook';
import { slidesettings, modalCustomStyles, processBioCertificateImages } from '../Utils';
import useAppStore from '../contexts/useAppStore';
import AppointmentForm from './AppointmentForm';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import AgentCertificates from './AgentCertificate';
import ButtonBlue from './elements/ButtonBlue';

function AgentLabelBlog({ agent, blogAttribute, blog }) {
  const {
    firstName, lastName, city, state, agentPhotoFileName, addlBioId,
  } = agent;

  const history = useHistory();

  const [certificates, setCertificates] = useState([]);

  const detectMobile = useMobileDetect();
  const [noSlides, setNoSlides] = useState(4);
  useEffect(() => {
    setNoSlides(detectMobile.isDesktop() ? 4 : 1);
    setCertificates(processBioCertificateImages(agent, agent));
  }, [detectMobile]);

  const agentReviews = () => (
    <div>
      <div className=" text-blue-dark text-xl md:text-2xl  mt-6 text-center font-semibold px-10 md:px-0">Wondering what it's like to work with me? Here is what some of my clients are saying:</div>
      <div className="h-1.5 bg-blue-dark w-28  md:hidden mx-auto mt-6" />
      <div className="mt-2 md:mt-10 max-w-sm md:max-w-full">
        <Slider {...slidesettings(agent.agentReviews.length < noSlides ? agent.agentReviews.length : noSlides, false)}>
          {agent.agentReviews.map((x) => (
            <div
              key={x.reviewId}
              className="md:pr-10 max-w-xl pr-4 pl-4"
            >
              <div className="flex flex-col gap-4  rounded-tr-3xl  shadow-lg  border border-gray-50 px-4 py-10">
                <div>
                  <div className="font-bold text-lg md:text-xl text-black">
                    {x.reviewTitle}
                  </div>
                  <StarRatings
                    rating={x.rating}
                    starRatedColor="#33C590"
                    numberOfStars={5}
                    name="rating"
                    starDimension="14px"
                    starSpacing="2px"
                  />
                </div>

                <div className="text-blue-dark  text-xs md:text-md  ">
                  <b className="text-black">Reviewed By </b>
                  {`${x.customerNickname} - ${x.customerLocation} | Traveled to ${x.customerDestination} `}
                </div>
                <div className="text-gray-700 text-xs md:text-md my-2" style={{ minHeight: '250px' }}>
                  {parser(sanitizeHtml(x.review))}
                  { x.review.length > 400 ? parser(`${sanitizeHtml(x.review.slice(0, 400))}...`)
                    : parser(`${sanitizeHtml(x.review)}`)}
                </div>
              </div>

            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
  const goToBio = () => {
    if (agent.addlBioId === null || agent.addlBioId === undefined) { history.push(`/agent/${agent.agentId}`); } else { history.push(`/agent/${agent.agentId}/bio/${agent.addlBioId}`); }
  };

  const actionButtons = () => (
    <div className="md:text-right md:flex-1 flex flex-row md:flex-row gap-4 items-center">

      <div className="flex flex-row gap-4">
        {agent.email !== '' && (

        <div
          className={['emailme rounded-full p-2 cursor-pointer', agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
          onClick={() => (agent.isOffline !== '1' ? setIsEmailFormModalOpen(true) : {})}
        >
          <IoMail className="text-white w-4 h-4" />
        </div>

        )}

        {agent.phone !== '' && (

        <div
          className={['callme rounded-full p-2 cursor-pointer', agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
          onClick={() => (agent.isOffline !== '1' ? setIsPhoneFormModalOpen(true) : {})}
        >
          <IoCall className="text-white w-4 h-4" />
        </div>

        )}

        {/* {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
        <div
          className={['rounded-full p-2 cursor-pointer', agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
          onClick={() => (agent.isOffline !== '1' ? setIsAppointmentFormModalOpen(true) : {})}
        >
          <IoCalendar className="text-white w-4 h-4" />
        </div>

        ) } */}
      </div>
      <div className="flex">
        <ButtonBlue title="Profile" onClick={() => goToBio()} />
      </div>
    </div>
  );

  const getTitle = () => {
    // Blog has a destination attribute set/ Been there /Book it/ Years of experience
    if (blog.destinations.length > 0 && agent.bookedIt && agent.beenThere && agent.yearsActive) {
      return `Hi! I'm ${agent.firstName} ${agent.lastName} located in ${agent.city}, ${agent.state}  with expert knowledge about ${blogAttribute}  by having visited ${agent.visitCount} times  as well as  booked guests ${agent.bookingCount} times and  having ${agent.yearsActive} years of experience in the industry.`;
    }
    if (blog.destinations.length > 0 && agent.beenThere && agent.yearsActive) {
      return `Hi! I'm ${agent.firstName} ${agent.lastName} located in ${agent.city}, ${agent.state}  with expert knowledge about ${blogAttribute}  by having visited ${agent.visitCount} times  and  having ${agent.yearsActive} years of experience in the industry.`;
    } if (blog.destinations.length > 0 && agent.bookedIt && agent.yearsActive) {
      return `Hi! I'm ${agent.firstName} ${agent.lastName} located in ${agent.city}, ${agent.state}  with expert knowledge about ${blogAttribute}  by having booked guests ${agent.bookingCount} times  and  having ${agent.yearsActive} years of experience in the industry.`;
    } if (blog.destinations.length > 0 && agent.yearsActive) {
      return `Hi! I'm ${agent.firstName} ${agent.lastName} located in ${agent.city}, ${agent.state}  with expert knowledge about ${blogAttribute} and  having ${agent.yearsActive} years of experience in the industry.`;
    } if (agent.yearsActive) {
      return `Hi! I'm ${agent.firstName} ${agent.lastName} located in ${agent.city}, ${agent.state}  having ${agent.yearsActive} years of experience in the industry.`;
    }
    if (blog.destinations.length > 0) {
      return `Hi! I'm ${agent.firstName} ${agent.lastName} located in ${agent.city}, ${agent.state} with expert knowledge about ${blogAttribute}.`;
    }

    return `Hi! I'm ${agent.firstName} ${agent.lastName} located in ${agent.city}, ${agent.state}.`;
  };

  const {
    setIsEmailFormModalOpen, setIsPhoneFormModalOpen,
    setIsAppointmentFormModalOpen,
    isAppointmentFormModalOpen,
    isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal,
  } = useAppStore();

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="md:ml-20 pl-8 md:pl-0 ">
          <div className="rounded-tl-3xl flex flex-col md:flex-row gap-6 bg-gray-verylight w-full md:items-center border border-gray-100">

            <div className="md:justify-center items-center md:items-start flex md:flex-col gap-4">
              <img
                alt="Agent"
                src={agentPhotoFileName}
                className="w-32 md:w-52 rounded-tl-3xl cursor-pointer hover:opacity-75"
                onClick={() => goToBio()}
              />
              <div className="flex flex-col gap-1 justify-start text-left md:hidden">
                <h1 className="text-gray-700 text-xl font-semibold">{`${firstName} ${lastName}`}</h1>
                <div className="text-blue-dark text-sm font-semibold cursor-pointer" onClick={() => goToBio()}>
                  {blogAttribute}
                  {' '}
                  Travel Expert
                </div>
                <div className="text-gray-700 font-thin text-sm md:text-2xl">{`${city}, ${state}`}</div>
              </div>

            </div>
            <div className="flex flex-col items-start gap-2 px-4">
              <div className=" flex-row gap-2 items-center justify-start text-left hidden-tw md:flex">
                <h1 className="text-gray-700 text-xl md:text-3xl font-semibold">{`${firstName} ${lastName}`}</h1>
                <div className="text-gray-800 font-bold">|</div>
                <div className="text-gray-700 my-2 font-thin text-sm md:text-2xl">{`${city}, ${state}`}</div>

              </div>
              <div className="text-blue-dark text-xl font-semibold cursor-pointer  hidden-tw md:flex" onClick={() => goToBio()}>
                {' '}
                {blogAttribute}

                {' '}
                Travel Expert
              </div>
              <div className="w-full my-2 md:hidden">
                {actionButtons()}
              </div>
              <div className="text-sm ">
                {agent && agent.totalReviews > 0
              && (
                <div
                  className="flex flex-col gap-1"
                >
                  <div>

                    <StarRatings
                      rating={agent.rating}
                      starRatedColor="#33C590"
                      numberOfStars={5}
                      name="rating"
                      starDimension="20px"
                      starSpacing="2px"
                    />

                  </div>
                  <div className="flex flex-row gap-2 cursor-pointer font-thin text-xs md:text-sm">
                    <div
                      className=""
                    >
                      {agent.totalReviews}
                      {' '}
                      {agent.totalReviews > 1 ? 'reviews' : 'review'}
                    </div>
                    <div className="">
                      {' / '}
                      {((agent.recommended / agent.totalReviews) * 100).toFixed(2)}
                      % Recommended
                    </div>

                  </div>
                  <div>
                    {agent.yearsActive && (
                    <div className="font-bold text-gray-700">
                      {agent.yearsActive}
                      {' '}
                      Years of Experience
                    </div>
                    )}
                  </div>

                </div>
              )}

              </div>
              <div className="w-full my-2 hidden-tw md:block">
                {actionButtons()}
              </div>
            </div>
            <div className="flex justify-end md:mr-10 ml-10 md:ml-0 flex-1">
              <div className="max-w-xl
            rounded-2xl
            flex flex-col justify-center bg-gray-50
            gap-4 text-xs md:text-base py-4 px-8 my-4 text-gray-600"
              >
                <div>
                  {getTitle()}
                </div>
                <div>
                  Reach out and let me know what you’re looking for to make your vacation perfect
                </div>

              </div>
            </div>
          </div>
        </div>
        {agent.agentReviews && agent.agentReviews.length > 0 && agentReviews()}
        {/*{ ((agent.agentCertifications && agent.agentCertifications.length > 0) || certificates.length > 0)*/}
        {/* && <AgentCertificates certificates={certificates} agent={agent} />}*/}
      </div>
      <Modal
        isOpen={isEmailFormModalOpen || isPhoneFormModalOpen || isAppointmentFormModalOpen}
        onRequestClose={() => closeFormModal()}
        style={modalCustomStyles}
        contentLabel="Form Modal"
        ariaHideApp={false}
      >
        <div>
          <div className="flex justify-end cursor-pointer" onClick={() => closeFormModal()}>
            <IoClose className="text-red-500 text-right" />
          </div>
          {isEmailFormModalOpen && <EmailForm agent={agent} />}
          {isPhoneFormModalOpen && (
          <PhoneForm
            agent={agent}
            phone={agent.phone}
            callBack={() => {
              setIsPhoneFormModalOpen(false);
              setIsEmailFormModalOpen(true);
            }}
          />
          ) }
          {isAppointmentFormModalOpen && (
          <AppointmentForm
            agent={agent}
          />
          ) }

        </div>
      </Modal>

    </>
  );
}

export default AgentLabelBlog;
