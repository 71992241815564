import React from 'react';

import { slide as Menu } from 'react-burger-menu';
import Logo from './Logo';

function Header() {
    const headers = [
        {
            name: 'FIND A TRAVEL AGENT',
            link: '/find_travel_agent',
            bgColor: 'bg-blue-dark',
            textColor: 'text-white',
        },
        {
            name: 'FIND YOUR PERFECT CRUISE',
            link: '/find_your_cruise',
            bgColor: 'bg-blue-dark',
            textColor: 'text-white',
        },
        {
            name: 'TRAVEL INFORMATION',
            link: '/travel_information',
            bgColor: 'bg-white',
            textColor: 'text-black',
        },
        {
            name: 'ABOUT US',
            link: '/about_us',
            bgColor: 'bg-gray-normal',
            textColor: 'text-black',
        },
    ];

    return (
        <>
            <div className="md:hidden bg-green-light z-50 w-full top-0 fixed flex" style={{ zIndex: '10000' }}>
                <Menu className="bg-white justify-end right-0" itemClassName="my-4">
                    {headers.map((x, index) => (
                        <a
                            key={x.name}
                            href={x.link}
                            className={[
                                'text-sm font-semibold border-gray-500 text-white mx-auto px-4 hover:text-blue-dark',
                                'whitespace-nowrap w-full',
                                index === 0 ? 'rounded-full' : '',
                            ].join(' ')}
                        >
                            {x.name}
                        </a>
                    ))}
                </Menu>
                <div className="flex flex-row gap-4 p-4">
                    <Logo />
                </div>
            </div>

            <header className="justify-between fixed top-0 bg-white w-full z-50 md:flex hidden-tw" style={{ zIndex: '10000' }}>
                <div className="bg-green-light w-1/2 text-white pl-12 hidden-tw md:block">
                    <div className="py-3 flex flex-row gap-4 p-4">
                        <Logo />
                        <div className="ml-2">
                            <a
                                key={headers[0].name}
                                href={headers[0].link}
                                className={[
                                    'text-sm font-semibold text-black px-4 relative',
                                    'whitespace-nowrap w-full rounded-full',
                                    'md:border-black md:border',
                                    'transition-all duration-300 hover:text-blue-dark hover:border-blue-dark',
                                    'p-2',
                                ].join(' ')}
                            >
                                {headers[0].name}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="md:flex flex-1 hidden-tw uppercase">
                    {headers.slice(1).map((x, index) => (
                        <a
                            key={x.name}
                            href={x.link}
                            className={[
                                'text-sm font-semibold w-full py-3 text-center px-4',
                                index + 1 !== headers.length ? ' ' : '',
                                x.textColor,
                                x.bgColor,
                                'whitespace-nowrap',
                            ].join(' ')}
                        >
                            {x.name}
                        </a>
                    ))}
                </div>
            </header>


        </>
    );
}

export default Header;
