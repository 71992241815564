import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select, { createFilter } from 'react-select';
import PropTypes from 'prop-types';
import { fetchRevelexDestinations, fetchRevelexSuppliers } from '../services/API';
import Button from './elements/Button';
import SelectInput from './elements/SelectInput';
import customPlaceholder from '../App.css';
import 'react-datepicker/dist/react-datepicker.css';
import { customSelectStyles, filterConfigFindYourCruise } from '../Utils';

function SearchBoxCruises({ destinationId, destinationName, interest }) {
    const [selectedCruiseCategory, setSelectedCruiseCategory] = useState('default');
    const [selectedDestination, setSelectedDestination] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fromDurationNights, setFromDurationNights] = useState('');
    const [toDurationNights, setToDurationNights] = useState('');
    const [isDestinationMenuOpen, setIsDestinationMenuOpen] = useState(false);
    const [isCruiseLineMenuOpen, setIsCruiseLineMenuOpen] = useState(false);
    const [destinationsData, setDestinationsData] = useState({});
    const [suppliersData, setSuppliersData] = useState({});
    const [filteredDestinations, setFilteredDestinations] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [allDestinations, setAllDestinations] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const todayDate = new Date();
    const tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(todayDate.getDate() + 1);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const referrerUrl = new URL(document.referrer);
                const destinationIdParam = referrerUrl.searchParams.get('destination');
                const interestParam = referrerUrl.searchParams.get('interest');

                const allDestinationsData = await fetchRevelexDestinations();

                if (
                    allDestinationsData
                    && typeof allDestinationsData === 'object'
                    && 'ocean' in allDestinationsData
                    && 'river' in allDestinationsData
                ) {
                    setAllDestinations(allDestinationsData);

                    let updatedCruiseCategory = 'default';

                    if (destinationIdParam) {
                        const matchedOceanDestination = allDestinationsData.ocean.find((dest) => dest.destinationId === Number(destinationIdParam));
                        const matchedRiverDestination = allDestinationsData.river.find((dest) => dest.destinationId === Number(destinationIdParam));

                        if (matchedOceanDestination) {
                            const { value, label, revelexId } = matchedOceanDestination;

                            updatedCruiseCategory = 'ocean';
                            setSelectedDestination({ value, label: matchedOceanDestination.destination, revelexId });
                        } else if (matchedRiverDestination) {
                            const { value, label, revelexId } = matchedRiverDestination;

                            updatedCruiseCategory = 'river';
                            setSelectedDestination({ value, label: matchedRiverDestination.destination, revelexId });
                        } else {
                            setSelectedDestination('');
                            setSelectedSupplier('');
                        }
                    } else {
                        setSelectedDestination('');
                        setSelectedSupplier('');
                    }

                    

                    if (interest === 'River Cruises' || interestParam === 'River Cruises') {
                        updatedCruiseCategory = 'river';
                        setSelectedCruiseCategory('river');
                        setSelectedDestination('');
                    }

                    if (interest === 'World Cruises' || interestParam === 'World Cruises') {
                        updatedCruiseCategory = 'ocean';
                        setSelectedCruiseCategory('ocean');
                        setSelectedDestination({ value: 'world', label: 'World', revelexId: '42' });
                    }

                    if (interest === 'Transatlantic' || interestParam === 'Transatlantic') {
                        updatedCruiseCategory = 'ocean';
                        setSelectedCruiseCategory('ocean');
                        setSelectedDestination({ value: 'transatlantic', label: 'Transatlantic', revelexId: '38' });
                    }

                    const destinationsToCategory = {
                        Caribbean: 'Caribbean',
                        'Caribbean Sea': 'Caribbean',
                        Mediterranean: 'Mediterranean',
                        'Mediterranean Sea': 'Mediterranean',
                        Alaska: 'Alaska',
                        Europe: 'Europe',
                    };

                    if (destinationName in destinationsToCategory) {
                        setSelectedCruiseCategory('ocean');
                        setSelectedDestination({ value: destinationName.toLowerCase(), label: destinationsToCategory[destinationName] });
                    }

                    const oceanInterests = ['Expedition Cruises', 'Grand Voyages', 'Hosted Cruises', 'Luxury Cruises', 'Small Ship Cruises'];
                    if (oceanInterests.includes(interest) || oceanInterests.includes(interestParam)) {
                        updatedCruiseCategory = 'ocean';
                        setSelectedCruiseCategory('ocean');
                    }

                    setSelectedCruiseCategory(updatedCruiseCategory);
                    await getSuppliers(updatedCruiseCategory);
                    await getDestinations();
                }

            } catch (error) {
                console.error('Error fetching all destinations:', error);
            }
        };
        fetchData();
    }, []);

    const getDestinations = async () => {
        try {
            const data = await fetchRevelexDestinations();
            setDestinationsData(data);

            const selectedDestinations = data[selectedCruiseCategory.toLowerCase()] || [];

            const formattedDestinations = selectedDestinations.map((dest) => ({
                value: dest.destinationId,
                label: dest.destination,
                type: selectedCruiseCategory,
                revelexId: dest.revelexId,
            }));

            setFilteredDestinations(formattedDestinations);
        } catch (error) {
            console.error('Error fetching destinations:', error);
        }
    };

    const getSuppliers = async (category) => {
        try {
            const data = await fetchRevelexSuppliers();
            setSuppliersData(data);

            const selectedSuppliers = data[category.toLowerCase()] || [];

            const formattedSuppliers = selectedSuppliers.map((supp) => ({
                value: supp.supplierId,
                label: supp.supplierName,
                type: category,
                revelexId: supp.revelexId,
            }));

            setFilteredSuppliers(formattedSuppliers);
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
    };

    const onSearch = async () => {
        if (selectedCruiseCategory !== 'default') {
            const isOceanCategory = selectedCruiseCategory === 'ocean';
            const searchType = isOceanCategory ? 'cruise_only' : '';
            const cruiseCat = isOceanCategory ? 'cruise' : 'river_cruise';

            const supplierId = selectedSupplier ? selectedSupplier.revelexId : '';
            const searchDestinationId = selectedDestination ? selectedDestination.revelexId : -1;

            const toDurationNightsValue = toDurationNights || 30;
            const formDurationNightsValue = fromDurationNights || 0;

            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);

            let startDateString = '';
            let endDateDefault = null;
            let endDateString = '';

            if (startDate) {
                const startDatePlus30Days = new Date(startDate);
                startDatePlus30Days.setDate(startDatePlus30Days.getDate() + 30);

                startDateString = startDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });

                if (endDate) {
                    endDateString = endDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    });
                } else {
                    const endDatePlus30Days = new Date(startDate);
                    endDatePlus30Days.setDate(endDatePlus30Days.getDate() + 30);

                    endDateString = endDatePlus30Days.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    });
                }
            } else {
                startDateString = tomorrow.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });

                if (!endDate) {
                    const endDateDefaultPlus30Days = new Date(tomorrow);
                    endDateDefaultPlus30Days.setDate(endDateDefaultPlus30Days.getDate() + 30);

                    endDateDefault = endDateDefaultPlus30Days;
                    endDateString = endDateDefault.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    });
                }
                else {
                    endDateString = endDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    });
                }
            }

            if (startDate && endDateDefault && startDate.getTime() > endDateDefault.getTime()) {
                alert('Please enter a valid value in the "Start Date" field. Value should be equal or greater than the "End Date" field.');
                return;
            }

            if (startDate && endDate && startDate.getTime() === endDate.getTime()) {
                alert('Please check date fields. Dates should not be equal.');
                return;
            }

            if (startDateString && endDateString && new Date(endDateString) < new Date(startDateString)) {
                alert('Please check date fields. End Date should not be lower than Start Date.');
                return;
            }

            if (toDurationNights !== '' && fromDurationNights !== '' && fromDurationNights > toDurationNights) {
                alert('Please enter a valid value in the "To" field. Value should be equal or grater than the "From" field.');
                return;
            }

            if (fromDurationNights > '30' && toDurationNights === '') {
                alert('Please enter a valid value in the "To" field. Value should be equal or grater than the "From" field.');
                return;
            }


            const searchTypeParam = searchType ? `search[search_type]=${searchType}&` : '';
            const supplierRevelexId = supplierId ? `search[vendor_ids][]=${supplierId}&` : '';

            const searchUrl = `https://res.vacation.com/app/0/${cruiseCat}/0/search_cruises.html?${searchTypeParam}search[start_date]=${encodeURIComponent(startDateString)}&search[end_date]=${encodeURIComponent(endDateString)}&search[destination_ids][]=${searchDestinationId}&${supplierRevelexId}search[duration_min]=${formDurationNightsValue}&search[duration_max]=${toDurationNightsValue}`;

            window.open(searchUrl, '_blank');
        } else {
            alert('Please select a Cruise Category.');
        }
    };

    const onInputChangeDestination = (val) => {
        if (val !== '') {
            setIsDestinationMenuOpen(true);

            const category = selectedCruiseCategory || 'ocean';

            const filteredOptions = destinationsData[category.toLowerCase()]
                .filter((dest) => dest.destination.toLowerCase().includes(val.toLowerCase()))
                .map((x) => ({ value: x.destinationId, label: x.destination, revelexId: x.revelexId }));

            setFilteredDestinations(filteredOptions);
        } else {
            setIsDestinationMenuOpen(false);
        }
    };

    const onInputChangeCruiseLine = (val) => {
        if (val !== '') {
            setIsCruiseLineMenuOpen(true);

            const category = selectedCruiseCategory || 'ocean';
            const filteredOptions = suppliersData[category.toLowerCase()]
                .filter((supp) => supp.supplierName.toLowerCase().includes(val.toLowerCase()))
                .map((x) => ({ label: x.supplierName, revelexId: x.revelexId.toString() }));

            setFilteredSuppliers(filteredOptions);
        } else {
            setIsCruiseLineMenuOpen(false);
        }
    };

    const onSelectedCruiseCategory = async (newCategory) => {
        setSelectedCruiseCategory(newCategory);
        setIsMenuOpen(false);
        setFilteredDestinations([]);
        setFilteredSuppliers([]);

        if (newCategory !== 'default') {
            await getDestinations();
            await getSuppliers(newCategory);
        }

        setSelectedDestination('');
        setSelectedSupplier('');
    };

    const onSelectedCruiseLine = (selectedSupplierValue) => {
        const selectedSupplierObject = filteredSuppliers.find((supplier) => {
            console.log();
            return supplier.value === parseInt(selectedSupplierValue, 10);
        });
        setSelectedSupplier(selectedSupplierObject);
    };

    return (
        <div className={`bg-transparent md:bg-white md:shadow-xl z-40 rounded-lg md:-ml-2 ${isMobile ? 'mb-4' : 'mb-40'} ${isMobile ? 'mt-4' : 'mt-4'}`}>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-8 md:bg-gray-200 bg-transparent rounded-t-lg px-6 py-3 md:pl-20">
                <div className="flex flex-col justify-center">
                    <h1 style={{ fontWeight: 'bold', fontSize: '12px', color: isMobile ? 'white' : 'black' }}>CRUISE CATEGORY</h1>
                    <div>
                        <SelectInput
                            styles={customSelectStyles}
                            className={`w-full rounded-full text-sm ${selectedCruiseCategory === 'default' && 'disabled'}`}
                            options={[
                                { label: 'Select Cruise Category', value: 'default' },
                                { label: 'Ocean Cruises', value: 'ocean' },
                                { label: 'River Cruises', value: 'river' },
                            ]}
                            placeholder={selectedCruiseCategory === 'ocean' ? 'Ocean Cruises' : 'River Cruises'}
                            onSelected={(newCategory) => onSelectedCruiseCategory(newCategory)}
                            value={selectedCruiseCategory}
                        />
                    </div>
                </div>

                <div className="flex flex-col justify-center">
                    <div>
                        <h1 id="destination-label" style={{ fontWeight: 'bold', fontSize: '12px', color: isMobile ? 'white' : 'black' }}>DESTINATION</h1>
                        <Select
                            aria-labelledby="destination-label"
                            styles={{
                                ...customSelectStyles,
                                menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    fontSize: '12px',
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isDisabled ? '#f2f2f2' : provided.backgroundColor,
                                    color: state.isDisabled ? '#4d4d4d' : provided.color,
                                    cursor: state.isDisabled ? 'not-allowed' : provided.cursor,
                                    borderRadius: '9999px',
                                    borderColor: state.isDisabled ? '#a6a6a6' : provided.borderColor,
                                    '&:hover': {
                                        borderColor: state.isDisabled ? '#a6a6a6' : provided['&:hover'].borderColor,
                                    },
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    color: state.isDisabled ? '#4d4d4d' : provided.color,
                                    padding: '12px',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: 'black',
                                    padding: '12px',
                                }),
                            }}
                            className={`w-full rounded-full text-xs ${selectedCruiseCategory === 'default' && 'disabled'}`}
                            options={[
                                ...filteredDestinations,
                            ]}
                            onChange={(val) => setSelectedDestination(val)}
                            onInputChange={onInputChangeDestination}
                            placeholder="Destination"
                            value={selectedDestination}
                            filterOption={createFilter(filterConfigFindYourCruise)}
                            menuIsOpen={isDestinationMenuOpen}
                            noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                            isDisabled={selectedCruiseCategory === 'default'}
                            components={{ DropdownIndicator: () => null }}
                            menuContainerStyle={{ maxHeight: '200px', overflowY: 'auto', padding: '12px' }}
                        />
                    </div>
                </div>


                <div className="flex flex-col justify-center">
                    <div>
                        <h1 style={{ fontWeight: 'bold', fontSize: '12px', color: isMobile ? 'white' : 'black' }}>CRUISE LINE</h1>
                        <SelectInput
                            styles={customSelectStyles}
                            className={`w-full rounded-full text-sm ${selectedCruiseCategory === 'default' && 'disabled'}`}
                            options={[
                                { label: 'Select Cruise Line', value: 'default' },
                                ...filteredSuppliers,
                            ]}
                            onSelected={onSelectedCruiseLine}
                            onInputChange={onInputChangeCruiseLine}
                            placeholder="Select Cruise Line"
                            value={selectedSupplier && selectedSupplier.label ? selectedSupplier.label : 'Select Cruise Line'}
                            filterOption={createFilter(filterConfigFindYourCruise)}
                            menuIsOpen={isCruiseLineMenuOpen}
                            noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                            isDisabled={selectedCruiseCategory === 'default'}
                        />
                    </div>
                </div>

            </div>

            <div className="grid md:grid-cols-3 grid-cols-1 gap-8 md:bg-gray-200 bg-transparent rounded-b-lg px-6 py-3 md:pl-20">
                <div className={`flex flex-col justify-center ${isMobile ? 'text-center' : ''}`}>
                    <div>
                        <h1 style={{ fontWeight: 'bold', fontSize: '12px', color: isMobile ? 'white' : 'black' }}>
                            START DATE
                        </h1>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Select Start Date"
                            minDate={tomorrowDate}
                            className={`custom-placeholder rounded-full px-4 py-3.5 text-xs border border-gray-300 focus:outline-none focus:border-blue-500 w-full ${isMobile ? 'text-center' : ''}`}
                        />
                    </div>
                </div>

                <div className={`flex flex-col justify-center ${isMobile ? 'text-center' : ''}`}>
                    <div>
                        <h1 style={{ fontWeight: 'bold', fontSize: '12px', color: isMobile ? 'white' : 'black' }}>
                            END DATE
                        </h1>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="Select End Date"
                            minDate={startDate || tomorrowDate}
                            className={`custom-placeholder rounded-full px-4 py-3.5 text-xs border border-gray-300 focus:outline-none focus:border-blue-500 w-full ${isMobile ? 'text-center' : ''}`}
                        />
                    </div>
                </div>

                <div className={`flex flex-col justify-center relative ${isMobile ? 'text-center' : ''}`}>
                    <h1 style={{ fontWeight: 'bold', fontSize: '12px', color: isMobile ? 'white' : 'black' }}>
                        DURATION
                    </h1>
                    <div className="flex items-center">
                        <input
                            type="number"
                            value={fromDurationNights}
                            onChange={(e) => {
                                const numericValue = Math.max(0, parseInt(e.target.value, 10));
                                setFromDurationNights(Number.isNaN(numericValue) ? '' : numericValue);
                            }}
                            placeholder="From (Nights)"
                            className={`custom-placeholder rounded-full px-4 py-3.5 text-xs border border-gray-300 focus:outline-none focus:border-blue-500 w-1/2 ${isMobile ? 'text-center' : ''}`}
                        />
                        <input
                            type="number"
                            value={toDurationNights}
                            onChange={(e) => {
                                const numericValue = Math.max(0, parseInt(e.target.value, 10));
                                setToDurationNights(Number.isNaN(numericValue) ? '' : numericValue);
                            }}
                            placeholder="To (Nights)"
                            className={`custom-placeholder rounded-full px-4 py-3.5 text-xs border focus:outline-none focus:border-blue-500 w-1/2 ${isMobile ? 'text-center' : ''}`}
                        />
                    </div>
                </div>



                <div className="flex flex-col justify-center">{' '}</div>
                <div className="flex flex-col justify-center">
                    <Button
                        title="GET STARTED"
                        onClick={onSearch}
                        className="mx-auto"
                    />
                </div>
                <div className="flex flex-col justify-center">{' '}</div>
            </div>
        </div>
    );
}

SearchBoxCruises.propTypes = {
    destinationId: PropTypes.string,
    destinationName: PropTypes.string,
    interest: PropTypes.string,
};

SearchBoxCruises.defaultProps = {
    destinationId: '',
    destinationName: '',
    interest: '',
};

export default SearchBoxCruises;
