/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import parser from 'html-react-parser';
import sanitizeHtml from 'sanitize-html-react';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import SlideDown from 'react-slidedown';
import { wrapperClass } from '../Utils';
import API from '../services/API';
import Button from './elements/Button';

function AgentClientReview({
  agent, refs, callback, setLoading,
}) {
  const [itemToShow] = useState(5);
  const [showFull, setShowFull] = useState(false);

  const isRequestedBefore = (reviewId) => {
    if (localStorage.getItem(`review-${reviewId}`)) {
      return true;
    }
    return false;
  };

  const sendHelpful = (reviewId, value) => {
    localStorage.setItem(`review-${reviewId}`, value);

    setLoading(true);
    API.sendReview({ reviewId, helpful: value }).then((response) => {
      if (response.responseStatus === 1) {
        toast.success('Your feedback has been sent');
        if (callback) { callback(); }
      } else {
        toast(response.error.message);
      }
      setLoading(false);
    });
  };

    const sanitizeReview = (content) => {
        const contentString = content.toString();

        const sanitizedContent = contentString.replace(/\[object Object\],/g, ' ');

        return sanitizeHtml(sanitizedContent, {
            allowedTags: [],
            allowedAttributes: {},
        });
    };

  return (

    <div
      className={[wrapperClass, 'bg-blue-dark text-white px-4 text-center md:text-left md:px-20 md:mt-20'].join(' ')}
    >
      <div className=" text-xl md:text-4xl mt-4 font-bold">Client Reviews</div>
      <div className="flex flex-col gap-10 md:gap-10 md:py-6 py-3">
        <div className="text-sm md:text-xl ">
          <div
            className="flex flex-col gap-1 cursor-pointer min-w-max"
          >
            <div>

              <StarRatings
                rating={agent.agentRating.rating}
                starRatedColor="#33C590"
                numberOfStars={5}
                name="rating"
                starDimension="16px"
                starSpacing="2px"
              />

            </div>

            <div>
              Rating:
              {' '}
              {agent.agentRating.rating}
            </div>
            <div className="flex flex-row gap-3 text-xs justify-center md:justify-start">

              <div className="">
                {' '}
                {((agent.agentRating.recommended / agent.agentRating.totalReviews) * 100).toFixed(2)}
                % Recommended
              </div>
              <div>|</div>
              <div>
                {agent.agentRating.totalReviews}
                {' '}
                {agent.agentRating.totalReviews > 1 ? 'reviews' : 'review'}
              </div>
            </div>
          </div>

        </div>
        <div className="flex flex-col gap-4  md:px-10 md:border-l border-white text-left">
          <SlideDown className="my-dropdown-slidedown">
            {agent.agentReviews.slice(0, showFull ? agent.agentReviews.length : itemToShow).map((x) => (
              <div key={x.reviewId} className="flex flex-col gap-2 md:mb-6 mb-12" ref={refs[x.reviewId]}>
                <div className=" font-bold text-sm md:text-2xl uppercase">
                    <span dangerouslySetInnerHTML={{ __html: sanitizeReview(x.reviewTitle) }} />
                </div>
                <div className="flex flex-col md:flex-row gap-3 md:items-center">
                  <div>
                    <StarRatings
                      rating={x.rating}
                      starRatedColor="#FD6A4A"
                      numberOfStars={5}
                      name="rating"
                      starDimension="16px"
                      starSpacing="2px"
                    />
                  </div>

                  <div className="flex flex-col md:flex-row text-sm mt-1 gap-2">
                    <div>Reviewed By</div>
                    <div><span className="text-white">{`${x.customerNickname} - ${x.customerLocation} | Traveled to ${x.customerDestination}`}</span></div>
                  </div>
                </div>
                    <div className="text-sm md:text-lg my-2">
                        <span dangerouslySetInnerHTML={{ __html: sanitizeReview(parser(x.review)) }} />
                  
                </div>
                {x.agentResponse && (
                <div className="flex flex-col gap-1 text-xs md:text-sm  self-end mt-auto w-full">
                  <div className="font-medium text-white">{`${agent.firstName} ${agent.lastName} responded`}</div>
                  <div className="p-2 italic border-l-4 border-neutral-500 quote text-blue-dark bg-gray-light">
                    {`"${x.agentResponse}"`}

                  </div>
                </div>
                )}
                <div className="text-sm md:text-lg my-2">
                  {x.helpful}
                  {' '}
                  of
                  {' '}
                  {x.helpful + x.notHelpful}
                  {' '}
                  people found this review helpful. Was this review helpful to you?
                  <span
                    className={[isRequestedBefore(x.reviewId) ? 'text-white' : 'text-white cursor-pointer', ' px-1'].join(' ')}
                    onClick={() => (!isRequestedBefore(x.reviewId) ? sendHelpful(x.reviewId, true) : {})}
                  >
                    YES

                  </span>
                  |
                  <span
                            className={[isRequestedBefore(x.reviewId) ? 'text-white' : 'text-white cursor-pointer',
                      ' px-1 '].join(' ')}
                    onClick={() => (!isRequestedBefore(x.reviewId) ? sendHelpful(x.reviewId, false) : {})}
                  >
                    NO

                  </span>
                </div>
              </div>
            ))}
          </SlideDown>
          {agent.agentReviews.length > itemToShow && (
          <div className="text-xs mb-1 cursor-pointer font-semibold text-gray-800 hover:text-gray-500 mt-2 md:w-1/4">
            {!showFull ? (
              <Button
                title="VIEW MORE REVIEWS"
                onClick={() => setShowFull(!showFull)}
              />
            ) : (
              <Button
                title="VIEW LESS REVIEWS"
                onClick={() => {
                  setShowFull(!showFull);
                }}
              />
            )}
          </div>
          )}
        </div>
      </div>
    </div>

  );
}

export default AgentClientReview;
