/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoChevronForward, IoChevronDown } from 'react-icons/io5';

function ListItem({
  title, children, onClick, onParentClicked,
  isClickable, opened,
}) {
  const [isOpened, setIsOpened] = useState(opened);

  const clickHandler = () => {
    setIsOpened(!isOpened);
    onClick();
  };
  return (
    <div className="my-1 ">
      <div className="flex flex-row gap-1 text-left items-center text-xs md:text-base">
        <div className={[isClickable ? 'hover:text-black cursor-pointer ' : '']} onClick={onParentClicked}>
          {title}
        </div>
        {children && (
        <div className="cursor-pointer" onClick={clickHandler}>
          {!isOpened && <div className="text-green-light hover:text-black"><IoChevronForward /></div> }
          {isOpened && <div className="text-green-light hover:text-black"><IoChevronDown /></div> }
        </div>
        )}
      </div>
      {isOpened && (
      <div className="mt-2">
        <div className="flex flex-col gap-2 px-4 text-left text-xs font-normal">{children}</div>
      </div>
      )}
    </div>
  );
}

ListItem.defaultProps = {
  children: '',
  onClick: () => {},
  onParentClicked: () => {},
  isClickable: false,
  opened: false,

};
ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any,
  onParentClicked: PropTypes.func,
  isClickable: PropTypes.bool,
  opened: PropTypes.bool,
};

export default ListItem;
