import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';
import useMobileDetect from 'use-mobile-detect-hook';
import Button from '../components/elements/Button';
import Input from '../components/elements/Input';
import Loading from '../components/elements/Loading';
import TopReviews from '../components/TopReview';
import SearchBox from '../components/SearchBox';
import useAppStore from '../contexts/useAppStore';
import ListItem from '../components/elements/ListItem';
import {
    downCurve,
    getStateName, popularInterest, searchTypes, upCurve,
} from '../Utils';
import BreadCrumb from '../components/BreadCrumb';
import ArrowDown from '../components/elements/ArrowDown';
import ArrowUp from '../components/elements/ArrowUp';

import 'react-slidedown/lib/slidedown.css';

function FindATravelAgent() {
    const history = useHistory();
    const detectMobile = useMobileDetect();
    const [showmetro, setShowmetro] = useState(true);
    const [showPopularDestination, setShowPopularDestination] = useState(false);
    const [showPopularInterest, setShowPopularInterest] = useState(false);

    const links = [{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a Travel Agent',
    },
    ];
    const {
        isFetchingAgent, setSelectedZipCode,
        setSelectedAgentName, selectedZipCode, selectedAgentName,
        selectedInterest,
        getDestinations,
        popularDestinations,
        getStates,
    } = useAppStore();

    const onSearch = (type) => {
        const filters = [];

        if (type === searchTypes.ZIPCODE) {
            filters.push(`zipCode=${selectedZipCode}`);
        } else if (selectedInterest !== '') {
            filters.push(`agentName=${selectedAgentName}`);
        } else {
            return false;
        }

        history.push(`/agents?${filters.join('&')}`);
        return true;
    };

    useEffect(() => {
        if (detectMobile.isDesktop()) {
            setShowmetro(true);
            setShowPopularDestination(true);
            setShowPopularInterest(true);
        }
    }, [detectMobile]);

    const popularDestinationLayout = (popularDestination) => (
        <>

            <div className="text-left my-2 md:border-l border-blue-dark pl-4">
                <div
                    className=" font-semibold mb-1 md:mb-5  text-sm md:text-base cursor-pointer hover:text-gray-800"
                    onClick={() => (getDestinations.some((x) => x.destinationId === parseInt(popularDestination.id)) ? history.push(`/agents?destination=${popularDestination.id}&destinationLabel=${getDestinations.find((x) => x.destinationId === parseInt(popularDestination.id)).destination}`) : {})}
                >
                    {popularDestination.location}

                </div>
                {popularDestination.destination.map((y) => (
                    <div
                        className="hover:black text-sm"
                        key={y.id}
                        onClick={() => (getDestinations.some((x) => x.destinationId === parseInt(y.id)) ? history.push(`/agents?destination=${y.id}&destinationLabel=${getDestinations.find((x) => x.destinationId === parseInt(y.id)).destination}`) : {})}
                    >

                        <ListItem
                            title={y.location}
                            isClickable
                        />
                    </div>
                ))}
            </div>
        </>
    );

    return (
        <>
            <div role="main">
            <Helmet>
                <title>Find a Travel Agent Anywhere with Vacation.com</title>
                <meta name="title" content="Find a Travel Agent Anywhere with Vacation.com" />
                <meta name="description" content="Find a Travel Agent Near You" />
            </Helmet>

            <div className=" min-h-screen mt-16">

                <div
                    className="relative flex flex-col  justify-center min-h-full bg-cover w-full -mt-10 overflow-x-hidden"
                    style={{
                        height: '700px', background: 'url(\'media/homepage/find_travel_agent.webp\')', backgroundSize: 'cover', backgroundPosition: '0px -160px',
                    }}
                >
                    <div className="absolute right-0 w-1/2 opacity-40 -mr-24 top-40 hidden-tw md:block">{downCurve}</div>
                    <div className="ml-2 mt-12 md:-mt-16 z-20">
                        <BreadCrumb links={links} inverted />
                    </div>
                    <div className="flex flex-col md:gap-48 text-center pt-4 pb-20">

                        <div className="justify-center text-center py-10 z-10">
                            <h1 className="text-2xl md:text-3xl font-bold leading-tight text-white  uppercase mt-20">
                                Find a travel agent
                            </h1>

                        </div>
                        <div className=" md:mr-32 right-0 z-10 text-left">
                            <SearchBox />
                        </div>
                    </div>
                    <div className="absolute inset-0 w-full h-full bg-black bg-opacity-20" />
                    <div className="absolute left-0 w-1/2 opacity-30 -ml-80 bottom-10 hidden-tw md:block">{upCurve}</div>
                </div>
                <div className=" max-w-screen-2xl mx-auto bg-white">
                    <div className="">

                        <section className="flex flex-col justify-center py-16 text-center mx-auto gap-10  md:px-0 relative overflow-x-hidden">
                            <div className="absolute right-0 top-4 -mr-64 w-1/3 hidden-tw md:block">{upCurve}</div>
                            <div className="text-blue-dark text-xl md:text-3xl font-semibold text-center tracking-wide px-4 md:px-0">
                                FIND A TRAVEL AGENT BY

                            </div>
                            <div className="flex flex-col md:flex-row  justify-between gap-2 text-center md:text-left">
                                <div className="flex flex-col justify-center bg-gray-verylight py-4 px-16 md:rounded-r-2xl">

                                    <div className="uppercase text-lg tracking-widest font-bold">Zipcode</div>
                                    <div className="text-sm text-gray-700">Enter a zip code below to find the agents nearest to that location</div>
                                    <div className="mt-2 flex flex-col md:flex-row gap-4 justify-center">
                                        <Input
                                            value={selectedZipCode}
                                            onChange={(val) => setSelectedZipCode(val)}
                                            aria-label="Enter your ZIP code"
                                            placeholder="Enter your ZIP code"
                                        />
                                        <Button title="GET STARTED" onClick={() => onSearch(searchTypes.ZIPCODE)} />
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center bg-gray-verylight py-4 px-16 md:rounded-l-2xl">

                                    <div className="uppercase  text-lg tracking-widest font-bold">Agent name</div>
                                    <div className="text-sm text-gray-700">Enter a first and last name below to find a specific travel agent.</div>
                                    <div className="mt-2 flex flex-col md:flex-row gap-4 justify-center">
                                            <Input value={selectedAgentName} onChange={(val) => setSelectedAgentName(val)} aria-label="Enter agent name" placeholder="Enter an agent name" />
                                        <Button title="GET STARTED" onClick={() => onSearch(searchTypes.AGENTNAME)} />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10 text-center md:text-left py-4 md:px-16 px-4 bg-gray-verylight md:rounded-r-2xl md:mr-32">

                                <div className="text-gray-600 text-lg md:text-xl font-bold  flex flex-row justify-center md:justify-start items-center" id="metroArea">
                                    <div onClick={() => setShowmetro(!showmetro)} className="block md:hidden">
                                        {showmetro ? <ArrowUp /> : <ArrowDown />}
                                    </div>

                                    <div>POPULAR METRO AREA</div>
                                </div>
                                <div className="text-xs px-10 text-gray-700 my-4 mb-10 md:px-0 md:text-sm">Select a popular metro area or State/Province from list below</div>
                                <SlideDown className="my-dropdown-slidedown">
                                    {showmetro
                                        && (
                                            <div className="flex flex-col md:flex-row gap-10 text-left my-4">
                                                <div className="grid grid-cols-2  md:grid-cols-6  gap-1 text-sm font-semibold text-gray-700 px-0 ">
                                                    {getStates().map((x) => (
                                                        <div key={x.state}>
                                                            <div className="w-56 lg:w-56 md:w-24 cursor-pointer hover:text-black " onClick={() => history.push(`/agents?state=${x.state}`)}>
                                                                <div className="md:w-24 lg:w-32">
                                                                    {getStateName(x.state)}
                                                                </div>
                                                            </div>
                                                            <div className="mt-2">

                                                                {
                                                                    x.metroArea.length > 0
                                                                        ? x.metroArea.map((metro) => (
                                                                            <div
                                                                                key={metro.metroRegion}
                                                                                className="hover:text-black cursor-pointer font-normal mt-2 text-xs"
                                                                                onClick={() => history.push(`/agents?metro=${metro.metroRegion}&state=${x.state}`)}
                                                                            >
                                                                                {metro.metroRegion}
                                                                            </div>
                                                                        ))
                                                                        : null
                                                                }

                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        )}
                                </SlideDown>

                            </div>
                        </section>
                        <section className="my-2 px-2 md:px-2  bg-blue-dark">
                            <TopReviews inverted title="What our customers are saying about our travel experts" />
                        </section>

                        <section className="flex flex-col justify-center py-10 pb-32 text-center mx-auto gap-20  md:px-10 px-1 relative overflow-x-hidden">
                            <div className="absolute right-0 bottom-4 -mr-64 w-1/3 hidden-tw md:block">{upCurve}</div>
                            <div>
                                <div className="text-blue-dark text-xl md:text-3xl font-bold text-center  tracking-wide">
                                    FIND A TRAVEL SPECIALIST

                                </div>
                                <div className="text-sm text-gray-700">Find a professional travel agent who specializes in a destination or travel interest.</div>

                            </div>

                            <div className="flex flex-col   md:gap-10 md:px-10 px-4">
                                <div className="flex flex-col justify-center w-full border-b border-t md:border-none border-blue-dark py-4">

                                    <div className="uppercase font-bold text-lg mb-8 text-left tracking-widest flex flex-row items-center scroll-margin" id="destination">
                                        <div onClick={() => setShowPopularDestination(!showPopularDestination)} className="block md:hidden">
                                            {showPopularDestination ? <ArrowUp /> : <ArrowDown />}
                                        </div>
                                        <div> Popular destinations</div>
                                    </div>
                                    <SlideDown className="my-dropdown-slidedown">
                                        {showPopularDestination
                                            && (
                                                <>
                                                    {popularDestinations.length > 0 && (
                                                        <>
                                                            <div className="md:flex flex-row justify-around w-full hidden-tw">
                                                                <div>
                                                                    {popularDestinationLayout(popularDestinations[0])}
                                                                </div>
                                                                <div>
                                                                    {popularDestinationLayout(popularDestinations[1])}

                                                                </div>
                                                                <div>

                                                                    {popularDestinationLayout(popularDestinations[2])}
                                                                </div>
                                                                <div>
                                                                    {popularDestinationLayout(popularDestinations[3])}
                                                                </div>
                                                                <div>
                                                                    {popularDestinationLayout(popularDestinations[4])}
                                                                </div>
                                                                <div>{popularDestinationLayout(popularDestinations[5])}</div>

                                                            </div>
                                                            <div className="flex flex-row justify-around w-full md:hidden">
                                                                <div>
                                                                    <div>
                                                                        {popularDestinationLayout(popularDestinations[0])}
                                                                    </div>
                                                                    <div>
                                                                        {popularDestinationLayout(popularDestinations[1])}
                                                                        {popularDestinationLayout(popularDestinations[2])}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        {popularDestinationLayout(popularDestinations[3])}
                                                                    </div>
                                                                    <div>
                                                                        {popularDestinationLayout(popularDestinations[4])}
                                                                        {popularDestinationLayout(popularDestinations[5])}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </>
                                                    )}
                                                </>
                                            )}
                                    </SlideDown>
                                </div>
                                <div className="flex flex-col justify-center py-4 border-b  md:border-none border-blue-dark">

                                    <div className="uppercase font-bold text-lg mb-8 text-left tracking-widest flex flex-row items-center scroll-margin" id="interest">
                                        <div onClick={() => setShowPopularInterest(!showPopularInterest)} className="block md:hidden">
                                            {showPopularInterest ? <ArrowUp /> : <ArrowDown />}
                                        </div>
                                        <div> Popular Interests</div>
                                    </div>
                                    <SlideDown className="my-dropdown-slidedown">
                                        {showPopularInterest
                                            && (
                                                <div className="grid grid-cols-2  md:grid-cols-5  text-xs ">
                                                    {popularInterest.map((x) => (
                                                        <div
                                                            className="hover:text-black w-56 px-4  md:border-l border-blue-dark pl-4"
                                                            key={x}
                                                            onClick={() => history.push(`/agents?interest=${encodeURIComponent(x)}`)}
                                                        >
                                                            <ListItem
                                                                title={x}
                                                                isClickable
                                                            />
                                                        </div>

                                                    ))}

                                                </div>
                                            )}
                                    </SlideDown>

                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
                {isFetchingAgent() && <Loading />}
            </div>
        </>
    );
}

export default FindATravelAgent;
