/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React from 'react';
import { upCurve, downCurve } from '../Utils';

function Hero({ title }) {
  return (
    <div className="relative flex justifiy-center  w-full  overflow-hidden -mt-56 md:-mt-12" style={{ height: '700px' }}>

      <div
        className="absolute  w-full"
        style={{ zIndex: 1 }}

      >
        <video
          autoPlay
          loop
          muted
         // className="absolute z-10 w-auto min-w-full top-0 -mt-12"
          style={{ height: '1000px' }}
          playsInline
          className="object-cover -mt-44 md-:mt-20"
        >
          <source
            src="media/vid.mov"
            type="video/mp4"
          />

        </video>
      </div>

      <div className="text-4xl flex flex-col justify-center text-center z-20 absolute right-0 left-0 -top-10 bottom-0">

        <div className="flex max-w-6xl mx-auto mt-40 md:mt-10">
          <div className="container relative flex flex-col items-center px-4 pb-1 ">
            <img
              alt="Blademar logo"
              src="images/logo.webp"
              className="w-4/5 mb-10 hidden md:flex z-20"
            />
            <h1 className=" z-20 text-xl md:text-4xl font-semibold  text-white uppercase">
              {title}
            </h1>

          </div>

        </div>
        <div className="absolute right-0 w-1/2 opacity-40 -mr-24 top-32 hidden-tw md:block z-20">{downCurve}</div>
        {/* <div className="absolute inset-0 w-full h-full bg-black bg-opacity-30 z-20" /> */}

        <div className="absolute left-0 w-1/2 opacity-40 -ml-80 bottom-20 hidden-tw md:block z-30">{upCurve}</div>
      </div>

    </div>
  );
}

export default Hero;
