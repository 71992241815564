import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function LoadingInline() {
  return (
    <Loader
      type="ThreeDots"
      color="#33C590"
      height={50}
      width={50}
    />
  );
}

export default LoadingInline;
