/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import BreadCrumb from '../components/BreadCrumb';
import ThankYouHero from '../components/ThankYouHero';

function ThankYou() {
  const history = useHistory();
  const links = [{
    label: 'Home',
    url: '/',
  },
  {
    label: 'Thank You',
  },
  ];
  return (
    <div className=" max-w-screen-2xl mx-auto">
      <Helmet>
        <meta
          name="ROBOTS"
          content="NOINDEX, NOFOLLOW"
        />
      </Helmet>
      <div className="bg-white min-h-screen">

        <ThankYouHero />
        <div className="flex flex-col max-w-5xl mx-auto  text-lg pt-5 pb-10 border-b-2 border-black px-4 md:px-0">
          <div className="mb-6">
            <BreadCrumb links={links} />
          </div>
          <div className="font-medium">Dear Customer</div>
          <div>Thank you for your inquiry below!</div>
          <div className="mt-10">
            I’m excited to learn more about you to help make your travel dreams come true. You can expect to hear back from me
            {' '}
            <b>within 24 hours </b>
            to get started! In the meantime, check out the link below for more travel inspiration.

          </div>
        </div>
        <div className="flex px-4 md:px-0  md:max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row justify-evenly w-full py-20  mx-auto gap-10">
            <div className="flex flex-col gap-4 flex-1 items-center">
              <img alt="Thank you" src="https://www.travelleaders.com/images/thankyoucirc-1.jpg" className="rounded-full w-64 border-4 border-gray-300" />
              <div className="cursor-pointer text-xl md:text-2xl font-semibold text-blue-dark" onClick={() => history.push('/about_us')}>GLOBAL DESTINATION EXPERTS</div>
              <div className=" text-base md:text-lg">Vacation.com agents are certified specialists with world-class professional training and extensive world travel experience. Our specialized knowledge provides us insight, firsthand experience and insider connections you can’t find elsewhere.</div>
            </div>
            <div className="flex flex-col gap-4 flex-1 items-center">
              <img alt="Thank you" src="https://www.travelleaders.com/images/thankyoucirc-2.jpg" className="rounded-full w-64 border-4 border-gray-300" />
              <div className="cursor-pointer text-xl md:text-2xl font-semibold text-blue-dark" onClick={() => history.push('/about_us')}>A PASSION FOR TRAVEL</div>
              <div className="text-base md:text-lg">From all-inclusive resorts to cruises, tours and more, we are constantly scouring the globe to find the best travel experiences, hot deals and travel tips.</div>
            </div>
            <div className="flex flex-col gap-4 flex-1 items-center">
              <img alt="Thank you" src="https://www.travelleaders.com/images/thankyoucirc-3.jpg" className="rounded-full w-64 border-4 border-gray-300" />
              <div className="cursor-pointer text-xl md:text-2xl font-semibold text-blue-dark" onClick={() => history.push('/about_us')}>AWARD-WINNING SERVICE</div>
              <div className="text-base md:text-lg">Vacation.com's parent company has been honored with multiple prestigious Travel Industry Awards for Excellence since 2009 - more than any other travel agency company in North America.</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default ThankYou;
