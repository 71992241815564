/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonBlue from './elements/ButtonBlue';

function PhoneForm({ agent, phone, callBack }) {
  return (
    <div className="p-4 flex flex-col gap-6 max-w-5xl">
      <div className="flex flex-col justify-center items-center gap-2">
        <div className="text-sm md:text-lg font-semibold text-center md:mx-60">
          You can call me at the number below. However, the easiest way to connect is with a message using the Email Me form.
          Send me a message and I'll contact you as soon as possible.

        </div>

        <div className="mt-10 w-full md:px-80">
          <ButtonBlue
            type="button"
            title="EMAIL ME"
            onClick={() => callBack()}
          />
        </div>
        <div className="text-sm md:text-base text-center mt-10">
          {`${agent.firstName} ${agent.lastName}`}
          <br />
        </div>
        <div className="text-xl md:text-2xl font-semibold text-gray-800">
          <a
            href={`tel:${phone}`}
            target="_blank"
            rel="noreferrer"
          >
            {`${phone}`}

          </a>

        </div>

      </div>

    </div>
  );
}

PhoneForm.propTypes = {
  agent: PropTypes.any.isRequired,
  phone: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,

};
export default PhoneForm;
