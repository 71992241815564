/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React from 'react';
import BreadCrumb from '../components/BreadCrumb';

function AboutUs() {
  const links = [{
    label: 'Home',
    url: '/',
  },
  {
    label: 'About us',
  },
  ];
  return (
    <div className="bg-white min-h-screen" role="main">

      <div className="flex flex-col md:max-w-5xl mx-auto  text-lg pt-20 pb-10  mt-10 px-4 md:px-0">
        <BreadCrumb links={links} />
        <div className="flex flex-col gap-10">
          <div>
            <h1 className="font-semibold text-xl md:text-3xl ">ABOUT VACATION</h1>
            <div className="flex flex-col gap-10 text-sm md:text-lg">
              <div>Vacation is a premier travel planning service that combines the valuable services of travel specialists with inspirational content to help you customize a travel experience that truly exceeds your expectations.</div>
              <div>Our Vacation Expert Agents assist more than 50,000 travelers every year, covering the full spectrum of travel opportunities: ocean and river cruising, all-inclusive resorts and luxury hotels, global tours and adventurous safaris, international flights and private jets, sporting events and more. They are ready and extremely capable of helping you design an unforgettable, safe, perfectly-planned trip filled with unique moments and customized experiences.</div>
              <div>Our goal is to match you to the Expert Agent best suited to your needs. Once matched, the journeys you will plan together will become your most cherished travel memories ever.</div>
            </div>
          </div>

          <div>
            <h1 className="font-semibold text-xl md:text-3xl">VACATION IS POWERED BY INTERNOVA TRAVEL GROUP</h1>
            <div className="flex flex-col gap-10 text-sm md:text-lg">
              <div>As North America’s largest travel agency company, Internova Travel Group delivers a high-touch, personal level of travel expertise through our more than 7,000 company-owned, franchised and affiliated travel agencies throughout the United States, Canada, the United Kingdom, Ireland and Australia. Our team of leaders – from our Expert Agents to our executives –– are dedicated to delivering the best travel experience. It’s our heart and soul. It’s all we do. And we do it all for you.</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
