/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { SlideDown } from 'react-slidedown';
import API from '../../services/API';

import 'react-slidedown/lib/slidedown.css';
import { label } from '../../Utils';
import { IoChevronDown, IoPlaySharp, IoChevronForward } from 'react-icons/io5';

function Destination({
  destination, city, interest, state, language, metroRegion, zip,
  supplier,
  callback,
  trigger,
}) {
  const type = 'FacetedDestinations';
  const [showFull, setShowFull] = useState(false);
  const [data, setData] = useState([]);
  const [itemToShow, setItemToShow] = useState(5);
  const [showDetail, setShowDetail] = useState(true);
  const [showArea] = useState(true);

  const fetch = () => {
    API.fetchFaceted({
      destination: destination || '',
      city: city || '',
      interest: interest || '',
      state: state || '',
      language: language || '',
      metroRegion: metroRegion || '',
      zip: zip || '',
      supplier: supplier || '',
      type,
    }).then((response) => {
      if (!response || response.error) { setData([]); } else { setData(response); }
    });
  };

  const getName = (dest) => {
    switch (dest.locationType) {
      case 'Reg':
        return dest.region;
      case 'Are':
        return dest.area;
      case 'Locale':
        return dest.locale;
      case 'Cit':
        return dest.locale;
      default:
        return '';
    }
  };

  const getDestinationFullName = (dest) => {
    switch (dest.locationType) {
      case 'Reg':
        return dest.region;
      case 'Are':
        return `${dest.area} | ${dest.region}`;
      case 'Locale':
        return `${dest.locale} | ${dest.area} | ${dest.region}`;
      case 'Cit':
        return `${dest.locale} | ${dest.area} | ${dest.region}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!showArea) {
      setShowDetail(false);
    }
  }, [showArea]);

  useEffect(() => {
    fetch();
  }, [destination, interest, state, language, supplier, metroRegion, zip]);
  useEffect(() => {
    trigger();
  }, [destination]);

  return (
    <>
      {(data.length > 0 || destination) && (
      <div className="py-2">
        <div>{label('DESTINATIONS')}</div>
        <div className="text-4xl flex flex-col">
          <div className="flex flex-col items-start ">
            <SlideDown className="my-dropdown-slidedown">
              <div className="flex flex-col items-start ">
                <div
                  onClick={() => (destination ? callback(0) : setItemToShow(itemToShow === 5 ? 0 : 5))}
                  className="text-xs mb-1 cursor-pointer text-white flex flex-row items-center gap-1"
                >
                  All Regions
                  {' '}
                  {itemToShow === 0 && <IoChevronForward className="text-right" /> }
                  {itemToShow === 5 && <IoChevronDown className="text-right" /> }
                </div>

                {destination && data.length > 0
                && (
                <>
                  {data[0].locationType === 'Cit' && (
                  <>
                    <div
                      className="text-xs mb-1  text-white flex flex-row gap-1 items-center cursor-pointer"
                      onClick={() => callback(data[0].region)}
                    >
                      {data[0].region}
                      <IoChevronDown className="text-right" />
                    </div>
                    <div
                      className="text-xs mb-1  text-white flex flex-row gap-1 items-center ml-1 cursor-pointer"
                      onClick={() => callback(`${data[0].area} | ${data[0].region}`)}
                    >
                      {data[0].area}

                      <IoChevronDown className="text-right" />
                    </div>
                  </>
                  )}
                  {data[0].locationType === 'Are' && (
                  <>
                    <div
                      className="text-xs mb-1  text-white flex flex-row gap-1 items-center cursor-pointer"
                      onClick={() => setShowDetail(!showDetail)}
                    >
                      {data[0].region}
                      {!showDetail && <IoChevronForward className="text-right" /> }
                      {showDetail && <IoChevronDown className="text-right" /> }
                    </div>

                  </>
                  )}
                  {data[0].locationType === 'Locale' && (
                  <>
                    <div
                      className="text-xs mb-1  text-white flex flex-row gap-1 items-center cursor-pointer"
                      onClick={() => callback(data[0].region)}
                    >
                      {data[0].region}
                      <IoChevronDown className="text-right" />
                    </div>

                    <div
                      className="text-xs mb-1  text-white flex flex-row gap-1 items-center cursor-pointer"
                      onClick={() => callback(`${data[0].area} | ${data[0].region}`)}
                    >
                      {data[0].area}
                      <IoChevronDown className="text-right" />
                    </div>

                  </>
                  )}

                </>
                )}
                {destination && data.length === 0
                && (
                <>
                  <div
                    className="text-xs mb-1  text-white"
                  >
                    {destination}
                  </div>

                </>
                )}

                {showDetail && (
                <div className={[destination ? 'pl-4' : '']}>
                  {data.slice(0, showFull ? data.length : itemToShow).map((x, index) => (
                    <div
                      onClick={() => callback(getDestinationFullName(x))}
                      className="text-xs mb-1 cursor-pointer text-white"
                      key={`${index}-search-dest`}
                    >
                      {getName(x)}
                    </div>
                  ))}
                </div>
                )}
              </div>
            </SlideDown>
            {data.length > itemToShow && itemToShow !== 0 && showDetail
         && (
         <div className="text-xs mb-1 cursor-pointer font-semibold text-white mt-2 self-end md:self-start" onClick={() => setShowFull(!showFull)}>
           {!showFull ? (
             <div className="flex flex-row gap-1">
               <IoPlaySharp />
               More Destinations
               {`(${data.length - itemToShow})`}
             </div>
           ) : <div>Close</div>}
         </div>
         )}
          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default Destination;
