/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import { IoPlaySharp } from 'react-icons/io5';
import API from '../../services/API';
import 'react-slidedown/lib/slidedown.css';
import { label } from '../../Utils';

function Language({
  destination, city, interest, state, language, metroRegion, zip,
  supplier,
  callback,
  trigger,
}) {
  const type = 'FacetedLanguages';
  const [data, setData] = useState([]);
  const [showFull, setShowFull] = useState(false);

  const fetch = (agent) => {
    API.fetchFaceted({
      destination: destination || '',
      city: city || '',
      interest: interest || '',
      state: state || '',
      language: language || '',
      metroRegion: metroRegion || '',
      zip: zip || '',
      supplier: supplier || '',
      type,
    }).then((response) => {
      if (!response || response.error) { setData([]); } else setData(response);
    });
  };

  useEffect(() => {
    fetch();
  }, [destination, interest, state, language, supplier, metroRegion, zip]);

  useEffect(() => {
    if (language) { trigger(); }
  }, [language]);

  return (
    <>
      {data.length > 0 && (
      <div className="py-2">

        <div>{label('Agent language')}</div>

        <div className="text-4xl flex flex-col">
          <div className="flex flex-col items-start ">
            <SlideDown className="my-dropdown-slidedown">
              <div className="flex flex-col items-start ">
                {data.slice(0, showFull ? data.length : 5).map((x) => (
                  <div
                    key={x.language}
                    onClick={() => callback(x.language)}
                    className={['text-xs mb-1 cursor-pointer ', x.language === language ? 'text-white' : 'text-white'].join(' ')}
                  >
                    {x.language}
                  </div>
                ))}
              </div>
            </SlideDown>
            {data.length > 5
     && (
     <div className="text-xs mb-1 cursor-pointer font-semibold text-white mt-2  self-end md:self-start" onClick={() => setShowFull(!showFull)}>
       {!showFull ? (
         <div className="flex flex-row gap-1">
           <IoPlaySharp />
           More Languages
           {`(${data.length - 5})`}
         </div>
       ) : <div>Close</div>}
     </div>
     )}
          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default Language;
