/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import sanitizeHtml from 'sanitize-html-react';
import StarRatings from 'react-star-ratings';
import Slider from 'react-slick';
import API from '../services/API';
import { upCurve, downCurveRight } from '../Utils';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,

    // centerMode: true,
    centerPadding: '40px',
};

const sanitizeReview = (content) => {
    const contentString = content.toString();

    const sanitizedContent = contentString.replace(/\[object Object\],/g, ' ');

    return sanitizeHtml(sanitizedContent, {
        allowedTags: [],
        allowedAttributes: {},
    });
};

function TopReviews({ title, inverted }) {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        API.fetchTopAgentsByReview().then((result) => {
            if (result.responseStatus === 1) { setReviews(result.data.slice(0, 3)); }
        });
    }, []);
    const renderReview = (review) => (
        <a key={review.reviewId} href={`/agent/${review.agentId}?s=review`} className="bg-white text-black -ml-4 flex flex-col gap-2 px-8 py-16 rounded-tr-3xl shadow-lg border border-gray-100 flex-1" tabIndex="-1">
            <div className="text-lg font-semibold uppercase">{sanitizeReview(review.reviewTitle)}</div>
            <div>
                <StarRatings
                    rating={review.rating}
                    starRatedColor="#33C590"
                    numberOfStars={5}
                    name="rating"
                    starDimension="18px"
                    starSpacing="2px"
                />

            </div>
            <div className="mt-6">
                <span className="font-semibold">
                    Reviewed by
                    {' '}
                    <span dangerouslySetInnerHTML={{ __html: sanitizeReview(review.customerNickname) }} />
                    {' '}
                    -
                </span>
                Traveled to
                {' '}
                <span dangerouslySetInnerHTML={{ __html: sanitizeReview(review.customerLocation) }} />
            </div>
            <div>
                <p className="break-all text-sm" dangerouslySetInnerHTML={{ __html: review.review.length > 150 ? `${review.review.slice(0, 150)}...` : review.review }} />
            </div>
            <div>
                <span className="font-semibold">Agent Name: </span>
                <span className="text-blue-dark font-semibold cursor-pointer">{review.agentName}</span>
            </div>
        </a>
    );
    return (
        <div className={[inverted ? 'bg-blue-dark text-white' : 'bg-white text-blue-dark', 'md:p-16 px-4 py-16 pb-0 relative overflow-x-hidden'].join(' ')}>
            {!inverted && <div className="absolute right-0 -mt-10 -mr-40 w-1/3 hidden-tw md:block">{upCurve}</div>}
            <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
                <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left">
                    {title}
                </div>
                <div className={[inverted ? 'bg-white' : 'bg-blue-dark', 'h-1.5 w-24 md:w-14 md:ml-0'].join(' ')} />
            </div>
            <div className="hidden md:flex flex-col md:flex-row justify-center  md:py-20 py-8 gap-10">
                {reviews.map((review) => (
                    renderReview(review)
                ))}
            </div>
            <div className="block md:hidden -ml-4 overflow-x-hidden">
                <Slider {...settings}>
                    {reviews.map((review) => (
                        <div className="pr-10" key={review.reviewId}>
                            {renderReview(review)}
                        </div>
                    ))}
                </Slider>
            </div>
            <div className={[inverted ? 'opacity-20 -ml-48' : '-ml-20', ' left-0   w-1/3 hidden-tw md:block'].join(' ')}>{downCurveRight}</div>
        </div>
    );
}

export default TopReviews;
