/* eslint-disable react/no-unescaped-entities */
import React, {
    useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import StarRatings from 'react-star-ratings';
import {
    CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE, downCurve, IMAGE_URL, randomIntFromInterval,
} from '../Utils';
import API from '../services/API';

import Loading from '../components/elements/Loading';
import AgentLabelBlog from '../components/AgentLabelBlog';
import BreadCrumb from '../components/BreadCrumb';

function Blog() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [mainAgent, setMainAgent] = useState();
    const [data, setData] = useState();
    const [links, setLinks] = useState([]);
    const agentRef = useRef(null);
    const executeScroll = (ref, block = 'start') => {
        ref.current.scrollIntoView({ behavior: 'smooth', block });
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const [blogAttribute, setBlogAttribute] = useState('');
    const [category, setCategory] = useState(query.get('category') || null);
    const [preview, setPreview] = useState(query.get('preview') || 'false');
    const getBlog = () => {
        setLoading(true);
        API.getBlog({ storyTitle: '', storyId: id, preview }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (data) {
            const base = [{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Travel Information',
                url: '/travel_information',
            }];
            if (category) {
                let text = `${category} Travel Ideas and Tips`;
                if (category.toLowerCase() === 'travel tips') {
                    text = 'Travel tips and Ideas';
                }

                base.push({
                    label: text,
                    url: `/travel_information_category?category=${category}`,
                });
            }
            base.push({
                label: `${data.title}`,
            });

            setLinks(base);
        }
    }, [category, data]);

    const getMatchedAgents = async () => {
        setLoading(true);
        API.agentMatchToInterest({
            id: 0,
            destination: data.destinations.map((x) => x.destinationId).join(','),
            interest: data.interests.map((x) => x.interestId).join(','),
            supplier: data.suppliers.map((x) => x.supplierId).join(','),
        }).then(async (result) => {
            if (result.responseStatus === 1 && result.data.length > 0) {
                const index = randomIntFromInterval(0, result.data.length - 1);

                await API.agentBio({
                    agentId: result.data[index].agentId,

                }).then((agentBioResponse) => {
                    if (agentBioResponse.responseStatus === 1) {
                        const currentAgent = agentBioResponse.data;

                        setMainAgent({
                            ...currentAgent,
                            phone: currentAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE)
                                ? currentAgent.agentConnections.find((x) => x.contactMethod === CONTACT_METHOD_PHONE).contactValue : '',
                            email: currentAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL)
                                ? currentAgent.agentConnections.find((x) => x.contactMethod === CONTACT_METHOD_EMAIL).contactValue : '',
                            rating: currentAgent.agentRating.rating,
                            totalReviews: currentAgent.agentRating.totalReviews,
                            recommended: currentAgent.agentRating.recommended,
                            agentReviews: currentAgent.agentReviews.slice(0, 4),
                        });
                    } else { toast(result.error.message); }
                    setLoading(false);
                });
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (data) {
            if (data.spotlightAgents && data.spotlightAgents.length > 0) {
                const index = randomIntFromInterval(0, data.spotlightAgents.length - 1);
                setMainAgent(data.spotlightAgents[index]);
            } else {
                getMatchedAgents();
            }
        }
    }, [data]);

    useEffect(() => {
        if (mainAgent && data) {
            if (data.destinations.length > 0) {
                setBlogAttribute(data.destinations[0].destination1);
            } else if (data.interests.length > 0) {
                setBlogAttribute(data.interests[0].interest1);
            } else if (data.suppliers.length > 0) {
                setBlogAttribute(data.suppliers[0].SupplierName);
            }
        }
    }, [mainAgent, data]);

    useEffect(() => {
        getBlog();
        window.scrollTo(0, 0);
    }, []);

    const agent = () => (
        <div className="flex flex-col gap-2 w-full ">
            <div className="flex flex-col gap-4 justify-center">
                <h2 className="text-xl md:text-4xl text-blue-dark font-semibold leading-tight px-10 md:px-0">
                    Connect with
                    {' '}
                    {mainAgent.firstName}
                    {' '}
                    to talk about your dream
                    {' '}
                    {blogAttribute || ''}
                    {' '}
                    {!blogAttribute.includes('Vacations') ? 'Vacation' : ''}

                </h2>
                <div className="h-1.5 bg-blue-dark w-28 hidden-tw md:block" />
            </div>
            <div className="flex my-8">

                <AgentLabelBlog agent={mainAgent} blog={data} blogAttribute={blogAttribute} />
            </div>

        </div>
    );

    return (

        <>
            <div role="main">
                <ToastContainer />

                {data
                    && (
                        <>
                            <Helmet>
                                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
                                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
                                <link rel="preconnect" href="https://fonts.googleapis.com" />
                                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                                <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
                                <title>
                                    {data.title}
                                    {' '}
                                    | Vacation.com
                                </title>
                                <meta name="title" content={`${data.title}`} />
                                <meta
                                    name="description"
                                    content={`${data.shortDescription.substring(0, 165)}`}
                                />
                            </Helmet>
                            <div className=" pt-12 w-full relative">
                                <div className="ml-2 mt-4 absolute z-50">
                                    <BreadCrumb links={links} inverted />
                                </div>
                                <img
                                    src={`${IMAGE_URL()}${data.images.find((x) => x.fileType === 'HeroImage').fileName}`}
                                    className="w-full h-screen  md:h-map object-cover"
                                    alt="Blog"
                                />
                                <div className="absolute bg-gray-900 h-full w-full top-0 opacity-40" />
                                <div className="absolute w-full py-2.5 bottom-0 inset-x-0 bg-blue-dark
           text-white text-xs text-center leading-4"
                                >
                                    by
                                    {' '}
                                    <u>
                                        {data.author}

                                    </u>
                                    {' '}
                                    -
                                    {' '}
                                    {moment(data.datePublished).format('MMM DD,YYYY')}

                                </div>
                                {mainAgent && (
                                    <div
                                        className="bottom-1 mb-8 md:mb-0 md:bottom-40 absolute  z-40 ml-4 md:ml-0  md:max-w-md mx-auto bg-white
                   right-0 rounded-tl-lg shadow-lg cursor-pointer  w-full"
                                        onClick={() => executeScroll(agentRef)}
                                    >
                                        <div className="flex space-x-4 items-center ">

                                            <div className=" rounded-tl-lg">
                                                <img
                                                    src={mainAgent.agentPhotoFileName}
                                                    className=" w-36 h-36 rounded-tl-lg object-cover"
                                                    alt="Agent"
                                                />
                                                {/* <div className="absolute bg-gray-900 w-16 h-16  opacity-25 top-0 left-0 right-0 bottom-0 m-4 rounded-full" /> */}
                                            </div>
                                            <div className="bg-white flex flex-col gap-2">
                                                <div className="flex-1 py-1">
                                                    <div className="text-black rounded text-lg font-bold">
                                                        {mainAgent.firstName}
                                                        {' '}
                                                        {mainAgent.lastName}
                                                    </div>
                                                    <div className="text-black rounded text-sm font-thin">
                                                        {blogAttribute}
                                                        {' '}
                                                        Travel Expert
                                                    </div>
                                                    <div>
                                                        {mainAgent.totalReviews > 0
                                                            && (
                                                                <StarRatings
                                                                    rating={mainAgent.rating}
                                                                    starRatedColor="#33C590"
                                                                    numberOfStars={5}
                                                                    name="rating"
                                                                    starDimension="12px"
                                                                    starSpacing="2px"
                                                                />
                                                            )}
                                                    </div>

                                                </div>
                                                <div className="flex ">
                                                    <div className="animate-bounce bg-green-light dark:bg-slate-800 p-2 md:w-6
                         md:h-6 w-8 h-8 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full
                         flex items-center justify-center"
                                                    >
                                                        <svg className="md:w-6 md:h-6 w-4 h-4 text-white" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor" role="none">
                                                            <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="absolute bg-gray-900 opacity-25 top-0 left-0 right-0 bottom-0 " /> */}
                                    </div>
                                )}

                            </div>
                            <div className=" max-w-screen-2xl mx-auto bg-white">
                                <div className="min-h-screen pb-4">

                                    <div className="flex flex-col gap-10 items-center   pb-20">

                                        <div
                                            className="text-white text-center md:text-left  absolute font-semibold
          top-56 flex justify-center flex-col"
                                            style={{ textShadow: '1px 0px 2px #000000' }}
                                        >
                                            <h1 className="text-2xl md:text-5xl uppercase pl-10">
                                                {data.title}
                                            </h1>

                                        </div>

                                        <div className="flex flex-col gap-10 max-w-screen-2xl ">
                                            <div className="flex flex-col md:flex-row gap-2 justify-center
                                                            bg-gray-verylight md:py-14 md:px-20 py-10 px-4"
                                            >
                                                <div className="flex-1 text-sm md:text-md font-normal">
                                                    <div className="w-full">
                                                        <div className="">
                                                            {data.shortDescription}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="md:px-0 relative overflow-x-hidden">
                                                <div className="absolute right-0 w-1/3  -mr-32 top-0 hidden-tw md:block">
                                                    {downCurve}
                                                </div>
                                                <div className="flex justify-center my-20">
                                                    <h2 className="text-2xl md:text-5xl text-blue-dark font-semibold">Find Your Perfect Vacation</h2>
                                                </div>
                                                <div className="text-gray-700 text-sm md:text-xl py-2 max-w-screen-2xl mx-auto px-4 md:px-10">
                                                    <div className="divBlogStory" dangerouslySetInnerHTML={{ __html: data.story }} />
                                                </div>
                                            </div>
                                            {mainAgent && (
                                                <div ref={agentRef} className="md:px-20">
                                                    {agent()}
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    )}

                {loading && <Loading />}
            </div>

        </>
    );
}

export default Blog;
