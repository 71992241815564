/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import API from '../services/API';
import { getMoreReferralData, removeUTMCookies } from '../Utils';
import ButtonBlue from './elements/ButtonBlue';


function EmailForm({ agent }) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const history = useHistory();

    const {
        register, handleSubmit, formState: { errors },
        setValue,
    } = useForm();

    const onSubmit = async (data) => {
        if (isCaptchaVerified) {
            setIsProcessing(true);
            try {
                const response = await API.sendEmailToAgent(data);
                removeUTMCookies();
                history.push({
                    pathname: '/thankYou/',
                    search: `?check=${response.data.toLowerCase()}`,
                });
            } catch (error) {
                //console.error('Error sending email:', error);
            } finally {
                setIsProcessing(false);
            }
        }
    };

    let referralUrl = window.location.href;
    if (!referralUrl.toLowerCase().includes('utm_')) {
        referralUrl = window.location.href + getMoreReferralData();
    }

    useEffect(() => {
        setValue('agentId', agent.agentId.toString());
        setValue('URL', referralUrl);
    }, []);

    return (
        <div className="p-4 flex flex-col gap-6 max-w-2xl">

            <div className="text-sm md:text-lg font-semibold">
                Sending a message to
                {' '}
                {`${agent.firstName} ${agent.lastName}`}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col">
                    <input className="hidden" {...register('agentId', { required: true })} />
                    <div>
                        <label className="text-sm md:text-base">First Name</label>
                    </div>
                    <div>
                        <input placeholder="First Name" className="border border-gray-400  py-2 px-4 w-full" {...register('firstName', { required: true })} />
                    </div>
                    <div>
                        {errors.firstName && <span className="text-red-500 md:text-sm text-xs">First name is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label className="text-sm md:text-base">Last Name</label>
                    </div>
                    <div>
                        <input placeholder="Last Name" className="border border-gray-400  py-2 px-4 w-full" {...register('lastName', { required: true })} />
                    </div>
                    <div>
                        {errors.lastName && <span className="text-red-500 md:text-sm text-xs">Last name is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label className="text-sm md:text-base">Email</label>
                    </div>
                    <div>
                        <input placeholder="Email" type="email" className="border border-gray-400  py-2 px-4 w-full" {...register('email', { required: true })} />
                    </div>
                    <div>
                        {errors.email && <span className="text-red-500 md:text-sm text-xs">Email is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label className="text-sm md:text-base">Phone Number</label>
                    </div>
                    <div>
                        <input placeholder="Phone number" className="border border-gray-400  py-2 px-4 w-full" {...register('phone')} />
                    </div>
                    {errors.phone && <span className="text-red-500 md:text-sm text-xs">Phone number is required</span>}
                </div>

                <div className="flex flex-col">
                    <div>
                        <label className="text-sm md:text-base">Your questions / comments to this agent</label>
                    </div>
                    <div>
                        <textarea placeholder="Comment" className="border border-gray-400  py-2 px-4 w-full" {...register('comment')} />
                        {errors.comment && <span className="text-red-500 md:text-sm text-xs">Question/Comment is required</span>}

                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>
                            <input type="checkbox" className="mr-1" {...register('newsLetter')} />
                            Yes, I would like to sign up to receive exclusive travel specials, exciting destination information and the latest insights from our Travel Leaders brands. You can unsubscribe at any time. You can find out about your rights and how we use your information in our&nbsp;
                            <a className="text-blue-dark cursor-pointer hover:text-green-light" href="https://internova.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy.</a>
                        </label>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div style={{ maxWidth: '300px' }}>
                        <ReCAPTCHA
                            sitekey="6LeqBQ4pAAAAAL2W_bxwDMn5ftkAFTNnpVxvH5Wi"
                            onChange={(value) => setIsCaptchaVerified(!!value)}
                            hl="en"
                        />
                    </div>
                </div>
                <div>
                    <ButtonBlue
                        disabled={isProcessing || !isCaptchaVerified}
                        type="submit"
                        title={
                            isProcessing
                                ? `Sending email to ${agent.firstName} ${agent.lastName} ....`
                                : `Email ${agent.firstName} ${agent.lastName}`
                        }
                    />
                </div>
            </form>
        </div>
    );
}

EmailForm.propTypes = {
    agent: PropTypes.any.isRequired,
};

export default EmailForm;
