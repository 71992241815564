import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import useAppStore from '../contexts/useAppStore';
import Button from './elements/Button';
import { customSelectStyles, filterConfig } from '../Utils';

function OneFieldSearchBox() {
    const {
        getSpecialities,
        getDestinations,

    } = useAppStore();

    const [selected, setSelected] = useState();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory();

    const onSearch = () => {
        const filters = [];

        if (selected) {
            if (selected.type === 'Specialities') {
                filters.push(`interest=${encodeURIComponent(selected.value)}`);
            }
            if (selected.type === 'Destinations') {
                filters.push(`destination=${selected.value}&destinationLabel=${selected.label}`);
            }
        }
        history.push(`/agents?${filters.join('&')}`);
    };

    const groupedSpecialities = () => {
        const items = [];
        items.push({
            label: 'Travel Specialities',
            options: [...getSpecialities().reduce((map, obj) => map.set(obj.interest, obj), new Map()).values()].map((x) => ({
                value: x.interest,
                label: x.interest,
                type: 'Specialities',
            })),
        });
        items.push({
            label: 'Destinations',
            options: getDestinations.map((x) => ({
                value: x.destinationId,
                label: x.destination,
                type: 'Destinations',
            })),
        });
        return items;
    };

    return (
        <div
            className="md:shadow-xl rounded px-6 py-6 z-40  flex flex-col md:flex-row items-center md:items-baseline gap-6 md:gap-1 md:pt-6 pt-12"
            aria-labelledby="search-box-label" role="search">
            <div className="flex-1 flex">
                <span id="search-box-label" className="text-white text-lg md:text-2xl font-bold" role="heading" aria-level="1">Where Would You Like To Go?</span>
            </div>
            <div className="flex flex-col md:flex-row items-baseline gap-4 md:gap-10 flex-1 justifiy-center w-full">
                <div className="flex-1 min-w-full md:min-w-max">
                    <Select
                        id="specialities"
                        styles={customSelectStyles}
                        className="w-full rounded-full text-sm"
                        options={groupedSpecialities()}
                        components={{ DropdownIndicator: () => null }}
                        onChange={(val) => {
                            setSelected(val);
                            setIsMenuOpen(false);
                        }}
                        onInputChange={(val) => {
                            if (val !== '') {
                                setIsMenuOpen(true);
                            } else setIsMenuOpen(false);
                        }}
                        placeholder="ex: Italy | Hawaii | Cruise | Honeymoon"
                        value={selected}
                        filterOption={createFilter(filterConfig)}
                        menuIsOpen={isMenuOpen}
                        noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                        aria-label="Search for destinations or specialities"
                    />
                </div>
                <div className="flex-1 w-full">
                    <Button title="GET STARTED" onClick={() => onSearch()} />
                </div>
            </div>
        </div>
    );
}

export default OneFieldSearchBox;
