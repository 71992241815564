/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Slider from 'react-slick';
import { slidesettings, wrapperClass } from '../Utils';

function AgentTestimonials({ agent }) {
  const {
    agentTestimonials,
  } = agent;
  const noSlides = 1;
  return (
    <div
      className={[wrapperClass, 'md:px-20'].join(' ')}
    >

      <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
        <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left text-blue-dark">
          Testimonials
        </div>
        <div className="bg-blue-dark h-1 md:h-1.5 w-24 md:w-14 md:ml-0" />
      </div>
      <div className="md:flex flex-col gap-16 hidden-tw">
        {agentTestimonials.map((x) => (
          <div className="flex flex-col gap-3 " key={x.testimonialTitle}>
            <div className="font-bold text-sm md:text-2xl">{x.testimonialTitle}</div>
            <div className="font-light text-lg">{x.testimonialText}</div>
            <div className="font-bold text-sm md:text-lg">{x.testimonialCredit}</div>
          </div>
        ))}
      </div>
      <div className="block md:hidden">
        <Slider {...slidesettings(agentTestimonials.length < noSlides ? agentTestimonials.length : noSlides, true)}>
          {agentTestimonials.map((x) => (
            <div className="pr-2 " key={x.testimonialTitle}>
              <div className="bg-white text-black flex flex-col gap-2 md:px-8 md:py-16 p-4 rounded-tr-3xl shadow-lg  border border-gray-100 flex-1 min-h-full" style={{ minHeight: '450px' }}>
                <div
                  className="flex flex-col px-2 gap-4  cursor-pointer text-sm"

                >

                  <div className="font-bold text-blue-dark">{x.testimonialTitle}</div>
                  <div className="font-light text-xs">{x.testimonialText}</div>
                  <div className="font-bold text-xs">{x.testimonialCredit}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default AgentTestimonials;
