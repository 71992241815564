/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, {
    useEffect, useState, useRef,
} from 'react';
import {
    IoChevronBack,
} from 'react-icons/io5';
import parser from 'html-react-parser';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import sanitizeHtml from 'sanitize-html-react';
import { getStateName } from '../Utils';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import AgentLabel from '../components/AgentLabel';
import BreadCrumb from '../components/BreadCrumb';

function Story() {
    const { id, bioId, storyId } = useParams();
    const [loading, setLoading] = useState(false);
    const [agent, setAgent] = useState();
    const history = useHistory();
    const reviewsRef = useRef(null);
    const [story, setStory] = useState();
    const [links, setLinks] = useState([]);
    const [bio, setBio] = useState();

    const executeScroll = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    getTravelStory();
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
        window.scrollTo(0, 0);
    }, []);

    const getTravelStory = () => {
        setLoading(true);
        API.experimentalPost({
            agentId: id,
            bioId: 0,
            blogId: storyId,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setStory(result.data[0]);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAdditionalBio = () => {
        setLoading(true);
        API.additionalBio({
            agentId: id,
            bioId,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setBio(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (agent && story) {
            const base = [{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?state=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
                url: `/agent/${id}`,
            }];

            if (bioId) {
                base.push(
                    {
                        label: `${bio.displayTitle}`,
                        url: `/agent/${id}/bio/${bioId}`,
                    },
                );
            }
            setLinks([...base, {
                label: `${story.postTitle}`,
            }]);
        }
    }, [agent, story]);

    useEffect(() => {
        if (bioId) {
            getAdditionalBio();
        }
    }, [bioId]);

    const agentBio = () => (
        <div className="flex flex-col gap-10 md:px-20" role="main">

            <div className="flex flex-col gap-4 px-2 ">
                <div className=" md:-ml-6 mt-4 md:mt-0" style={{ zIndex: '1000' }}>
                    <BreadCrumb links={links} />
                </div>
                <div className="flex flex-col md:flex-row gap-4 md:gap-6 md:items-center  w-full flex-1">
                    <div className="absolute right-0 z-40 top-28 md:top-32 hidden-tw md:block">
                        <img
                            alt="Decoration vector 5"
                            src="/images/Vector 5.webp"
                            className="md:w-full -mr-40 md:mr-0"
                        />
                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="hidden-tw md:block">
                            <div
                                className="text-blue-dark hover:text-green-light text-sm mb-2 font-semibold flex flex-row items-center cursor-pointer gap-1"
                                onClick={() => history.push(`/agent/${id}`)}
                            >
                                <IoChevronBack className=" text-right" />
                                <div>
                                    {' '}
                                    Return to My Main profile
                                </div>
                            </div>
                            <div className="w-56 h-72 bg-blue-dark rounded-tr-3xl
             md:absolute -ml-6   z-10"
                            />

                            <img
                                alt={agent.firstName + ' ' + agent.lastName + ', ' + agent.city + ', ' + agent.state + ' Travel Agent'}
                                src={agent.agentPhotoFileName}
                                className="hidden-tw md:block md:w-80 md:mt-8 mx-auto  rounded-tr-3xl z-20 relative"
                            />
                        </div>
                        <div className="md:hidden w-full">
                            <AgentLabel agent={agent} reviewCallback={() => executeScroll(reviewsRef)} isAdditionalBio />
                        </div>
                    </div>
                    <div className="hidden-tw md:flex flex-col items-center md:items-start gap-2  w-full">
                        {/* <BreadCrumb links={links} /> */}
                        {story && (
                            <>
                                <AgentLabel agent={agent} reviewCallback={() => executeScroll(reviewsRef)} story={story.postText} />
                            </>
                        )}
                    </div>

                </div>

            </div>
            {story && (
                <div className="flex flex-col gap-2 p-6 md:p-8 bg-gray-verylight md:rounded-l-3xl md:-mr-20">

                    <div className="flex flex-col gap-2 px-0 md:px-0">
                        <div className="text-center md:text-left text-2xl md:text-4xl  font-medium
           text-blue-dark"
                        >
                            {story.postTitle}
                        </div>

                        <div className="w-full">
                            <div className="float-left mr-4">
                                <img
                                    alt={story.postTitle + ' ' + agent.city + ', ' + agent.state + ' Travel Agent'}
                                    src={story.travelImage}
                                    className="w-80 md:w-96 mx-auto rounded-lg"
                                />

                            </div>
                            <div className="text-gray-700 text-sm md:text-xl py-2">
                                {parser(sanitizeHtml(story.postText))}
                            </div>
                        </div>
                    </div>

                </div>
            )}

        </div>
    );

    return (
        <div className=" max-w-screen-2xl mx-auto bg-white">
            <ToastContainer />
            {agent && (
                <div className="h-full
       flex flex-col md:pt-24 pt-10 md:py-24 px-2 md:px-8 text-gray-900"
                >
                    {agentBio()}

                </div>
            )}
            {loading && <Loading />}
        </div>
    );
}

export default Story;
