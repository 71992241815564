import React from 'react';
import PropTypes from 'prop-types';

function Button({ onClick, title }) {
  return (
    <button type="button" className="bg-green-light rounded-full py-3 px-10 text-black text-sm w-full" onClick={onClick}>
      {title}
    </button>
  );
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Button;
