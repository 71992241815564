/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import useAppStore from '../contexts/useAppStore';
import Button from './elements/Button';
import SelectInput from './elements/SelectInput';
import { customSelectStyles, filterConfig, getStateName } from '../Utils';

function SearchBox() {
    const {
        getStates,
        getSpecialities,
        getDestinations,

    } = useAppStore();

    const [selectedDestination, setSelectedDestination] = useState('');
    const [selectedInterest, setSelectedInterest] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const history = useHistory();

    const label = (title) => <div className="uppercase font-bold ml-3 mb-1 text-sm hidden md:block">{title}</div>;

    const onSearch = () => {
        const filters = [];

        if (selectedState !== '') {
            filters.push(`state=${selectedState}`);
        }
        if (selectedInterest !== '') {
            filters.push(`interest=${encodeURIComponent(selectedInterest)}`);
        }
        if (selectedDestination !== '') {
            filters.push(`destination=${selectedDestination.value}&destinationLabel=${selectedDestination.label}`);
        }

        history.push(`/agents?${filters.join('&')}`);
    };

    const groupedSpecialities = () => {
        const items = [];
        getSpecialities().map((x) => {
            if (items.some((y) => y.name === x.category)) {
                items.find((y) => y.name === x.category).items.push({
                    value: x.interest,
                    label: x.interest,
                });
            } else {
                items.push({
                    type: 'group',
                    name: x.category,
                    items: [{
                        value: x.interest,
                        label: x.interest,
                    }],
                });
            }

            return true;
        });

        return items;
    };

    return (
        <div className="bg-transparent md:bg-white md:shadow-xl   z-40  rounded-lg md:-ml-2 ">
            <div className="py-2 text-lg md:pl-20 font-bold text-white md:text-black text-center md:text-left px-10 mb-16 md:mb-0 md:px-0">
                Search for an expert travel advisor by one or more of the following.
            </div>
            <div className="flex flex-col md:flex-row gap-8 justify-between md:bg-gray-200 bg-transparent rounded-b-lg px-6 py-3 md:pl-20">
                <div className="flex flex-col justify-center flex-1">
                    {label('AGENT LOCATION')}
                    <div>
                        <SelectInput
                            options={[{ label: 'Select a State/Province', value: '' }].concat(getStates().map((x) => ({
                                value: x.state,
                                label: getStateName(x.state),
                            })))}
                            placeholder="Select a state"
                            onSelected={(val) => setSelectedState(val)}
                            value={selectedState}
                        />
                    </div>
                </div>

                <div className="flex flex-col justify-center flex-1">
                    <div>
                        {label('DESTINATION EXPERTISE')}
                        <div>
                            <Select
                                aria-label="DESTINATION EXPERTISE"
                                styles={{
                                    ...customSelectStyles,
                                    menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                    }),
                                }}
                                className=" w-full rounded-full text-xs"
                                options={[{ label: 'Select a destination', value: '' }].concat(getDestinations.map((x) => ({
                                    value: x.destinationId,
                                    label: x.destination,
                                })))}
                                onChange={(val) => {
                                    setSelectedDestination(val);
                                    setIsMenuOpen(false);
                                }}
                                onInputChange={(val) => {
                                    if (val !== '') {
                                        setIsMenuOpen(true);
                                    } else setIsMenuOpen(false);
                                }}
                                placeholder="Destination"
                                // input={selectedDestination}
                                value={selectedDestination}
                                filterOption={createFilter(filterConfig)}
                                menuIsOpen={isMenuOpen}
                                noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                                components={{ DropdownIndicator: () => null }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center flex-1">
                    <div>
                        {label('TRAVEL SPECIALTY')}
                        <div>
                            <SelectInput
                                options={[{ label: 'Select a Travel Interest', value: '' }].concat(groupedSpecialities())}
                                placeholder="Select a travel interest"
                                onSelected={(val) => setSelectedInterest(val)}
                                value={selectedInterest}
                            />

                        </div>
                    </div>
                </div>
                <div className="flex-1 w-full self-end">

                    <Button title="GET STARTED" onClick={() => onSearch()} />

                </div>
                {/* <div className="flex flex-col justify-center">
          <div className="" />
          <div className="mt-5">
            <Button title="SEARCH" onClick={() => onSearch()} />

          </div>
        </div> */}
            </div>

        </div>
    );
}

export default SearchBox;
