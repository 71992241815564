/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import parser from 'html-react-parser';
import Slider from 'react-slick';
import {
  IoCaretForwardOutline, IoCaretDownOutline, IoGlobeOutline, IoCalendarOutline,
} from 'react-icons/io5';
import sanitizeHtml from 'sanitize-html-react';
import { useHistory } from 'react-router-dom';
import SlideDown from 'react-slidedown';
import SliderArrows from './elements/SliderArrows';
import {
  wrapperClass, slidesettings, downCurve, upCurve,
} from '../Utils';

function AgentItineraries({ agent, itineraries, bioId = '' }) {
  const history = useHistory();
  const slider = useRef(null);
  const limit = 3;
  const [openedList, setOpenedList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onItemClicked = (item) => {
    if (openedList.includes(item)) {
      setOpenedList([...openedList.filter((x) => x !== item)]);
    } else {
      openedList.push(item);
      setOpenedList([...openedList]);
    }
  };
  return (
    <div className={[wrapperClass, 'bg-white md:px-20 relative overflow-x-hidden'].join(' ')}>
      <div className="absolute right-0 w-1/3  -mr-32 top-0 hidden-tw md:block">
        {downCurve}
      </div>

      <div className="flex flex-row justify-center md:justify-between md:mt-10">
        <div className="flex flex-col gap-4 mb-8 items-center md:items-start md:bg-white
        bg-blue-dark w-full md:py-0 py-10
        -mx-6 flex-1"
        >
          <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center
           md:text-left text-white md:text-blue-dark"
          >
            My Itineraries
          </div>
          <div className="bg-white md:bg-blue-dark h-1 md:h-1.5 w-24 md:w-14 md:ml-0" />
        </div>
        {itineraries.length > limit && (
        <div className="hidden-tw md:flex ">
          <div>
            <SliderArrows
              onPrev={() => slider.current.slickNext()}
              onNext={() => slider.current.slickPrev()}
              hasNext={selectedIndex + limit < itineraries.length}
              hasPrev={selectedIndex !== 0}
              horizontal
            />
          </div>
        </div>
        )}
      </div>
      <div className="hidden-tw md:block -mr-20 z-10">
        <Slider {...slidesettings(itineraries.length < limit ? itineraries.length : limit, false)} ref={slider} afterChange={(index) => setSelectedIndex(index)}>
          {itineraries.map((x) => (
            <div className="max-w-xl   pr-2 md:pr-10" key={x.itineraryId}>
              <div
                className="flex flex-col gap-4  cursor-pointer
                rounded-tr-3xl  shadow-lg  border border-gray-100"
                onClick={() => (bioId !== ''
                  ? history.push(`/agent/${agent.agentId}/bio/${bioId}/itinerary/${x.itineraryId}`)
                  : history.push(`/agent/${agent.agentId}/itinerary/${x.itineraryId}`))}
              >
                <div className="rounded-tr-3xl h-80">
                    <img src={x.itineraryImage} className="w-full h-80 rounded-tr-3xl object-cover" alt={'Vacation Itinerary - ' + x.title} />
                </div>
                <div className="p-4 flex flex-col gap-3 h-80">
                  <div className="text-black font-bold text-lg uppercase px-2">{x.title}</div>
                  <div className="text-blue-dark font-bold text-sm  px-2 mt-4 flex flex-row items-center gap-1">
                    <span className="bg-blue-dark rounded-full p-1">
                      <IoGlobeOutline className="text-white" />
                    </span>
                    <span>COUNTRIES VISITED:</span>
                    <span className="text-black font-thin">
                      {x.visitedCountries}
                    </span>
                  </div>
                  <div className="text-blue-dark font-bold text-sm  px-2 flex flex-row gap-1 items-center">
                    <span className="bg-blue-dark rounded-full p-1">
                      <IoCalendarOutline className="text-white" />
                    </span>
                    <span>SUGGESTED DURATION:</span>
                    {x.itineraryDays.length && (
                    <span className="text-black font-thin">
                      {x.itineraryDays.length}
                      {' '}
                      Days
                    </span>
                    ) }
                  </div>
                  <div
                    className="text-xs text-gray-500 px-2"
                  >

                    { x.overview.length > 250 ? parser(`${sanitizeHtml(x.overview.slice(0, 250))}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)
                      : parser(`${sanitizeHtml(x.overview)}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className=" md:hidden">
        {
          itineraries.map((x) => (
            <div className="" key={x.itineraryId}>

              <div className="uppercase font-bold text-sm  mb-8 text-left tracking-widest flex flex-row items-center gap-1 text-black">
                <div onClick={() => onItemClicked(x.itineraryId)}>
                  {openedList.includes(x.itineraryId) ? <IoCaretDownOutline className="text-lg text-blue-dark" /> : <IoCaretForwardOutline className="text-lg text-blue-dark" />}
                </div>
                <div>
                  {' '}
                  {x.title}
                </div>

              </div>
              <SlideDown className="my-dropdown-slidedown">
                {openedList.includes(x.itineraryId)
                && (
                <>
                  <div
                    className="flex flex-col gap-3 px-4  cursor-pointer"
                    onClick={() => (bioId !== ''
                      ? history.push(`/agent/${agent.agentId}/bio/${bioId}/itinerary/${x.itineraryId}`)
                      : history.push(`/agent/${agent.agentId}/itinerary/${x.itineraryId}`))}
                  >
                    <div className="rounded-tr-3xl h-80">
                        <img src={x.itineraryImage} className="w-full h-80 rounded-tr-3xl object-cover" alt={'Vacation Itinerary - ' + x.title} />
                      {' '}
                    </div>
                    <div className="text-blue-dark font-bold text-xs  px-2 mt-4 flex flex-row items-center gap-1">
                      <span className="bg-blue-dark rounded-full p-1">
                        <IoGlobeOutline className="text-white" />
                      </span>
                      <span>COUNTRIES VISITED:</span>
                      <span className="text-black font-thin">
                        {x.visitedCountries}
                      </span>
                    </div>
                    <div className="text-blue-dark font-bold text-xs  px-2 flex flex-row gap-1 items-center">
                      <span className="bg-blue-dark rounded-full p-1">
                        <IoCalendarOutline className="text-white" />
                      </span>
                      <span>SUGGESTED DURATION:</span>
                      {x.itineraryDays.length && (
                      <span className="text-black font-thin">
                        {x.itineraryDays.length}
                        {' '}
                        Days
                      </span>
                      ) }
                    </div>

                    <div
                      className="text-xs text-gray-500 px-2"
                    >

                      { x.overview.length > 250 ? parser(`${sanitizeHtml(x.overview.slice(0, 250))}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)
                        : parser(`${sanitizeHtml(x.overview)}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)}
                    </div>
                    <div />
                  </div>
                </>
                )}
              </SlideDown>
            </div>
          ))
          }
      </div>
      <div className="absolute left-0 w-1/3  -ml-48 bottom-0 hidden-tw md:block z-0">{upCurve}</div>

    </div>
  );
}

export default AgentItineraries;
