/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';

function BreadCrumb({ links, inverted }) {
  const history = useHistory();

  return (
    <div className="flex flex-row flex-wrap md:flex-nowrap w-full my-2">
      {links.map((link, index) => (
        <div className="flex flex-row  items-center mb-1" key={link.label}>
          {index > 0 && (
          <div>
            <div
              className={['text-right text-xs md:text-sm mx-1.5',
                inverted ? 'text-white' : 'text-gray-700 '].join(' ')}
            >
              |
            </div>
          </div>
          ) }
          <div className={['text-xs md:text-base', index + 1 === links.length ? 'font-semibold' : 'cursor-pointer hover:text-green-light', inverted ? 'text-white' : 'text-gray-700 '].join(' ')} onClick={() => ((index + 1 === links.length) ? {} : history.push(link.url))}>
            {link.label}
          </div>
        </div>
      ))}
    </div>
  );
}

export default BreadCrumb;
