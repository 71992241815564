/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import { IoPlaySharp } from 'react-icons/io5';
import API from '../../services/API';
import 'react-slidedown/lib/slidedown.css';
import { label } from '../../Utils';

function Interest({
  destination, city, interest, state, language, metroRegion, zip,
  supplier,
  callback,
  trigger,
}) {
  const type = 'FacetedInterests';
  const [data, setData] = useState([]);
  const [grouped, setGrouped] = useState([]);
  const [showFull, setShowFull] = useState(false);

  const order = ['Most Popular',
    'Cruises',
    'Couples, Groups, & Family',
    'Sports & Outdoors',
    'Arts, Culture, & Entertainment',
    'Lodging & Transportation',
    'Corporate Travel',
    'Specialized Travel & Luxury'];

  const groupedSpecialities = () => {
    const items = [];
    data.map((x) => {
      if (items.some((y) => y.name === x.category)) {
        items.find((y) => y.name === x.category).items.push({
          value: x.interest,
          label: x.interest,
        });
      } else {
        items.push({
          type: 'group',
          name: x.category,
          order: order.includes(x.category) ? order.indexOf(x.category) : 1000,
          show: false,
          items: [{
            value: x.interest,
            label: x.interest,
          }],
        });
      }

      return true;
    });

    items.map((x) => {
      if (x.items.find((y) => y.label === interest)) {
        x.show = true;
      }
    });

    items.sort(compare);

    setGrouped(items);
  };

  const fetch = (agent) => {
    API.fetchFaceted({
      destination: destination || '',
      city: city || '',
      interest: interest || '',
      state: state || '',
      language: language || '',
      metroRegion: metroRegion || '',
      zip: zip || '',
      supplier: supplier || '',
      type,
    }).then((response) => {
      if (!response || response.error) { setData([]); } else setData(response);
    });
  };

  const compare = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    fetch();
  }, [destination, interest, state, language, supplier, metroRegion, zip]);

  useEffect(() => {
    if (interest) {
      grouped.map((x) => {
        if (x.items.find((y) => y.label === interest)) {
          x.show = true;
        }
      });
      setGrouped([...grouped]);
      trigger();
    }
  }, [interest]);

  useEffect(() => {
    groupedSpecialities();
  }, [data]);

  return (
    <>
      {data.length > 0 && (
      <div className="py-2">
        <div>{label('SPECIALTIES')}</div>

        <div className="text-4xl flex flex-col">
          <div className="flex flex-col items-start ">

            <div className="flex flex-col items-start w-full ">
              {grouped.slice(0, showFull ? grouped.length : 5).map((x) => (
                <div
                  key={x.name}
                  onClick={() => {
                    grouped.find((g) => g.name === x.name).show = !grouped.find((g) => g.name === x.name).show;
                    setGrouped([...grouped]);
                  }}
                  className="text-xs mb-1 cursor-pointer text-white font-semibold"
                >

                  {x.show ? '-' : '+'}
                  {' '}
                  {x.name}
                  <SlideDown className="my-dropdown-slidedown">
                    {x.show && (
                    <div className="flex flex-col items-start pl-4 mt-2 w-48 gap-1">

                      {x.items.map((y) => (
                        <div
                          key={y.label}
                          onClick={() => callback(y.label)}
                          className={['text-xs mb-1 cursor-pointer font-normal', y.label === interest ? 'text-white' : 'text-white'].join(' ')}

                        >
                          {y.label}
                        </div>
                      ))}
                    </div>
                    )}
                  </SlideDown>

                </div>
              ))}

              {grouped.length > 5
                && (
                <div className="text-xs mb-1 cursor-pointer font-semibold text-white mt-2  self-end md:self-start" onClick={() => setShowFull(!showFull)}>
                  {!showFull ? (
                    <div className="flex flex-row gap-1">
                      <IoPlaySharp />
                      More Specialty
                      {`(${grouped.length - 5})`}
                    </div>
                  ) : <div>Close</div>}
                </div>
                )}
            </div>

          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default Interest;
