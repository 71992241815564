import React from 'react';
import { useLocation } from 'react-router-dom';

const OptOut = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    return (
        <div className="mx-4 md:mx-20 py-20 text-center min-h-screen">
            <strong>
                <h2 className="tl-cyan uppercase text-4xl tracking-wide text-blue-dark py-20">We are sorry to see you go!</h2>
            </strong>
            <div>
                <h6 className="text-l my-2 font-semibold tracking-wide py-2">
                    {`You have successfully opted out with the email: ${email}`}
                </h6>
            </div>
        </div>
    );
};

export default OptOut;
