import React from 'react';
import PropTypes from 'prop-types';

function Input({ onChange, value, placeholder }) {
  return (
      <input
          placeholder={placeholder}
          className="border border-gray-400 rounded-full text-xs"
          placeholderClassName="text-xs py-1.5 px-2"
          style={{ color: '#333333', padding: '13px 50px' }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
      />
  );
}

Input.defaultProps = {
  placeholder: '',
  value: '',
};
Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
export default Input;
