import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Loading from '../components/elements/Loading';

import SearchBox from '../components/SearchBox';
import useAppStore from '../contexts/useAppStore';
import WhyComponent from '../components/WhyComponent';

function Home() {
  const { isFetchingAgent } = useAppStore();
  return (
    <>
      <Helmet>
        <title>Best Travel Agents in the US and Canada | Vacation.com</title>
        <meta name="title" content="Best Travel Agents in the US and Canada | Vacation.com" />
              <meta name="description" content="Find travel agents, advisors and agencies providing one of a kind vacation services for trips of all kinds." />
      </Helmet>
      <Hero title="FIND THE PERFECT TRAVEL AGENT FOR YOU" />
      <div className=" max-w-screen-2xl mx-auto">

        <div className="min-h-screen">

          <div className="-mt-44 relative left-0 md:mx-20 right-0 z-10">
            <SearchBox />
          </div>

          <div className="mt-28">
            <WhyComponent />
          </div>
          {isFetchingAgent() && <Loading />}
        </div>
      </div>
    </>
  );
}

export default Home;
