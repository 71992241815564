/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Modal from 'react-modal';
import {
    IoClose, IoCall, IoMail, IoCalendar, IoLogoLinkedin, IoLogoYoutube, IoAtOutline,
    IoChevronBack,
} from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import {
    callMe, CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE, CONTACT_METHOD_VIDEO, modalCustomStyles,
} from '../Utils';
import useAppStore from '../contexts/useAppStore';
import AppointmentForm from './AppointmentForm';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import EmergencyEmailForm from './EmergencyEmailForm';
import ButtonBlue from './elements/ButtonBlue';

function AgentLabel({ agent, reviewCallback, isAdditionalBio, story }) {
    const {
        firstName, lastName, city, state,
    } = agent;

    const [showPinBar, setShowPinBar] = useState(false);
    const history = useHistory();
    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, setIsAppointmentFormModalOpen, setIsEmergencyFromModalOpen, isEmailFormModalOpen, isPhoneFormModalOpen, isAppointmentFormModalOpen, isEmergencyFromModalOpen, closeFormModal,
    } = useAppStore();

    const destinations = agent.agentDestinations ? agent.agentDestinations.map((x) => (x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area))) : [];
    const interests = agent.agentInterests ? agent.agentInterests.map((x) => x.interestName) : [];
    const suppliers = agent.agentSuppliers ? agent.agentSuppliers.map((x) => x.supplierName) : [];

    const onPhoneCall = () => {
        callMe(agent);
        setIsPhoneFormModalOpen(true);
    };

    const agentConnections = () => (
        <>
            {agent.agentConnections && agent.agentConnections.map((x) => (
                <div key={x.contactMethod}>
                    {x.contactMethod === 'Facebook'
                        && (
                            <a href={x.contactValue} target="_blank" rel="noreferrer" aria-label="Facebook">
                                <div className="blue-btn p-1 mb-1 h-8 w-8 rounded-full flex justify-center items-center">

                                    <img
                                        alt="facebook"
                                        src="/images/social/facebook.png"
                                    />
                                </div>
                            </a>
                        )}
                    {x.contactMethod === 'Instagram'
                        && (
                            <a href={x.contactValue} target="_blank" rel="noreferrer" aria-label="Instagram">
                                <div className="blue-btn p-1  mb-1 h-8 w-8 rounded-full flex justify-center items-center">
                                    <img
                                        alt="instagram"
                                        src="/images/social/instagram.png"
                                    />
                                </div>
                            </a>
                        )}
                    {x.contactMethod === 'Twitter'
                        && (
                            <a href={x.contactValue} target="_blank" rel="noreferrer" aria-label="Twitter">
                                <div className="blue-btn p-1  mb-1 h-8 w-8 rounded-full flex justify-center items-center">
                                    <img
                                        alt="twitter"
                                        src="/images/social/twitter.png"
                                    />
                                </div>
                            </a>
                        )}
                    {x.contactMethod === 'YouTube'
                        && (
                            <a href={x.contactValue} target="_blank" rel="noreferrer" aria-label="Youtube">
                                <div className="blue-btn p-1 mb-1 h-8 w-8 rounded-full flex justify-center items-center">
                                    <IoLogoYoutube className="text-white w-4 h-4" />
                                </div>
                            </a>
                        )}
                    {x.contactMethod === 'Pinterest'
                        && (
                            <a href={x.contactValue} target="_blank" rel="noreferrer" aria-label="Pinterest">
                                <div className="blue-btn p-1 mb-1 h-8 w-8 rounded-full flex justify-center items-center">
                                    <img
                                        alt="pinterest"
                                        src="/images/social/pinterest.png"
                                    />
                                </div>
                            </a>
                        )}

                    {x.contactMethod === 'LinkedIn'
                        && (
                            <a href={x.contactValue} target="_blank" rel="noreferrer" aria-label="Linkedin">
                                <div className="blue-btn p-1 mb-1 h-8 w-8 rounded-full flex justify-center items-center">
                                    <IoLogoLinkedin className="text-white w-4 h-4" />
                                </div>
                            </a>
                        )}

                    {x.contactMethod === 'My Blog'
                        && (
                            <a href={x.contactValue} target="_blank" rel="noreferrer" aria-label="My Blog">
                                <div className="blue-btn p-1 mb-1 h-8 w-8 rounded-full flex justify-center items-center">
                                    <IoAtOutline className="text-white w-6 h-6" />
                                </div>
                            </a>
                        )}
                </div>
            ))}

        </>
    );

    const pinnedBar = () => (
        <div className="flex flex-col md:flex-row justify-between py-3 w-full gap-4">
            <div className="flex flex-col md:flex-row gap-2 items-center flex-1">
                <div className="md:border-r border-blue-dark px-2">
                    <h1 className="text-blue-dark text-2xl md:text-4xl font-semibold tracking-wide">{`${firstName} ${lastName}`}</h1>
                </div>
                <div className="md:text-blue-dark md:my-2 font-semibold text-sm md:text-2xl">{`${city}, ${state} Travel Agent`}</div>

            </div>
            <div className="flex flex-row gap-3 flex-1">
                {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                    <div className="w-full ">
                        <ButtonBlue
                            titleClass="hidden-tw md:block"
                            disabled={agent.isOffline}
                            title="EMAIL ME"
                            onClick={() => (!agent.isOffline ? setIsEmailFormModalOpen(true) : {})}
                            icon={<IoMail className="text-white w-4 h-4" />}
                        />
                    </div>
                )}

                {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                    <div className="w-full">

                        <ButtonBlue
                            titleClass="hidden-tw md:block"
                            disabled={agent.isOffline}
                            title="CALL ME"
                            onClick={() => (!agent.isOffline ? onPhoneCall() : {})}
                            icon={<IoCall className="text-white w-4 h-4" />}
                        />
                    </div>
                )}
                {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
                    <div className="w-full">
                        <ButtonBlue
                            titleClass="hidden-tw md:block"
                            disabled={agent.isOffline}
                            title="VIDEO APPOINTMENT"
                            onClick={() => (!agent.isOffline ? setIsAppointmentFormModalOpen(true) : {})}
                            icon={<IoCalendar className="text-white w-4 h-4" />}
                        />
                    </div>
                )}

            </div>

        </div>
    );

    const listenToScroll = () => {
        const heightToHideFrom = 300;

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) { setShowPinBar(true); } else { setShowPinBar(false); }
    };
    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('resize', listenToScroll);
        };
    }, []);

    return (
        <>

            <Helmet>
                <title>{`${firstName} ${lastName} ${city}, ${state} Travel Agent | Vacation.com`}</title>
                {story && (
                    <meta
                        name="description"
                        content={`${story.replace(/(<([^>]+)>)/ig, '').substring(0, 180)}`}
                    />
                )}
                <meta name="title" content={`${firstName} ${lastName}: ${city}, ${state} Travel Agent | Vacation.com`} />
                <script type="application/ld+json">
                    {`
           {
            "@context": "https://schema.org",
            "@type": "TravelAgency",
            "name": "${firstName} ${lastName}",
            "telephone": "${agent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}",
            "description": "${agent.overviewTitle}",
            "logo": "https://www.vacation.com/lib/img/vacation-logo.webp",
            "image": "${agent.agentPhotoFileName}",
            "sameAs": [${agent.agentConnections.filter((x) => !(['by Email', 'by Phone', 'Video Appointment'].includes(x.contactMethod))).map((x) => (`"${x.contactValue}"`))}],
            "knowsAbout":[ ${destinations.map((x) => `"${x}"`)}
             ${interests.length > 0 ? ',' : ''}${interests.map((x) => `"${x}"`)}
             ${suppliers.length > 0 ? ',' : ''}${suppliers.map((x) => `"${x}"`)}
           ],
            "review": [

              ${agent.agentReviews && agent.agentReviews.slice(0, 10).map((x) => (`{

                    "@type": "Review",
                    "author": "${x.customerNickname}",
                    "about": "${x.customerDestination}",
                    "reviewBody": "${x.review}",
                    "reviewRating": {
                      "@type": "Rating",
                      "bestRating": "5",
                      "ratingValue": "${x.rating}",
                      "worstRating": "1"
                    }

                  }`))
                        }         
            ],
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "${city}",
              "addressRegion": "${state}"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "${agent.agentRating ? agent.agentRating.rating : 0}",
              "reviewCount": "${agent.agentRating ? agent.agentRating.totalReviews : 0}"
            }
          }
          `}

                </script>
                {/* <link rel="canonical" href={window.location.href} /> */}

                <link rel="stylesheet" href="https://unpkg.com/purecss@2.0.6/build/pure-min.css" integrity="sha384-Uu6IeWbM+gzNVXJcM9XV3SohHtmWE+3VGi496jvgX1jyvDTXfdK+rfZc8C1Aehk5" crossOrigin="anonymous" />

            </Helmet>
            <div className={[showPinBar ? 'opacity-100' : 'opacity-0 hidden-tw', 'transition-all fixed right-0 left-0 top-10 bg-white px-6 max-w-screen-2xl mx-auto z-50 border-b border-gray-normal shadow-md'].join(' ')}>{pinnedBar()}</div>
            <div className="w-full flex flex-col justify-between pt-5">

                <div className="flex flex-row gap-4 items-center">
                    <div className="md:hidden">
                        {isAdditionalBio && (
                            <div
                                className="text-blue-dark text-xs mb-2 font-semibold
              flex flex-row
              items-center cursor-pointer gap-1"
                                onClick={() => history.push(`/agent/${agent.agentId}`)}
                            >
                                <IoChevronBack className=" text-right" />
                                <div>
                                    {' '}
                                    Return to My Main profile
                                </div>
                            </div>
                        )}
                        <div>
                            <img
                                alt={agent.firstName + ' ' + agent.lastName + ': ' + agent.city + ' ' + agent.state + ' Travel Agent | Vacation'}
                                src={agent.agentPhotoFileName}
                                className="w-40  rounded-tr-3xl z-20 relative"
                            />
                            <div className="h-1 bg-blue-dark w-40 my-4 " />
                        </div>
                    </div>
                    <div>
                        <h1 className="text-blue-dark text-xl md:text-4xl font-semibold tracking-wide">{`${firstName} ${lastName}`}</h1>
                        <div className="md:text-blue-dark my-2 font-semibold text-sm md:text-2xl">{`${city}, ${state} Travel Agent`}</div>
                        <div className="h-1.5 bg-blue-dark w-28 mb-2 mt-6 hidden-tw md:block" />
                    </div>
                </div>
                <div className="text-sm md:text-lg ">
                    {agent.agentRating && agent.agentRating.totalReviews > 0
                        && (
                            <div
                                className="flex flex-col gap-1 cursor-pointer"
                                onClick={reviewCallback}
                            >
                                <div>

                                    <StarRatings
                                        rating={agent.agentRating.rating}
                                        starRatedColor="#33C590"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="16px"
                                        starSpacing="2px"
                                    />

                                </div>
                                <div className="flex flex-row gap-2 cursor-pointer font-thin text-xs md:text-sm">
                                    <div
                                        className=""
                                    >
                                        {agent.agentRating.totalReviews}
                                        {' '}
                                        {agent.agentRating.totalReviews > 1 ? 'reviews' : 'review'}
                                    </div>
                                    <div className="">
                                        {' / '}
                                        {((agent.agentRating.recommended / agent.agentRating.totalReviews) * 100).toFixed(2)}
                                        % Recommended
                                    </div>
                                    {agent.yearsActive && (
                                        <div>
                                            {' / '}
                                            {agent.yearsActive}
                                            {' '}
                                            Years of Experience
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                </div>
                {agent.isOffline
                    && (
                        <div className="bg-gray-light p-1 md:p-3 rounded-xl md:rounded-3xl flex justify-center items-center flex-col gap-1 md:w-2/3 my-2 text-center">
                            <div className="text-sm md:text-xl font-semibold cursor-pointer">I am currently not accepting customers inquiries</div>
                            <div className="items-center text-blue-dark  font-semibold cursor-pointer text-xs md:text-lg" onClick={() => setIsEmergencyFromModalOpen(true)}>    Have an Emergency? Contact Me Here</div>
                        </div>
                    )}
                <div className={[agent.isOffline ? 'grid grid-cols-1 md:grid-cols-2' : 'grid grid-cols-1 md:grid-cols-2', '  gap-4 md:w-1/2 items-end mt-6'].join(' ')}>

                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                        <div className="w-full ">
                            <ButtonBlue
                                disabled={agent.isOffline}
                                title="EMAIL ME"
                                onClick={() => (!agent.isOffline ? setIsEmailFormModalOpen(true) : {})}
                                icon={<IoMail className="text-white w-4 h-4" />}
                                extraClass="emailme"
                            />
                        </div>
                    )}

                    <div className="text-red-600 text-4xl  flex-row gap-2 justify-center hidden-tw md:flex">
                        {agentConnections()}
                    </div>

                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                        <div className="w-full">

                            <ButtonBlue
                                disabled={agent.isOffline}
                                title="CALL ME"
                                onClick={() => (!agent.isOffline ? onPhoneCall() : {})}
                                icon={<IoCall className="text-white w-4 h-4" />}
                                extraClass="callme"
                            />
                        </div>
                    )}
                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
                        <div className="w-full">
                            <ButtonBlue
                                disabled={agent.isOffline}
                                title="VIDEO APPOINTMENT"
                                onClick={() => (!agent.isOffline ? setIsAppointmentFormModalOpen(true) : {})}
                                icon={<IoCalendar className="text-white w-4 h-4" />}
                                extraClass="videome"
                            />
                        </div>
                    )}
                    <div className="text-red-600 text-4xl  flex flex-row gap-2 justify-center  md:hidden">
                        {agentConnections()}
                    </div>

                </div>

            </div>
            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen || isAppointmentFormModalOpen || isEmergencyFromModalOpen}
                onRequestClose={() => closeFormModal()}
                style={modalCustomStyles}
                contentLabel="Form Modal"
                ariaHideApp={false}
            >
                <>
                    <div>
                        <div className="flex justify-end cursor-pointer" onClick={() => closeFormModal()}>
                            <IoClose className="text-red-500 text-right" />
                        </div>
                        {isEmailFormModalOpen && <EmailForm agent={agent} />}
                        {isPhoneFormModalOpen && (
                            <PhoneForm
                                agent={agent}
                                phone={agent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                                callBack={() => {
                                    setIsPhoneFormModalOpen(false);
                                    setIsEmailFormModalOpen(true);
                                }}
                            />
                        )}

                        {isAppointmentFormModalOpen && (
                            <AppointmentForm
                                agent={agent}
                            />
                        )}
                        {isEmergencyFromModalOpen && <EmergencyEmailForm agent={agent} />}
                    </div>

                </>
            </Modal>

        </>
    );
}

export default AgentLabel;
