/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import parser from 'html-react-parser';
import Slider from 'react-slick';
import { IoCaretForwardOutline, IoCaretDownOutline } from 'react-icons/io5';
import sanitizeHtml from 'sanitize-html-react';
import { useHistory } from 'react-router-dom';
import SlideDown from 'react-slidedown';
import SliderArrows from './elements/SliderArrows';
import { wrapperClass, slidesettings } from '../Utils';

function AgentTravelSpecialities({ agent, additionalBio }) {
  const history = useHistory();
  const slider = useRef(null);
  const limit = 3;
  const [openedList, setOpenedList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onItemClicked = (item) => {
    if (openedList.includes(item)) {
      setOpenedList([...openedList.filter((x) => x !== item)]);
    } else {
      openedList.push(item);
      setOpenedList([...openedList]);
    }
  };

  return (
    <div className={[wrapperClass, 'bg-white md:px-20'].join(' ')}>
      <div className="flex flex-row justify-center md:justify-between">
        <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
          <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left text-blue-dark">
            My Travel Specialties
          </div>
          <div className="bg-blue-dark h-1 md:h-1.5 w-24 md:w-14 md:ml-0" />
        </div>
        {additionalBio.length > limit && (
        <div className="hidden-tw md:flex ">
          <div>
            <SliderArrows
              onPrev={() => slider.current.slickNext()}
              onNext={() => slider.current.slickPrev()}
              hasNext={selectedIndex + 3 < additionalBio.length}
              hasPrev={selectedIndex !== 0}
              horizontal
            />
          </div>
        </div>
        )}
      </div>
      <div className="hidden-tw md:block -mr-20">
        <Slider
          {...slidesettings(additionalBio.length < limit ? additionalBio.length : limit, false)}
          ref={slider}
          afterChange={(index) => setSelectedIndex(index)}
        >
          {additionalBio.sort((a, b) => a.displayOrder - b.displayOrder).map((x) => (
            <div className="pr-2 md:pr-10 max-w-xl" key={x.displayTitle}>
              <div
                className="flex flex-col gap-6 rounded-tr-3xl shadow-lg  border border-gray-100   cursor-pointer"
                onClick={() => history.push(`/agent/${agent.agentId}/bio/${x.addlBioId}`)}
              >
                <div className="rounded-tr-3xl h-80">
                    <img src={x.travelImage} className="w-full h-80 rounded-tr-3xl object-cover" alt={agent.firstName + ' ' + agent.lastName + ' - ' + x.displayTitle + ' - ' + agent.city + ', ' + agent.state} />
                  {' '}
                </div>
                <div className="p-4 flex flex-col gap-3 h-80">
                  <div className="text-black font-bold text-lg uppercase">{x.displayTitle}</div>
                  <div
                    className="text-sm text-gray-900"
                  >

                    { x.bioText.length > 250 ? parser(`${sanitizeHtml(x.bioText.slice(0, 250))}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)
                      : parser(`${sanitizeHtml(x.bioText)}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className=" md:hidden">
        {
          additionalBio.sort((a, b) => a.displayOrder - b.displayOrder).map((x) => (
            <div className="" key={x.displayTitle}>

              <div className="uppercase font-bold text-sm  mb-8 text-left tracking-widest flex flex-row items-center gap-1 text-black">
                <div onClick={() => onItemClicked(x.displayTitle)}>
                  {openedList.includes(x.displayTitle) ? <IoCaretDownOutline className="text-lg text-blue-dark" /> : <IoCaretForwardOutline className="text-lg text-blue-dark" />}
                </div>
                <div>
                  {' '}
                  {x.displayTitle}
                </div>
              </div>
              <SlideDown className="my-dropdown-slidedown">
                {openedList.includes(x.displayTitle)
                && (
                <>
                  <div
                    className="flex flex-col gap-6 px-4  cursor-pointer"
                    onClick={() => history.push(`/agent/${agent.agentId}/bio/${x.addlBioId}`)}
                  >
                    <div className="rounded-tr-3xl h-80">
                                      <img src={x.travelImage} className="w-full h-80 rounded-tr-3xl object-cover" alt={agent.firstName + ' ' + agent.lastName + ' - ' + x.displayTitle + ' - ' + agent.city + ', ' + agent.state} />
                      {' '}
                    </div>

                    <div
                      className="text-xs text-gray-500 px-2"
                    >

                      { x.bioText.length > 250 ? parser(`${sanitizeHtml(x.bioText.slice(0, 250))}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)
                        : parser(`${sanitizeHtml(x.bioText)}...<span class="mx-2 text-black cursor-pointer underline font-semibold">Read more</span>`)}
                    </div>
                    <div />
                  </div>
                </>
                )}
              </SlideDown>
            </div>
          ))
          }
      </div>
    </div>
  );
}

export default AgentTravelSpecialities;
