/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import 'react-accessible-accordion/dist/fancy-example.css';
import {
    circle, curve, dot, horizontalBorder, verticalBorder,
} from '../Utils';

const data = [{
    title: 'EXPLORE',
    description: 'Meet our collection of experienced travel experts and destination specialists to find the one perfect to plan your next vacation.',
    number: '01.',
},
{
    title: 'CONNECT',
    description: 'Discover how easy it is to get started with the help of a travel advisor. Reach out by text, email, or phone – and begin the conversation.',
    number: '02.',
},
{
    title: 'TRAVEL',
    description: 'Enjoy a stress-free vacation customized around your passions and planned to perfection from a true travel pro.',
    number: '03.',
}];

function GetMostFromVacation() {
    const render = (item, index) => (
        <>
            <div className="flex flex-col md:gap-10 gap-2 overflow-hidden ">
                <>
                    <div className="absolute left-0 top-0 -mt-3 md:mt-0">{verticalBorder}</div>
                    <div className={['absolute left-0 top-0 -mt-3', index > 0 ? 'hidden md:block' : '-mt-5 md:-mt-3'].join(' ')}>{curve}</div>
                    <div className="absolute bottom-0 left-0.5 -mb-6 -ml-5 bg-blue-dark z-10">{circle}</div>
                    {index === data.length - 1 && <div className="absolute bottom-0 right-0 -mb-6  bg-blue-dark z-10">{dot}</div>}
                    <div className={['absolute bottom-0 left-0.5 -mb-2 ', index === data.length - 1 ? '-ml-5' : '-mr-12 md:mr-0'].join(' ')}>{horizontalBorder}</div>
                </>
                <div className="font-semibold text-xl md:text-2xl">{item.title}</div>
                <div className="text-xs lg:text-md font-nprmal">{item.description}</div>
            </div>

            <div className="self-start">
                <span className="font-semibold text-xl md:text-2xl text-right  text-white">{item.number}</span>
            </div>
        </>
    );
    return (
        <div className="bg-blue-dark py-16 md:py-8 text-white relative">
            <div className="flex flex-col gap-4 text-center ">
                <div className="text-xl md:text-4xl font-medium px-20 md:px-0">
                    How To Get The Most From Vacation
                </div>
                <div className="h-1.5 w-14 bg-white mx-auto" />
            </div>
            <div className="flex flex-col md:flex-row justify-center px-12 md:py-20 py-8 mt-4">
                {data.map((x, index) => (
                    <div className="flex-1  rounded-lg py-10 md:py-6 px-4 md:px-12 flex justify-between relative items-center" key={x.title}>
                        {render(x, index)}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default GetMostFromVacation;
