/* eslint-disable react/prop-types */
import React from 'react';
import { IoCaretBackSharp, IoCaretForwardSharp } from 'react-icons/io5';

function SliderArrows({
  onNext, onPrev, horizontal, hasNext, hasPrev,
}) {
  return (
    <div className={['flex  gap-4', horizontal ? 'flex-row' : 'flex-col'].join(' ')}>
      <div onClick={() => onNext()} className={['py-3 pr-1  flex text-center ', hasPrev ? 'bg-blue-dark hover:bg-green-light cursor-pointer' : 'bg-gray-light'].join(' ')}>
        <IoCaretBackSharp className="text-white text-3xl" />
      </div>
      <div onClick={() => onPrev()} className={[' py-3 pl-1  flex text-center', hasNext ? 'bg-blue-dark hover:bg-green-light cursor-pointer' : 'bg-gray-light'].join(' ')}>
        <IoCaretForwardSharp className="text-white text-3xl" />
      </div>
    </div>
  );
}

export default SliderArrows;
