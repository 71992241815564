/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import React, {
  useEffect, useRef, useState,
} from 'react';
import parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { IoCaretForwardOutline, IoCaretDownOutline } from 'react-icons/io5';
import SlideDown from 'react-slidedown';
import sanitizeHtml from 'sanitize-html-react';
import Loader from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';
import { downCurveRight, IMAGE_URL, slidesettings, upCurve,
} from '../Utils';
import urlSlug from 'url-slug';
import Slider from 'react-slick';

function Blogs() {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const links = [{
    label: 'Home',
    url: '/',
  },
  {
    label: 'Travel Information',
  },
  ];

  const getCategories = () => {
    setLoading(true);
    API.getCategories().then((result) => {
      if (result.responseStatus === 1) {
        setCategories(result.data);
      } else { toast(result.error.message); }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCategories();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" max-w-screen-2xl mx-auto" role="main">
      <ToastContainer />
      <Helmet>
        <title>Travel News and Information from Vacation.com</title>
        <meta name="title" content="Travel News and Information from Vacation.com" />
        <meta
          name="description"
          content="Get travel information from our experts' first-hand experience and knowledge. See tips and news about your next trip, be it leisurely vacation or business."
        />
      </Helmet>

      <div className="min-h-screen pb-10 md:pb-40 bg-white ">

        <div className="flex flex-col gap-0 ">
          <div className="bg-blue-dark p-6 pb-14 md:p-10 relative md:pb-40 overflow-x-hidden">
            <div className="absolute left-0 w-1/3 opacity-40 -ml-12 hidden-tw md:block top-16">{downCurveRight}</div>
            <div className="absolute right-0 w-1/3 opacity-40  -mr-48 bottom-6 hidden-tw md:block">{upCurve}</div>
            <div className="ml-2  md:py-16 py-8 mt-4">
              <BreadCrumb links={links} inverted />
            </div>
            <div className="text-white text-2xl md:text-5xl  font-bold justify-center flex uppercase">

              <h1>Travel Information</h1>
            </div>
            <div className="max-w-5xl text-sm md:text-xl text-white lg:mx-auto mt-10 text-center font-semibold">
              Great vacations don’t just happen by accident.
              Get travel ideas, tips, and information directly from the experts so you can get the most out of your trip.
            </div>
          </div>
          <div className="flex flex-col gap-4 items-start justify-center  w-full">
            {categories.map((x, index) => (
              <div key={x.categoryName} className="flex-1 w-full">
                <CategoryBlogs name={x.categoryName} index={index} />
              </div>
            ))}
          </div>
        </div>

      </div>

      {loading && <Loading />}
    </div>
  );
}

export default Blogs;

export function CategoryBlogs({ name, index }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const slider = useRef(null);
  const limit = 3;
  const [isOdd, setIsOdd] = useState(false);
  const [openedList, setOpenedList] = useState([]);

  const onItemClicked = (item) => {
    if (openedList.includes(item)) {
      setOpenedList([...openedList.filter((x) => x !== item)]);
    } else {
      openedList.push(item);
      setOpenedList([...openedList]);
    }
  };

  const getBlogs = () => {
    setLoading(true);
    API.getBlogs({
      rows: 5,
      name,
    }).then((result) => {
      if (result.responseStatus === 1) {
        setData(result.data);
        setOpenedList(result.data.map((x) => x.storyId));
      } else { toast(result.error.message); }
      setLoading(false);
    });
  };
  useEffect(() => {
    getBlogs();
    if ((index % 2) !== 0) {
      setIsOdd(true);
    }
  }, []);
  return (
    <>
      {data.length > 0
      && (
      <div className=" pb-8 md:pb-32 relative overflow-x-hidden">
        <div className={[!isOdd ? 'md:bg-gray-light bg-white' : 'bg-blue-dark ', 'h-full md:h-96 w-full max-w-screen-2xl  absolute z-0 '].join(' ')} />
        {!isOdd && <div className="absolute left-0 w-1/3 opacity-80 -ml-32 hidden-tw md:block bottom-4">{downCurveRight}</div>}
        {isOdd && <div className="absolute right-0 w-1/3 opacity-80 -mr-32 hidden-tw md:block bottom-4">{upCurve}</div>}
        <ToastContainer />
        <div className="flex flex-row justify-between w-full relative md:px-32">

          <div className="flex flex-col gap-4 mb-8 items-center md:items-start pt-10 mx-auto md:mx-0">

            <div className={[isOdd ? 'text-white' : 'text-blue-dark ', 'text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left z-10'].join(' ')}>
              {name}
            </div>
            <div className={[isOdd ? 'bg-white' : 'bg-blue-dark ', 'h-1 md:h-1.5 w-24 md:w-14 md:ml-0 z-10'].join(' ')} />
          </div>
          {data.length > 4 && !loading && (
          <div
            className={[!isOdd ? 'text-black' : 'text-white',
              'text-sm font-semibold  cursor-pointer items-center justify-center gap-1 uppercase pr-10 z-10 hidden-tw md:flex'].join(' ')}
            onClick={() => history.push(`/travel_information_category?category=${name}`)}
          >
            <div>
              See more
              {' '}
              { name.toLowerCase() !== 'travel tips' ? `${name} stories` : `${name}`}
            </div>
            <div>{'>'}</div>
          </div>
          ) }
        </div>
        {!loading && (
          <>
            <div className="hidden-tw md:block z-10 px-32 ">
              <Slider {...slidesettings(data.length < limit ? data.length : limit, false)} ref={slider}>
                {data.slice(0, limit).map((x) => (
                  <div className="pr-2 max-w-sm" key={x.storyId}>
                    <div
                      className="flex flex-col gap-6  cursor-pointer bg-blue-dark text-white rounded-tr-3xl"
                      onClick={() => history.push(`/travel_information/${x.storyId}/${urlSlug(x.title)}`)}
                    >
                      <div className="rounded-tr-3xl  ">
                        <img src={`${IMAGE_URL()}/${x.thumbnailImage}`} className="w-full  rounded-tr-3xl object-cover" alt="Blog" />
                        {' '}
                      </div>

                      <div className="h-48">
                        <div className="font-bold text-lg uppercase px-4">{x.title}</div>
                        <div
                          className="text-xs  px-4 "
                        >

                          { x.shortDescription.length > 250 ? parser(`${sanitizeHtml(x.shortDescription.slice(0, 250))}...<span class="mx-2  cursor-pointer underline font-semibold">Read more</span>`)
                            : parser(`${sanitizeHtml(x.shortDescription)}...<span class="mx-2  cursor-pointer underline font-semibold">Read more</span>`)}
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="block md:hidden z-10 relative px-4">
              {
                data.slice(0, limit).map((x) => (
                  <div className="z-10" key={x.storyId}>

                    <div className={[!isOdd ? 'text-black' : 'text-white', 'uppercase font-bold text-sm  mb-8 text-left tracking-widest flex flex-row items-center gap-1'].join(' ')}>
                      <div onClick={() => onItemClicked(x.storyId)} className={!isOdd ? 'text-blue-dark' : 'text-white'}>
                        {openedList.includes(x.storyId) ? <IoCaretDownOutline className="text-lg " /> : <IoCaretForwardOutline className="text-lg " />}
                      </div>
                      <div>
                        {' '}
                        {x.title}
                      </div>
                    </div>
                    <SlideDown className="my-dropdown-slidedown">
                      {openedList.includes(x.storyId)
                 && (
                 <>
                   <div
                     className={[!isOdd ? 'text-black' : 'text-white', 'flex flex-col gap-6 px-4  cursor-pointer'].join(' ')}
                     onClick={() => history.push(`/travel_information/${x.storyId}/${urlSlug(x.title)}`)}
                   >
                     <div className="rounded-tr-3xl">
                       <img src={`${IMAGE_URL()}/${x.thumbnailImage}`} className="w-full rounded-tr-3xl object-cover" alt="Blog" />
                       {' '}
                     </div>

                     <div
                       className="text-xs  px-2"
                     >

                       { x.shortDescription.length > 250 ? parser(`${sanitizeHtml(x.shortDescription.slice(0, 250))}...<span class="mx-2  cursor-pointer underline font-semibold">Read more</span>`)
                         : parser(`${sanitizeHtml(x.shortDescription)}...<span class="mx-2  cursor-pointer underline font-semibold">Read more</span>`)}
                     </div>
                     <div />
                   </div>
                 </>
                 )}
                    </SlideDown>
                  </div>
                ))
           }
            </div>
            {data.length > 4 && !loading && (
            <div
              className={[isOdd ? 'text-white' : 'text-blue-dark',
                'text-sm font-semibold  cursor-pointer flex pl-10 w-full gap-1 uppercase z-10 relative overflow-x-hidden md:hidden'].join(' ')}
              onClick={() => history.push(`/travel_information_category?category=${name}`)}
            >
              <div>
                See more
                {' '}
                { name.toLowerCase() !== 'travel tips' ? `${name} stories` : `${name}`}
              </div>

            </div>
            ) }
          </>

        ) }
        {loading && (
        <div className="my-20 flex justify-center items-center w-full">
          <Loader
            type="ThreeDots"
            color="#1A5895"
            height={50}
            width={50}
          />
        </div>
        )}
      </div>
      )}
    </>
  );
}
