import React from 'react';
import PropTypes from 'prop-types';

function ButtonGray({ onClick, title, disabled }) {
  return (
    <button
      type="button"
      className={['border rounded-full py-2.5 px-4 text-sm w-full font-normal bg-gray-verylight md:bg-gray-light hover:bg-green-light ',
        disabled ? 'text-gray-100' : 'text-black hover:text-white'].join(' ')}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

ButtonGray.defaultProps = {
  disabled: false,
};

ButtonGray.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ButtonGray;
