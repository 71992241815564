/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { IoStarOutline } from 'react-icons/io5';
import SliderArrows from './elements/SliderArrows';
import { slidesettings, AD_URL } from '../Utils';

function AgentCertificates({ agent, certificates }) {
    const sliderDeskTop = useRef(null);
    const slider = useRef(null);
    const limit = 4;
    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <div className="py-6  flex flex-row gap-6  bg-gray-verylight items-center   justify-between md:py-4 my-4 px-1 md:px-20 w-full">

            <div className="w-full flex flex-col md:flex-row gap-4 justify-center items-center flex-1">
                <div className="text-blue-dark font-bold text-xl md:text-4xl md:mr-20">Certificates & Awards</div>
                <div className="bg-blue-dark h-1 md:h-1.5 w-24 md:w-14 md:ml-0 md:hidden" />
                {certificates.length + agent.agentCertifications.length > limit
                    && (
                        <div className="hidden-tw md:flex">
                            <SliderArrows
                                onPrev={() => sliderDeskTop.current.slickNext()}
                                onNext={() => sliderDeskTop.current.slickPrev()}
                                hasNext={selectedIndex + limit < (certificates.length + agent.agentCertifications.length)}
                                hasPrev={selectedIndex !== 0}
                            />

                        </div>
                    )}

                <div className="hidden-tw md:block md:w-1/2 md:mt-8">
                    <Slider
                        {...slidesettings(certificates.length + agent.agentCertifications.length < limit ? (certificates.length + agent.agentCertifications.length) : limit, false)}
                        ref={sliderDeskTop}
                        afterChange={(index) => setSelectedIndex(index)}
                    >
                        {certificates.map((x) => (
                            <div key={x}>
                                <img
                                    src={x}
                                    className="w-24 md:w-24 mx-auto p-2"
                                    alt="certificate"
                                />
                            </div>
                        ))}
                        {agent.agentCertifications.filter((x) => x.certification !== 'TTAUS'
                            && x.certification !== 'TTACA').map((x) => (
                                <div key={`${x.certification}-${x.certificationRank}`} className="flex flex-col gap-2 items-center tl-cyan">
                                    <div className="flex flex-col gap-2 items-center">
                                        {!x.imageFile ? (
                                            <IoStarOutline className="w-16 h-16 md:w-20 md:h-20 mx-auto" />
                                        ) : (
                                            <img
                                                src={`${AD_URL()}/supplierCerts/${x.imageFile}`}
                                                className="w-48 p-2"
                                                style={{ width: '96px', height: '96px' }}
                                                alt={x.certification}
                                            />
                                        )}
                                        <div className="text-xs md:text-xs font-medium w-20 md:w-40 text-center">
                                            {x.certification.split(' ').map((word, index, wordsArray) => (
                                                <React.Fragment key={index}>
                                                    {index % 2 === 0 && index > 0 ? <br /> : null}
                                                    {word}
                                                    {' '}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Slider>
                </div>

                <div className="w-full md:hidden">
                    <Slider
                        {...slidesettings(certificates.length + agent.agentCertifications.length < limit ? (certificates.length + agent.agentCertifications.length) : limit, false)}
                        ref={slider}
                        afterChange={(index) => setSelectedIndex(index)}
                    >
                        {certificates.map((x) => (
                            <div key={x}>
                                <img
                                    src={x}
                                    className="w-24 mx-auto p-2"
                                    alt="certificate"
                                />
                            </div>
                        ))}
                        {agent.agentCertifications.filter((x) => x.certification !== 'TTAUS'
                            && x.certification !== 'TTACA').map((x) => (
                                <div key={`${x.certification}-${x.certificationRank}`} className="flex flex-col gap-2 items-center tl-cyan">
                                    {!x.imageFile ? (
                                        <IoStarOutline className="w-16 h-16 md:w-20 md:h-20 mx-auto" />
                                    ) : (
                                        <img
                                            src={`${AD_URL()}/supplierCerts/${x.imageFile}`}
                                            className="w-48 p-2"
                                            style={{ width: '96px', height: '96px' }}
                                            alt={x.certification}
                                        />
                                    )}
                                    <div className="text-xs md:text-sm font-medium w-20 md:w-40 text-center">{x.certification}</div>
                                </div>
                            ))}
                    </Slider>
                </div>
                {certificates.length + agent.agentCertifications.length > limit
                    && (
                        <div className="md:hidden">
                            <SliderArrows
                                onPrev={() => slider.current.slickNext()}
                                onNext={() => slider.current.slickPrev()}
                                hasNext={selectedIndex + limit < (certificates.length + agent.agentCertifications.length)}
                                hasPrev={selectedIndex !== 0}
                                horizontal
                            />

                        </div>
                    )}
            </div>

        </div>
    );
}

export default AgentCertificates;
