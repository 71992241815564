import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';
import SearchBoxCruises from '../components/SearchBoxCruises';
import 'react-slidedown/lib/slidedown.css';
import API from '../services/API';

function FindYourCruise() {
    const [referrer, setReferrer] = useState('');
    const [queryParams, setQueryParams] = useState({});

    const links = [
        {
            label: 'Home',
            url: '/',
        },
        {
            label: 'Find Your Cruise',
        },
    ];

    useEffect(() => {
        const ref = document.referrer;
        setReferrer(ref);

        const params = new URLSearchParams(ref.split('?')[1]);
        const destination = params.get('destination') || '';
        const destinationLabel = params.get('destinationLabel') || '';
        const interest = params.get('interest') || '';

        setQueryParams((prevParams) => ({
            ...prevParams,
            destination,
            destinationLabel,
            interest,
        }));
    }, []);

    return (
        <>
        <div role="main">
            <Helmet>
                <title>Find your Perfect Cruise Anywhere with Vacation.com</title>
                <meta name="title" content="Find your Perfect Cruise Anywhere with Vacation.com" />
                <meta name="description" content="Find your Perfect Cruise" />
            </Helmet>

            <div className="min-h-screen mt-16">
                <div className="relative flex flex-col justify-center min-h-full bg-cover w-full -mt-10 overflow-x-hidden">
                    <div className="flex flex-col md:gap-4 text-center pt-2 pb-2">
                        {/* Desktop View */}
                        <div className="hidden md:block">
                            <div className="justify-center text-center py-5 pl-5" style={{ backgroundColor: '#1A5895' }}>
                                <BreadCrumb links={links} inverted />
                                <h1 className="text-2xl md:text-3xl font-bold leading-tight text-white uppercase mt-20 mb-2 pt-1">
                                    Find your Perfect Cruise
                                </h1>
                            </div>

                            <div className="md:flex md:justify-center md:items-center pt-1 md:pt-4">
                                <div className="md:w-1/2 md:text-left md:pl-20 mb-2">
                                    <h2 className="text-2xl font-bold mb-4 text-blue-dark">Let&apos;s find your dream cruise vacation</h2>
                                    <p className="mb-2 text-sm">
                                        Use the search feature here to find up-to-the-minute details on a wide range of inspiring cruise vacations - including Alaska, the Caribbean, the Baltics, Europe, Mexico, the Mediterranean, Tahiti and more - many of which include special offers you will receive for booking through our agency.
                                    </p>
                                    <p className="mb-2 text-sm">
                                        You will enjoy more than just the bonus amenities and privileges you get when you book with us - you will also receive expert advice and trusted information. We know these cruise lines and these destinations, and we look forward to offering you our real-world experience to help you make the best decisions. And since all bookings will be made through CruCon Cruise Outlet, you&apos;ll also benefit from their expert proficiency.
                                    </p>
                                </div>
                                <div className="md:w-1/2 md:pr-20 pl-10 mb-2">
                                    <img
                                        src="https://ebooks.travelleaders.com/Websites/Vacation/RCI_HM_Eastern_Caribbean_Dec2018_Content_Creator_JasonLisiewski_Labadee_3423_RET_CMYK.png"
                                        alt="Find Your Perfect Cruise"
                                        className="w-full h-auto rounded-tl-2xl"
                                        style={{ boxShadow: '-10px 10px 0px 0px #1A5895' }}
                                    />
                                </div>
                            </div>

                            <div className="md:mr-32 right-0 z-50 text-left w-full">
                                <SearchBoxCruises
                                    destination={queryParams.destination}
                                    destinationLabel={queryParams.destinationLabel}
                                    interest={queryParams.interest}
                                />
                            </div>
                        </div>

                        {/* Mobile View */}
                        <div className="md:hidden">
                            <div className="py-5 pl-5 bg-blue-dark">
                                <BreadCrumb links={links} inverted />
                            </div>
                            <div>
                                <img
                                    src="https://ebooks.travelleaders.com/Websites/Vacation/RCI_HM_Eastern_Caribbean_Dec2018_Content_Creator_JasonLisiewski_Labadee_3423_RET_CMYK.png"
                                    alt="Find Your Perfect Cruise"
                                    className="w-full h-auto"
                                />
                            </div>

                            <div className="text-center w-full" style={{ backgroundColor: '#1A5895' }}>
                                <h2 className="text-2xl font-bold text-white p-4">Let&apos;s find your dream cruise vacation</h2>
                                <SearchBoxCruises
                                    destination={queryParams.destination}
                                    destinationLabel={queryParams.destinationLabel}
                                    interest={queryParams.interest}
                                />
                            </div>

                            <div className="text-left w-full p-4" style={{ backgroundColor: '#FFFFFF' }}>
                                <h2 className="text-2xl font-bold text-blue-dark p-2">HOW IT WORKS</h2>
                                <p className="mb-2 text-sm">
                                    Use the search feature here to find up-to-the-minute details on a wide range of inspiring cruise vacations - including Alaska, the Caribbean, the Baltics, Europe, Mexico, the Mediterranean, Tahiti and more - many of which include special offers you will receive for booking through our agency.
                                </p>
                                <p className="mb-2 text-sm">
                                    You will enjoy more than just the bonus amenities and privileges you get when you book with us - you will also receive expert advice and trusted information. We know these cruise lines and these destinations, and we look forward to offering you our real-world experience to help you make the best decisions. And since all bookings will be made through CruCon Cruise Outlet, you&apos;ll also benefit from their expert proficiency.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}

export default FindYourCruise;
