/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { IoClose } from 'react-icons/io5';
import ReactImageGallery from 'react-image-gallery';
import { useHistory } from 'react-router-dom';
import parser from 'html-react-parser';
import sanitizeHtml from 'sanitize-html-react';
import Modal from 'react-modal';
import useMobileDetect from 'use-mobile-detect-hook';
import ReactPlayer from 'react-player';
import SliderArrows from './elements/SliderArrows';
import {
  slidesettings, wrapperClass, modalCustomStyles, downCurve, upCurve,
} from '../Utils';

function AgentTravelStories({
  agent, stories, albums, videos, type = 'default',
  bioId = '',
}) {
  const player = useRef(null);
  const sliderAlbum = useRef(null);
  const sliderStories = useRef(null);
  const history = useHistory();
  const [selectedAlbumId, setSelectedAlbumId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const detectMobile = useMobileDetect();
  const [noSlides, setNoSlides] = useState(3);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedStoriesIndex, setStoriesSelectedIndex] = useState(0);

  useEffect(() => {
    setNoSlides(detectMobile.isDesktop() ? 3 : 1);
  }, [detectMobile]);

  return (
    <div className="md:py-16">

      {(albums.length > 0 || videos.length > 0) && (
      <div
        className={[wrapperClass, ' md:px-20 relative overflow-x-hidden'].join(' ')}
      >
        <div className="absolute right-0 w-1/3  -mr-32 top-0 hidden-tw md:block">{downCurve}</div>
        <div className="flex flex-row md:justify-between justify-center">
          <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
            <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left text-blue-dark">
              My Photos & Videos
            </div>
            <div className={['bg-blue-dark', 'h-1.5 w-24 md:w-14 md:ml-0'].join(' ')} />
          </div>
          {albums.length + videos.length > noSlides && (
          <div className="hidden-tw md:block">
            <SliderArrows
              onPrev={() => sliderAlbum.current.slickNext()}
              onNext={() => sliderAlbum.current.slickPrev()}
              hasNext={selectedIndex + noSlides < (albums.length + videos.length)}
              hasPrev={selectedIndex !== 0}
              horizontal
            />
          </div>
          )}

        </div>

        <div className="md:block md:-mr-20 -mx-6 md:mx-0">
          <Slider
            {...slidesettings(albums.length + videos.length < noSlides ? albums.length + videos.length : noSlides, false)}
            ref={sliderAlbum}
            afterChange={(index) => setSelectedIndex(index)}
          >
            {albums.map((x) => (
              <div className="pr-2 max-w-xl" key={x.albumId}>
                <div
                  className="flex flex-col md:px-4 pr-4  cursor-pointer"
                  onClick={() => {
                    setSelectedAlbumId(x.albumId);
                    setIsModalOpen(true);
                  }}
                >
                  <div className="rounded-tr-3xl h-80">
                    <img src={x.albumImages[0].image} className="w-full h-80 rounded-tr-3xl object-cover" alt={x.albumTitle} />
                  </div>
                  <div className="text-white p-4 font-bold text-xs md:text-lg uppercase bg-blue-dark">{x.albumTitle}</div>
                </div>
              </div>
            ))}
            {videos.map((x) => (
              <div
                className="flex flex-col gap-6 px-4 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-1000"
                key={x.videoId}
              >

                <div className="rounded-tr-3xl h-80">
                  <div className="w-64 h-full md:w-full rounded-tr-3xl relative ">
                    <ReactPlayer
                      ref={player}
                      controls
                      url={x.videoURL}
                      className="absolute top-0 left-0 rounded-tr-3xl"
                      width="100%"
                      height="100%"
                    />

                    {/* <IoPlay onClick={() => { setFullScreen(!fullScreen); }} className="absolute z-50 text-6xl text-white m-auto  top-0 bottom-0 left-0 right-0" /> */}
                  </div>
                </div>
                {/* <div className="w-64 h-80 md:w-96 rounded-lg mx-auto flex justify-center items-center relative overflow-hidden">

                  <ReactPlayer
                    controls
                    url={x.videoURL}

                  />

                </div> */}

                {x.videoTitle !== '' && (
                <div className="flex flex-col gap-2 items-center ">
                  <div className="text-white p-4 font-bold text-xs md:text-lg uppercase bg-blue-dark w-full">{x.videoTitle}</div>
                </div>
                )}
              </div>

            ))}

          </Slider>
        </div>

      </div>
      )}

      {stories.length > 0 && (
      <div className={[wrapperClass, ' md:px-20'].join(' ')}>

        <div className="flex flex-row md:justify-between justify-center relative">

          <div className="absolute left-0 w-1/3  -ml-64 -top-24 hidden-tw md:block">{upCurve}</div>
          <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
            <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left text-blue-dark">
              Travel Stories
            </div>
            <div className={['bg-blue-dark', 'h-1.5 w-24 md:w-14 md:ml-0'].join(' ')} />
          </div>
          {stories.length > noSlides && (
          <div className="hidden-tw md:block">
            <SliderArrows
              onPrev={() => sliderStories.current.slickNext()}
              onNext={() => sliderStories.current.slickPrev()}
              hasNext={selectedStoriesIndex + noSlides < stories.length}
              hasPrev={selectedStoriesIndex !== 0}
              horizontal
            />
          </div>
          )}

        </div>

        <div className="md:block md:-mr-20 -mx-6 md:mx-0">
          <Slider
            {...slidesettings(stories.length < noSlides ? stories.length : noSlides, false)}
            ref={sliderStories}
            afterChange={(index) => setStoriesSelectedIndex(index)}
          >
            {stories.map((x) => (
              <div className="pr-2 md:pr-10" key={x.postId}>
                <div className="bg-white text-black flex flex-col gap-2 md:px-8 md:py-16 p-4 rounded-tr-3xl shadow-lg  border border-gray-100 flex-1 h-96">
                  <div
                    className="flex flex-col md:px-4 pr-4  cursor-pointer"
                    onClick={() => (type === 'bio'
                      ? history.push(`/agent/${agent.agentId}/bio/${bioId}/story/${x.postId}`)
                      : history.push(`/agent/${agent.agentId}/story/${x.postId}`))}
                  >
                    {/* <div className="rounded-tr-3xl h-80">
                      <img src={x.travelImage} className="w-full h-80 rounded-tr-3xl object-cover" alt={x.travelImageCaption} />
                    </div> */}
                    <div className="text-black font-bold text-xs md:text-xl uppercase">{x.postTitle}</div>
                    <div
                      className="text-xs md:text-base text-gray-500 break-all"
                    >

                      { x.postText.length > 300 ? parser(`${sanitizeHtml(x.postText.slice(0, 300))} <span class="mx-2 text-blue-dark cursor-pointer">Read more...</span><p>`)
                        : parser(`${sanitizeHtml(x.postText)} <span class="mx-2 text-blue-dark cursor-pointer">Read more...</span><p>`)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

      </div>
      ) }

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={modalCustomStyles}
        contentLabel="Modal"
        ariaHideApp={false}
      >
        <div className="max-w-6xl">
          <div className="flex justify-end cursor-pointer" onClick={() => setIsModalOpen(false)}>
            <IoClose className="text-blue-dark text-right" />
          </div>
          <div className=" px-4">

            <div>
              <div className="text-blue-dark text-xl font-medium text-center mb-6">
                {albums.find((x) => x.albumId === selectedAlbumId)?.albumTitle}
              </div>
              <ReactImageGallery
                items={albums.find((x) => x.albumId === selectedAlbumId)?.albumImages.map((y) => ({
                  original: y.image,
                  thumbnail: y.imageThumb,
                  originalTitle: y.imageCaption,
                  originalWidth: 100,
                  originalAlt: y.imageCaption || 'Vacation image',
                  thumbnailAlt: y.imageCaption || 'Vacation image',
                }))}
                additionalClass="max-w-xs md:max-w-lg"
              />
            </div>

          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AgentTravelStories;
