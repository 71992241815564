/* eslint-disable no-unused-vars */
// const API = 'https://localhost:44342';
// const API = 'https://qa.vacation.com';
const API = '';

const fetchInterests = () => fetch(`${API}/lookup/getInterests`, { method: 'GET', mode: 'cors' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchStates = () => fetch(`${API}/lookup/getStates`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchDestinations = () => fetch(`${API}/lookup/getDestinations`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

export const fetchRevelexDestinations = () => fetch(`${API}/lookup/getRevelexDestinations`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

export const fetchRevelexSuppliers = () => fetch(`${API}/lookup/getRevelexSuppliers`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchFaceted = ({
    destination, city, interest, state, language, metroRegion, zip,
    supplier,
    type,
}) => fetch(`${API}/lookup/${type}`,
    {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            destination,
            city,
            interest,
            state,
            language,
            metroRegion,
            zip,
            supplier,
        }),
    })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchAgents = ({
    zip, name, interest, destination, state,
    metroRegion,
    language,
    supplier,
    city,
    agentId = 0,
    pageSize = 20,
    currentPage = 0,
}) => fetch(`${API}/agent/getAgents?ZIP=${zip}
        &Name=${name}
        &AgentInterest=${encodeURIComponent(interest)}
        &AgentDestination=${encodeURIComponent(destination)}
        &AgentState=${encodeURIComponent(state)}
        &AgentMetroRegion=${encodeURIComponent(metroRegion)}
        &AgentLanguage=${encodeURIComponent(language)}
        &AgentCity=${encodeURIComponent(city)}
        &AgentSupplier=${encodeURIComponent(supplier)}
        &AgentId=${agentId}
        &CurrentPage=${currentPage}&PageSize=${pageSize}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchTopAgentsByReview = () => fetch(`${API}/agent/GetAgentReviewsByAgencyId`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendEmailToAgent = (data) => fetch(`${API}/customer/sendEmail`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendPhoneToAgent = (data) => fetch(`${API}/customer/sendPhone`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const agentBio = ({ agentId }) => fetch(`${API}/agent/getAgentFullBio?agentId=${agentId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const agentAlbum = ({ agentId, contentType, contentId }) => fetch(contentType
    ? `${API}/agent/GetAgentPhotoAlbum?agentId=${agentId}&contentType=${contentType}&contentId=${contentId}`
    : `${API}/agent/GetAgentPhotoAlbum?agentId=${agentId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));
const agentVideos = ({ agentId, contentType, contentId }) => fetch(
    contentType ? `${API}/agent/GetAgentVideoAlbum?agentId=${agentId}&contentType=${contentType}&contentId=${contentId}`
        : `${API}/agent/GetAgentVideoAlbum?agentId=${agentId}`, { method: 'GET' },
)
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getVimeoVideoMetaData = ({ videoId }) => fetch(`https://vimeo.com/api/v2/${videoId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendReview = (data) => fetch(`${API}/customer/AddHelpful`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const additionalBio = ({ agentId, bioId }) => fetch(`${API}/agent/getAdditionalBio?agentId=${agentId}&bioId=${bioId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const experimentalPost = ({
    agentId, bioId, blogId, returnAll,
}) => fetch(`${API}/agent/getExpermintalPosts?agentId=${agentId}&bioId=${bioId}&blogId=${blogId}&returnAll=${returnAll}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },

    }));

const agentItineraries = ({
    agentId, bioId, returnAll, itineraryId = 0, preview = false,
}) => fetch(`${API}/agent/getAgentItineraries?agentId=${agentId}&bioId=${bioId}&returnAll=${returnAll}&itineraryId=${itineraryId}&preview=${preview}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },

    }));

const getBlogs = ({ name, rows }) => fetch(`${API}/blog/stories?DisplayChannel=8&rows=${rows}&order=1&name=${name}&type=C`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getBlog = ({ storyId, storyTitle, preview }) => fetch(`${API}/blog/story?storyId=${storyId}&storyTitle=${storyTitle}&preview=${preview}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const agentMatchToInterest = ({
    id, destination, interest, supplier,
}) => fetch(`${API}/agent/agentMatchToInterest?agentId=${id}&AgentDestination=${destination}&AgentInterest=${interest}&AgentSupplier=${supplier}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getPopularDestinations = () => fetch(`${API}/lookup/getPopularDestinations`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getCategories = () => fetch(`${API}/blog/categories`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getAdverts = ({
    id, destination, interest, supplier,
}) => fetch(`${API}/agent/getAdvertisements?Destination=${destination}&Interest=${interest}&Supplier=${supplier}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const emailOptOut = (data) => fetch(`${API}/customer/AddEmailOptOut`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

export default {
    fetchInterests,
    fetchStates,
    fetchDestinations,
    fetchRevelexDestinations,
    fetchRevelexSuppliers,
    fetchFaceted,
    fetchAgents,
    sendEmailToAgent,
    sendPhoneToAgent,
    agentBio,
    agentAlbum,
    agentVideos,
    getVimeoVideoMetaData,
    sendReview,
    additionalBio,
    experimentalPost,
    getBlogs,
    getBlog,
    agentMatchToInterest,
    getPopularDestinations,
    getCategories,
    fetchTopAgentsByReview,
    agentItineraries,
    getAdverts,
    emailOptOut,
};
