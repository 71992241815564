import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
    <div className="mx-4 md:mx-20 py-20 text-center min-h-screen">
        <strong>
            <h2 className="tl-cyan uppercase text-4xl tracking-wide text-blue-dark py-20">Oops... you&apos;re lost!</h2>
        </strong>
        <div>
            <h6 className="text-xl my-2 font-semibold tracking-wide py-2">
                Sadly, the page you are looking for does not exist. What does exist are VACATION travel agents, truly
                destination experts who can guide you to your dream destination much better than this sign will.
            </h6>
            <h3 className="text-xl my-2 font-semibold tracking-wide py-2">
                <strong>
                    Find your travel agent
                    <Link to="/find_travel_agent" className="text-green-light"> here </Link>
                    or return to our
                    <Link to="/home" className="text-green-light"> homepage </Link>
                    .
                </strong>
            </h3>
            <div className="flex justify-center p-2">
                <img src="/images/404-signs.webp" alt="404 Error" className="max-w-full max-h-96" />
            </div>
        </div>
    </div>
);

export default NotFound;
