/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import 'react-accessible-accordion/dist/fancy-example.css';
import { upCurve, downCurve } from '../Utils';

function WhyComponent() {
    return (
        <div className="flex flex-col md:flex-row gap-10 justify-center px-10 md:px-20 md:py-28 py-8 bg-white relative overflow-hidden">
            <div className="absolute hidden-tw md:block right-0 -mt-20 -mr-24 w-1/2">{downCurve}</div>
            <div className="flex-1 flex flex-row relative">
                <div>
                    <div className="w-112 h-160 hidden-tw md:block bg-green-light rounded-tr-3xl md:absolute -ml-10 -mt-10 z-10" />
                    <img
                        alt="Young girl sticker"
                        src="media/homepage/young-girl.webp"
                        className="rounded-tr-3xl z-20 relative -ml-10 md:ml-0 md:w-140 w-full "
                    // style={{ width: '600px' }}
                    />

                </div>

            </div>
            <div className="flex flex-col gap-8 flex-1 justify-center">

                <div className="flex flex-col gap-4 text-center md:text-left">
                    <div className="text-xl md:text-4xl text-blue-dark font-semibold">Why use a Travel Agent?</div>
                    <div className="h-1.5 w-14 bg-blue-dark mx-auto md:mx-0" />
                </div>
                <div className="flex flex-col md:flex-row justify-center gap-4 items-center">
                    <div className="border-blue-dark border rounded-full p-4 md:p-8">
                        <img
                            alt="Hourglass sticker"
                            src="images/hourglass.webp"
                            className=""
                        />
                    </div>
                    <div className="text-sm md:text-md text-black text-center md:text-left ">
                        <div className="font-semibold text-lg md:text-xl mb-1.5">WE SAVE YOU TIME</div>

                        Stop wasting hours searching sites and reading thousands of reviews. We’re real people who have been to your dream destination. We have the firsthand experience to make your vacation truly extraordinary.
                    </div>

                </div>
                <div className="flex flex-col md:flex-row  gap-4 items-center">
                    <div className="border-blue-dark border rounded-full  p-4 md:p-8">
                        <img
                            alt="Payment sticker"
                            src="images/payment.webp"
                            className=""
                        />
                    </div>
                    <div className="text-sm md:text-md text-black text-center md:text-left">
                        <div className="font-semibold text-lg md:text-xl mb-1.5">WE CREATE PERSONALIZED VACATIONS</div>

                        No two travelers are exactly alike. Which is why we get to know you — your dreams, your preferences, and your budget — to create a customized experience we know you’ll love.
                    </div>

                </div>

                <div className="flex flex-col md:flex-row gap-4 items-center">
                    <div className="border-blue-dark border rounded-full  p-4 md:p-8">
                        <img
                            alt="Price sticker"
                            src="images/price-sticker.webp"
                            className=""
                        />
                    </div>
                    <div className="text-sm md:text-md text-black text-center md:text-left  ">
                        <div className="font-semibold text-lg md:text-xl mb-1.5">WE PROVIDE EXCLUSIVE BENEFITS</div>

                        Thanks to our longstanding relationships with travel suppliers around the globe, we have access to insider perks not available anywhere else, like limited-time discounts, bonus upgrades, and exclusive offers.
                    </div>
                </div>

            </div>
            <div className="absolute bottom-14 -ml-1 z-0 w-1/2 hidden-tw md:block">{upCurve}</div>
        </div>
    );
}

export default WhyComponent;
