/* eslint-disable react/no-children-prop */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, {
    useEffect, useState, useRef, createRef,
} from 'react';

import StarRatings from 'react-star-ratings';
import parser from 'html-react-parser';

import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import sanitizeHtml from 'sanitize-html-react';
import { IoChevronBack } from 'react-icons/io5';

import { Helmet } from 'react-helmet';

import {
    downCurve,
    getStateName, processBioCertificateImages, upCurve, wrapperClass,
} from '../Utils';
import API from '../services/API';

import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';

import { AgentMap } from '../components/AgentMap';
import 'react-image-gallery/styles/css/image-gallery.css';
import AgentLabel from '../components/AgentLabel';
import AgentClientReview from '../components/AgentClientReview';
import AgentTestimonials from '../components/AgentTestimonials';
import BreadCrumb from '../components/BreadCrumb';

import AgentAreaOfExpertis from '../components/AgentAreaOfExpertis';
import AgentTravelStories from '../components/AgentTravelStories';
import AgentCertificates from '../components/AgentCertificate';
import AgentItineraries from '../components/AgentItineraries';

function Bio() {
    const { id, bioId } = useParams();
    const [loading, setLoading] = useState(false);

    const [agent, setAgent] = useState();
    const [bio, setBio] = useState();

    const [certificates, setCertificates] = useState([]);
    const history = useHistory();
    const certificateRef = useRef(null);
    const testimonialRef = useRef(null);
    const myPlacesRef = useRef(null);
    const myPlacesContainerRef = useRef(null);
    const reviewsRef = useRef(null);
    const itinerariesRef = useRef(null);
    const myAlbumRef = useRef(null);
    const selectedPlaceRef = useRef(null);
    const executeScroll = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const executeScrollPlace = () => {
        // myPlacesContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
            selectedPlaceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 200);
    };

    const [noAgentReturned, setNoAgentReturned] = useState(false);
    const [noBioReturned, setNoBioReturned] = useState(false);

    const [stories, setStories] = useState([]);
    const [albums, setAlbums] = useState([]);
    const [selectedAlbumId, setSelectedAlbumId] = useState();
    const [videos, setVideos] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState();
    const [additionalBio, setAdditionalBio] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [refs, setRefs] = useState([]);

    const [links, setLinks] = useState([]);
    const prepareAdditionalBio = (data) => {
        // /  if (data.agentAddlSpecializations.some((x) => x.addlBioId !== 0)) {
        if (data.agentAddlSpecializations) {
            setAdditionalBio(data.agentAddlSpecializations);
        }
    };
    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);

                    prepareAdditionalBio(result.data);
                    getTravelStories();
                    getItineraries();

                    const drefs = [];
                    result.data.agentReviews.map((x) => {
                        drefs[x.reviewId] = createRef();
                    });

                    setRefs(drefs);
                }
                if (result.data.agentId === 0) {
                    setNoAgentReturned(true);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getTravelStories = () => {
        setLoading(true);
        API.experimentalPost({
            agentId: id,
            bioId,
            blogId: 0,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setStories(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAdditionalBio = () => {
        setLoading(true);
        API.additionalBio({
            agentId: id,
            bioId,



        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.bioText !== null) {
                    setBio(result.data);
                    setCertificates(processBioCertificateImages(result.data, agent));
                    getAgentAlbum();
                }
                if (result.data.bioText === null) {
                    setNoBioReturned(true);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    }; 

    const getAgentAlbum = () => {
        setLoading(true);
        API.agentAlbum({
            agentId: id,
            contentId: bioId,
            contentType: 'adsp',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setAlbums(result.data);
                getAgentVideos();
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getItineraries = () => {
        setLoading(true);
        API.agentItineraries({
            agentId: id,
            bioId,
            returnAll: 'false',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setItineraries(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentVideos = () => {
        setLoading(true);
        API.agentVideos({
            agentId: id,
            contentId: bioId,
            contentType: 'adsp',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setVideos(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const sendHelpful = (reviewId, value) => {
        localStorage.setItem(`review-${reviewId}`, value);

        setLoading(true);
        API.sendReview({ reviewId, helpful: value }).then((response) => {
            if (response.responseStatus === 1) {
                toast.success('Your feedback has been sent');
                getAgentBio();
            } else {
                toast(response.error.message);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
        window.scrollTo(0, 0);
    }, [bioId]);

    useEffect(() => {
        if (agent) {
            getAdditionalBio();
        }
    }, [agent]);

    useEffect(() => {
        if (agent && bio) {
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?state=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
                url: `/agent/${id}`,
            },
            {
                label: `${bio.displayTitle}`,
            },
            ]);
        }
    }, [agent, bio]);

    const agentBio = () => (
        <div className="flex flex-col gap-10 md:px-20  ">

            <div className="flex flex-col gap-4 px-4 ">
                <div className=" md:-ml-6 mt-4 md:mt-0" style={{ zIndex: '1000' }}>
                    <BreadCrumb links={links} />
                </div>
                <div className="flex flex-col md:flex-row gap-4 md:gap-6 md:items-center  w-full flex-1">

                    <div className="absolute right-0 z-40 top-28 md:top-32 hidden-tw md:block">
                        <img
                            alt="Vector"
                            src="/images/Vector 5.webp"
                            className="md:w-full -mr-40 md:mr-0"
                        />

                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="hidden-tw md:block">
                            <div
                                className="text-blue-dark hover:text-green-light text-sm mb-2 font-semibold flex flex-row items-center cursor-pointer gap-1"
                                onClick={() => history.push(`/agent/${id}`)}
                            >
                                <IoChevronBack className=" text-right" />
                                <div>
                                    {' '}
                                    Return to My Main profile
                                </div>
                            </div>
                            <div className="w-56 h-72 bg-blue-dark rounded-tr-3xl
             md:absolute -ml-6   z-10"
                            />

                            <img
                                alt={agent.firstName + ' ' + agent.lastName + ', ' + agent.city + ', ' + agent.state + ' Travel Agent'}
                                src={agent.agentPhotoFileName}
                                className="hidden-tw md:block md:w-80 md:mt-8 mx-auto  rounded-tr-3xl z-20 relative"
                            />
                        </div>
                        <div className="md:hidden w-full">

                            <AgentLabel agent={agent} reviewCallback={() => executeScroll(reviewsRef)} isAdditionalBio />
                        </div>

                    </div>
                    <div className="hidden-tw md:flex flex-col items-center md:items-start gap-2  w-full">
                        {/* <BreadCrumb links={links} /> */}
                        <AgentLabel agent={agent} reviewCallback={() => executeScroll(reviewsRef)} />

                    </div>

                </div>

            </div>
            <div className="flex flex-col gap-2 p-6 md:p-8 bg-gray-verylight md:rounded-l-3xl md:-mr-20">

                <div className="text-center md:text-left text-2xl md:text-4xl  font-medium text-blue-dark">{bio.displayTitle}</div>
                <div className="pure-u-1">
                    <div className="float-left mr-4 pure-g mt-4">
                        <img src={bio.travelImage} className="w-96 rounded-lg" alt={agent.firstName + ' ' + agent.lastName + ' - ' + bio.displayTitle + ' - ' + agent.city + ', ' + agent.state} />
                    </div>
                    {parser(sanitizeHtml(bio.bioText))}

                    {/* <div className="text-gray-700 text-sm md:text-xl py-2 pure-g pure-u-1">
            {parser(sanitizeHtml(bio.bioText))}
          </div> */}
                </div>
            </div>

        </div>
    );

    const tabBar = () => {
        const item = 'border-r border-gray-400';
        const baseClass = 'inline-block text-white font-semibold  hover:text-green-light md:px-6 px-3 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-500 text-xs md:text-lg';

        const separator = () => <hr className="w-1 bg-white hidden-tw" />;

        const displayTabBar = ((additionalBio.length > 0 ? 1 : 0)
            + ((certificates.length > 0 || bio.agentCertifications.length > 0) ? 1 : 0)
            + (agent.agentReviews.length > 0 ? 1 : 0)
            + (itineraries.length > 0 ? 1 : 0)
            + (agent.agentTestimonials.length > 0 ? 1 : 0)
            + (agent.agentMaps.length > 0 ? 1 : 0)) >= 2;

        return (
            <>
                {noAgentReturned === true ? (
                    <>
                        <Helmet>
                            <meta name="robots" content="noindex, nofollow" />
                        </Helmet>
                        <div className="mx-4 md:mx-20 py-20 text-center h-screen">
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">Sorry, this agent unvailable</h2>
                            <div>
                                <h3 className="uppercase text-xl my-2 font-semibold tracking-wide">
                                    It looks like the agent you have selected is no longer a part of our network.
                                    <br />
                                    <a href="/find_travel_agent" className="tl-red hover:text-black ">Don�t worry, we have plenty of other agents to choose from</a>
                                </h3>
                            </div>
                        </div>
                    </>
                ) : ''}

                {displayTabBar && (
                    <div className="md:px-5 py-2 bg-blue-dark md:flex md:flex-row md:ext-center text-left  text-lg tracking-wider  md:mt-20  md:justify-center">
                        {agent.agentReviews.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(reviewsRef);
                                    }}
                                >
                                    REVIEWS

                                </div>
                                {separator()}
                            </>
                        )}

                        {(albums.length > 0 || videos.length > 0 || stories.length > 0)
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(myAlbumRef);
                                        }}
                                    >
                                        TRAVEL STORIES AND MEDIA

                                    </div>
                                    {separator()}
                                </>
                            )}
                        {itineraries.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(itinerariesRef);
                                    }}
                                >
                                    MY ITINERARIES

                                </div>
                                {separator()}
                            </>
                        )}
                        {(certificates.length > 0 || bio.agentCertifications.length > 0)
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(certificateRef);
                                        }}
                                    >
                                        CERTIFICATES AND AWARDS

                                    </div>
                                    {separator()}
                                </>
                            )}

                        {agent.agentMaps.length > 0
                            && (
                                <>
                                    <div
                                        className={[baseClass].join('')}
                                        onClick={() => {
                                            executeScroll(myPlacesRef);
                                        }}
                                    >
                                        MY PLACES

                                    </div>
                                    {separator()}
                                </>
                            )}

                        {agent.agentTestimonials.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(testimonialRef);
                                    }}
                                >
                                    TESTIMONIALS

                                </div>
                                {/* {separator()} */}
                            </>
                        )}

                    </div>
                )}
            </>
        );
    };

    const agentMaps = () => (
        <div
            className={[wrapperClass, 'md:pr-0 bg-blue-dark md:bg-white px-4  md:px-20 relative overflow-x-hidden'].join(' ')}
            ref={myPlacesRef}
        >
            <div className="absolute right-0 w-1/3  -mr-32 top-0 hidden-tw md:block">{upCurve}</div>
            <div className="absolute left-0 w-1/3  -ml-32 bottom-10 hidden-tw md:block">{downCurve}</div>
            <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
                <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left text-white md:text-blue-dark">
                    My Places
                </div>
                <div className={['md:bg-blue-dark bg-white', 'h-1.5 w-24 md:w-14 md:ml-0'].join(' ')} />
            </div>
            <div className="flex flex-col md:flex-row  mt-10 w-full" ref={myPlacesContainerRef}>
                <div className="md:w-2/3 md:inline-block ">

                    <AgentMap
                        selectedLocation={selectedLocation}
                        callback={(val) => {
                            setSelectedLocation(val);
                        }}
                        locations={agent.agentMaps}
                        containerElement={<div className="w-full h-smallmap md:h-map" />}
                        mapElement={<div className="md:rounded-l-3xl" style={{ height: '100%' }} />}
                    />
                </div>
                <div className="md:w-1/3 inline-block text-white overflow-y-auto h-map bg-blue-dark">
                    {agent.agentMaps.map((x) => (
                        <div
                            key={x.mapId}
                            ref={selectedLocation && x.mapId === selectedLocation.mapId ? selectedPlaceRef : null}
                            className={['flex flex-row p-4 gap-4 justify-between', selectedLocation && selectedLocation.mapId === x.mapId ? 'bg-gray-300' : ''].join(' ')}
                        >
                            <div className="flex flex-col gap-2">
                                <button
                                    type="button"
                                    className="text-sm md:text-base font-bold cursor-pointer focus:outline-none focus:ring focus:ring-offset-2 focus:ring-orange-500"
                                    onClick={() => setSelectedLocation(x)}
                                    style={{ all: 'unset' }}
                                >
                                    {`${x.locale} ${x.locale ? ',' : ''} ${x.area} ${x.region}`}
                                </button>
                                <div className="flex flex-row gap-2">
                                    {x.bookedIt && <div className="text-sm font-light">{`Booked It ${x.bookingCount} Times`}</div>}
                                    {x.beenThere && x.bookedIt && <div>|</div>}
                                    {x.beenThere && <div className="text-sm font-light">{`Been There ${x.visitCount} Times`}</div>}
                                </div>
                                <StarRatings
                                    rating={x.reviewRating}
                                    starRatedColor="#FD6A4A"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="14px"
                                    starSpacing="2px"
                                />
                                <button
                                    type="button"
                                    className="text-sm font-light focus:outline-none focus:ring focus:ring-offset-2 focus:ring-orange-500"
                                    onClick={() => (refs[x.reviewId] ? executeScroll(refs[x.reviewId], 'center') : {})}
                                    style={{ all: 'unset' }}
                                >
                                    {x.review.length > 50 ? parser(`${sanitizeHtml(x.review.slice(0, 50))}...<span className="mx-2 cursor-pointer">Read More</span>`) : x.review}
                                </button>
                                {x.locationTip && (
                                    <>
                                        <div className="text-sm font-semibold mt-4">Must Do:</div>
                                        <div className="text-sm font-light">{x.locationTip}</div>
                                    </>
                                )}
                                <div className={['bg-white', 'h-0.5 w-24 md:w-20 mt-4'].join(' ')} />
                            </div>
                            <div className="md:flex flex-col hidden">
                                {x.locationPhoto && (
                                    <img
                                        src={x.locationPhoto}
                                        className="object-contain h-48 w-full"
                                        alt="Location"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );

    useEffect(() => {
        if (selectedLocation) { executeScrollPlace(); }
    }, [selectedLocation]);
    return (
        <div className=" max-w-screen-2xl mx-auto bg-white" role="main">
            <ToastContainer />
            {((noAgentReturned === false) && (noBioReturned === true)) ? (
                <>
                    <Helmet>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                    <div className="mx-4 md:mx-20 py-20 text-center h-screen">
                        <strong>
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide text-blue-dark py-20">Sorry, this agent bio is unavailable</h2>
                        </strong>
                        <div>
                            <h3 className="uppercase text-xl my-2 font-semibold tracking-wide py-2">
                                It looks like this agent bio is not available. Please go back to the previous page and select a different bio.
                            </h3>
                        </div>
                    </div>
                </>
            ) : ''}
            {agent && bio && (
                <div className="h-full  flex flex-col pt-10 md:py-16 text-gray-900 ">
                    {agentBio()}
                    {tabBar()}
                    <AgentAreaOfExpertis agent={bio} type="bio" />
                    {agent.agentReviews.length > 0
                        && (
                            <div ref={reviewsRef}>
                                <AgentClientReview agent={agent} ref={reviewsRef} refs={refs} setLoading={setLoading} callback={() => getAgentBio()} />
                            </div>
                        )}
                    {(albums.length > 0 || videos.length > 0 || stories.length > 0)
                        && (
                            <div ref={myAlbumRef}>
                                <AgentTravelStories
                                    type="bio"
                                    agent={agent}
                                    bioId={bioId}
                                    stories={stories}
                                    videos={videos}
                                    albums={albums}
                                />
                            </div>
                        )}
                    {itineraries.length > 0
                        && (
                            <div ref={itinerariesRef}>
                                <AgentItineraries agent={agent} itineraries={itineraries} bioId={bioId} />
                            </div>
                        )}
                    {(certificates.length > 0 || bio.agentCertifications.length > 0)
                        && (
                            <div ref={certificateRef}>
                                <AgentCertificates certificates={certificates} agent={bio} />
                            </div>
                        )}

                    {agent.agentMaps.length > 0 && agentMaps()}
                    {agent.agentTestimonials.length > 0

                        && (
                            <div ref={testimonialRef}>
                                <AgentTestimonials agent={agent} />
                            </div>
                        )}

                </div>
            )}
            {loading && <Loading />}
        </div>
    );
}

export default Bio;
